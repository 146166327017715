// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_DOM = require("../Effect.DOM/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_DOM_Element = require("../Web.DOM.Element/index.js");
var GetPortal = (function () {
    function GetPortal() {

    };
    GetPortal.value = new GetPortal();
    return GetPortal;
})();
var DisableScrolling = (function () {
    function DisableScrolling(value0) {
        this.value0 = value0;
    };
    DisableScrolling.create = function (value0) {
        return new DisableScrolling(value0);
    };
    return DisableScrolling;
})();
var eqState = {
    eq: function (v) {
        return function (v1) {
            if (v instanceof GetPortal && v1 instanceof GetPortal) {
                return true;
            };
            if (v instanceof DisableScrolling && v1 instanceof DisableScrolling) {
                return true;
            };
            return false;
        };
    }
};
var $$new = React_Basic_Hooks.component("Portal")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(GetPortal.value))(function (v1) {
        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(eqState)(v1.value0)((function () {
            if (v1.value0 instanceof GetPortal) {
                return function __do() {
                    var portal = Effect_DOM.getPortal();
                    v1.value1(Data_Function["const"](new DisableScrolling(portal)))();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                };
            };
            if (v1.value0 instanceof DisableScrolling) {
                return function __do() {
                    var body = Effect_DOM.getBody();
                    var style = Data_Functor.map(Effect.functorEffect)(Data_Maybe.fromMaybe(""))(Web_DOM_Element.getAttribute("style")(body))();
                    Web_DOM_Element.setAttribute("style")("overflow: hidden;")(body)();
                    return Web_DOM_Element.setAttribute("style")(style)(body);
                };
            };
            throw new Error("Failed pattern match at React.Portal (line 31, column 21 - line 42, column 45): " + [ v1.value0.constructor.name ]);
        })()))(function () {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                if (v1.value0 instanceof GetPortal) {
                    return React_Basic.empty;
                };
                if (v1.value0 instanceof DisableScrolling) {
                    return $foreign.createPortal(React_Basic.fragment(v.children))(v1.value0.value0);
                };
                throw new Error("Failed pattern match at React.Portal (line 43, column 10 - line 45, column 71): " + [ v1.value0.constructor.name ]);
            })());
        });
    });
});
module.exports = {
    "new": $$new
};
