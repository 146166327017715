// Generated by purs version 0.14.4
"use strict";
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Hashable = require("../Data.Hashable/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Lambda_Language_Expression = require("../Lambda.Language.Expression/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Bound = (function () {
    function Bound(value0) {
        this.value0 = value0;
    };
    Bound.create = function (value0) {
        return new Bound(value0);
    };
    return Bound;
})();
var Free = (function () {
    function Free(value0) {
        this.value0 = value0;
    };
    Free.create = function (value0) {
        return new Free(value0);
    };
    return Free;
})();
var Lambda = (function () {
    function Lambda(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Lambda.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Lambda(value0, value1, value2);
            };
        };
    };
    return Lambda;
})();
var Apply = (function () {
    function Apply(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Apply.create = function (value0) {
        return function (value1) {
            return new Apply(value0, value1);
        };
    };
    return Apply;
})();
var prettyNameless = {
    pretty: function (v) {
        var walk = function (inApp) {
            return function (v1) {
                if (v1 instanceof Bound) {
                    return Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v1.value0));
                };
                if (v1 instanceof Free) {
                    return Lambda_Language_Pretty.text(Data_Show.show(Lambda_Language_Name.showName)(v1.value0));
                };
                if (v1 instanceof Lambda) {
                    return Lambda_Language_Pretty.parensIf(inApp)(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text("\u03bb. "))(walk(false)(v1.value2)));
                };
                if (v1 instanceof Apply) {
                    return Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(walk(true)(v1.value0))(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text(" "))(walk(true)(v1.value1)));
                };
                throw new Error("Failed pattern match at Lambda.Language.Nameless (line 120, column 18 - line 128, column 47): " + [ v1.constructor.name ]);
            };
        };
        return walk(false);
    }
};
var genericNameless = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Bound(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Free(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Lambda(x.value0.value0.value0.value0, x.value0.value0.value0.value1.value0, x.value0.value0.value0.value1.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new Apply(x.value0.value0.value0.value0, x.value0.value0.value0.value1);
        };
        throw new Error("Failed pattern match at Lambda.Language.Nameless (line 31, column 1 - line 31, column 54): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Bound) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Free) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Lambda) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, x.value2)))));
        };
        if (x instanceof Apply) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1))));
        };
        throw new Error("Failed pattern match at Lambda.Language.Nameless (line 31, column 1 - line 31, column 54): " + [ x.constructor.name ]);
    }
};
var showNameless = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericNameless)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt))({
            reflectSymbol: function () {
                return "Bound";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))({
            reflectSymbol: function () {
                return "Free";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Data_Set.showSet(Lambda_Language_Name.showName)))(Data_Show_Generic.genericShowArgsArgument(showNameless))))({
            reflectSymbol: function () {
                return "Lambda";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(showNameless))(Data_Show_Generic.genericShowArgsArgument(showNameless)))({
            reflectSymbol: function () {
                return "Apply";
            }
        })))))(x);
    }
};
var fresh = function ($copy_env) {
    return function ($copy_n) {
        var $tco_var_env = $copy_env;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(env, n) {
            if (Data_Set.member(Lambda_Language_Name.ordName)(n)(env)) {
                $tco_var_env = env;
                $copy_n = Lambda_Language_Name.next(n);
                return;
            };
            if (Data_Boolean.otherwise) {
                $tco_done = true;
                return {
                    used: Data_Set.insert(Lambda_Language_Name.ordName)(n)(env),
                    "new": n
                };
            };
            throw new Error("Failed pattern match at Lambda.Language.Nameless (line 103, column 1 - line 103, column 61): " + [ env.constructor.name, n.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_env, $copy_n);
        };
        return $tco_result;
    };
};
var freeVars = function (v) {
    if (v instanceof Bound) {
        return Data_Set.empty;
    };
    if (v instanceof Free) {
        return Data_Set.singleton(v.value0);
    };
    if (v instanceof Lambda) {
        return v.value1;
    };
    if (v instanceof Apply) {
        return Data_Semigroup.append(Data_Set.semigroupSet(Lambda_Language_Name.ordName))(freeVars(v.value0))(freeVars(v.value1));
    };
    throw new Error("Failed pattern match at Lambda.Language.Nameless (line 110, column 12 - line 114, column 40): " + [ v.constructor.name ]);
};
var eqNameless = {
    eq: function (lhs) {
        return function (rhs) {
            if (lhs instanceof Bound && rhs instanceof Bound) {
                return lhs.value0 === rhs.value0;
            };
            if (lhs instanceof Free && rhs instanceof Free) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(lhs.value0)(rhs.value0);
            };
            if (lhs instanceof Lambda && rhs instanceof Lambda) {
                return Data_Eq.eq(eqNameless)(lhs.value2)(rhs.value2);
            };
            if (lhs instanceof Apply && rhs instanceof Apply) {
                return Data_Eq.eq(eqNameless)(lhs.value0)(rhs.value0) && Data_Eq.eq(eqNameless)(lhs.value1)(rhs.value1);
            };
            return false;
        };
    }
};
var hashableNameless = {
    hash: function (v) {
        if (v instanceof Bound) {
            return Data_Hashable.hash(Data_Hashable.hashableInt)(v.value0);
        };
        if (v instanceof Free) {
            return Data_Hashable.hash(Lambda_Language_Name.hashableName)(v.value0);
        };
        if (v instanceof Lambda) {
            return Data_Hashable.hash(hashableNameless)(v.value2);
        };
        if (v instanceof Apply) {
            return Data_Hashable.hash(Data_Hashable.hashableTuple(hashableNameless)(hashableNameless))(new Data_Tuple.Tuple(v.value0, v.value1));
        };
        throw new Error("Failed pattern match at Lambda.Language.Nameless (line 47, column 10 - line 51, column 34): " + [ v.constructor.name ]);
    },
    Eq0: function () {
        return eqNameless;
    }
};
var alphaInternal = function (x) {
    var loop = function (env) {
        return function (v) {
            if (v instanceof Bound) {
                return new Bound(v.value0);
            };
            if (v instanceof Free) {
                return new Free(v.value0);
            };
            if (v instanceof Lambda) {
                var v1 = fresh(env)(v.value0);
                return Lambda.create(v1["new"])(v.value1)(loop(v1.used)(v.value2));
            };
            if (v instanceof Apply) {
                return new Apply(loop(env)(v.value0), loop(env)(v.value1));
            };
            throw new Error("Failed pattern match at Lambda.Language.Nameless (line 91, column 14 - line 100, column 38): " + [ v.constructor.name ]);
        };
    };
    return loop(x.fvs)(x.expr);
};
var from = (function () {
    var go = function (env) {
        return function (v) {
            if (v instanceof Lambda_Language_Expression.Var) {
                var v1 = Data_Map_Internal.lookup(Lambda_Language_Name.ordName)(v.value0)(env);
                if (v1 instanceof Data_Maybe.Nothing) {
                    return {
                        expr: new Free(v.value0),
                        fvs: Data_Set.singleton(v.value0)
                    };
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return {
                        expr: new Bound(v1.value0),
                        fvs: Data_Set.empty
                    };
                };
                throw new Error("Failed pattern match at Lambda.Language.Nameless (line 60, column 7 - line 62, column 50): " + [ v1.constructor.name ]);
            };
            if (v instanceof Lambda_Language_Expression.Nat) {
                return go(env)(Lambda_Language_Expression.encodeNat(v.value0));
            };
            if (v instanceof Lambda_Language_Expression.List) {
                return go(env)((function () {
                    var names = Data_Map.keys(env);
                    var v1 = fresh(names)(Lambda_Language_Name.from("cons"));
                    var v2 = fresh(names)(Lambda_Language_Name.from("nil"));
                    return Lambda_Language_Expression.encodeList(v1["new"])(v2["new"])(v.value0);
                })());
            };
            if (v instanceof Lambda_Language_Expression.Lambda) {
                var shifted = Data_Map_Internal.insert(Lambda_Language_Name.ordName)(v.value0)(0)(Data_Functor.map(Data_Map_Internal.functorMap)(function (v1) {
                    return v1 + 1 | 0;
                })(env));
                var v1 = go(shifted)(v.value1);
                return {
                    expr: new Lambda(v.value0, v1.fvs, v1.expr),
                    fvs: v1.fvs
                };
            };
            if (v instanceof Lambda_Language_Expression.Apply) {
                var f = go(env)(v.value0);
                var a = go(env)(v.value1);
                return {
                    expr: new Apply(f.expr, a.expr),
                    fvs: Data_Semigroup.append(Data_Set.semigroupSet(Lambda_Language_Name.ordName))(f.fvs)(a.fvs)
                };
            };
            if (v instanceof Lambda_Language_Expression.Highlight) {
                return go(env)(v.value0);
            };
            if (v instanceof Lambda_Language_Expression.Cycle) {
                return Partial_Unsafe.unsafeCrashWith("Parser should never produce a cycle");
            };
            throw new Error("Failed pattern match at Lambda.Language.Nameless (line 58, column 12 - line 80, column 78): " + [ v.constructor.name ]);
        };
    };
    var $115 = go(Data_Map_Internal.empty);
    return function ($116) {
        return alphaInternal($115($116));
    };
})();
var alpha = function (expr) {
    return alphaInternal({
        expr: expr,
        fvs: freeVars(expr)
    });
};
module.exports = {
    Bound: Bound,
    Free: Free,
    Lambda: Lambda,
    Apply: Apply,
    from: from,
    freeVars: freeVars,
    alpha: alpha,
    genericNameless: genericNameless,
    showNameless: showNameless,
    eqNameless: eqNameless,
    hashableNameless: hashableNameless,
    prettyNameless: prettyNameless
};
