// Generated by purs version 0.14.4
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var monadZeroIsDeprecated = function (dictWarn) {
    return {};
};
var monadZeroArray = {
    Monad0: function () {
        return Control_Monad.monadArray;
    },
    Alternative1: function () {
        return Control_Alternative.alternativeArray;
    },
    MonadZeroIsDeprecated2: function () {
        return undefined;
    }
};
module.exports = {
    monadZeroIsDeprecated: monadZeroIsDeprecated,
    monadZeroArray: monadZeroArray,
    alt: Control_Alt.alt,
    guard: Control_Alternative.guard,
    liftA1: Control_Applicative.liftA1,
    pure: Control_Applicative.pure,
    unless: Control_Applicative.unless,
    when: Control_Applicative.when,
    apply: Control_Apply.apply,
    bind: Control_Bind.bind,
    ifM: Control_Bind.ifM,
    join: Control_Bind.join,
    ap: Control_Monad.ap,
    liftM1: Control_Monad.liftM1,
    empty: Control_Plus.empty,
    map: Data_Functor.map,
    "void": Data_Functor["void"]
};
