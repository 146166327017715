// Generated by purs version 0.14.4
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var pure = function (dict) {
    return dict.pure;
};
var unless = function (dictApplicative) {
    return function (v) {
        return function (v1) {
            if (!v) {
                return v1;
            };
            if (v) {
                return pure(dictApplicative)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Control.Applicative (line 66, column 1 - line 66, column 65): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var when = function (dictApplicative) {
    return function (v) {
        return function (v1) {
            if (v) {
                return v1;
            };
            if (!v) {
                return pure(dictApplicative)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Control.Applicative (line 61, column 1 - line 61, column 63): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var liftA1 = function (dictApplicative) {
    return function (f) {
        return function (a) {
            return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
        };
    };
};
var applicativeProxy = {
    pure: function (v) {
        return Type_Proxy["Proxy"].value;
    },
    Apply0: function () {
        return Control_Apply.applyProxy;
    }
};
var applicativeFn = {
    pure: function (x) {
        return function (v) {
            return x;
        };
    },
    Apply0: function () {
        return Control_Apply.applyFn;
    }
};
var applicativeArray = {
    pure: function (x) {
        return [ x ];
    },
    Apply0: function () {
        return Control_Apply.applyArray;
    }
};
module.exports = {
    pure: pure,
    liftA1: liftA1,
    unless: unless,
    when: when,
    applicativeFn: applicativeFn,
    applicativeArray: applicativeArray,
    applicativeProxy: applicativeProxy,
    apply: Control_Apply.apply,
    map: Data_Functor.map,
    "void": Data_Functor["void"]
};
