// Generated by purs version 0.14.4
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Argonaut_Decode_Error = require("../Data.Argonaut.Decode.Error/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var token = function (p) {
    return Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(p)(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity));
};
var run = function (p) {
    return function (s) {
        return Text_Parsing_Parser.runParser(s)(Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(p))(Text_Parsing_Parser_String.eof(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)));
    };
};
var positionColumn = function (v) {
    return v.column;
};
var parse = function (dict) {
    return dict.parse;
};
var formatParseError = function (text) {
    return function (err) {
        var message = Text_Parsing_Parser.parseErrorMessage(err);
        var column = positionColumn(Text_Parsing_Parser.parseErrorPosition(err));
        var caret = Data_String_CodeUnits.fromCharArray(Data_Array.replicate(column - 1 | 0)(" ")) + "^";
        return {
            message: "Parse error: " + (message + (" at column " + Data_Show.show(Data_Show.showInt)(column))),
            source: text,
            caret: caret
        };
    };
};
var simpleRun = function (p) {
    return function (s) {
        var toString = function (v) {
            return Data_Array.intercalate(Data_Monoid.monoidString)("\x0a")([ v.message, v.source, v.caret ]);
        };
        return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)((function () {
            var $9 = formatParseError(s);
            return function ($10) {
                return toString($9($10));
            };
        })())(run(p)(s));
    };
};
var liftJson = function (p) {
    var $11 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.TypeMismatch.create);
    var $12 = simpleRun(p);
    return function ($13) {
        return $11($12($13));
    };
};
var unsafeRun = function (p) {
    var $14 = Data_Either.either(Partial_Unsafe.unsafeCrashWith)(Control_Category.identity(Control_Category.categoryFn));
    var $15 = simpleRun(p);
    return function ($16) {
        return $14($15($16));
    };
};
var balance = function (lhs) {
    return function (rhs) {
        return Text_Parsing_Parser_Combinators.between(Data_Identity.monadIdentity)(token(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(lhs)))(token(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(rhs)));
    };
};
var brackets = balance("[")("]");
var parens = balance("(")(")");
module.exports = {
    parse: parse,
    run: run,
    liftJson: liftJson,
    unsafeRun: unsafeRun,
    formatParseError: formatParseError,
    token: token,
    balance: balance,
    parens: parens,
    brackets: brackets,
    fail: Text_Parsing_Parser.fail,
    between: Text_Parsing_Parser_Combinators.between,
    option: Text_Parsing_Parser_Combinators.option,
    optionMaybe: Text_Parsing_Parser_Combinators.optionMaybe,
    optional: Text_Parsing_Parser_Combinators.optional,
    sepBy: Text_Parsing_Parser_Combinators.sepBy,
    sepBy1: Text_Parsing_Parser_Combinators.sepBy1,
    "try": Text_Parsing_Parser_Combinators["try"],
    "char": Text_Parsing_Parser_String["char"],
    satisfy: Text_Parsing_Parser_String.satisfy,
    skipSpaces: Text_Parsing_Parser_String.skipSpaces,
    string: Text_Parsing_Parser_String.string
};
