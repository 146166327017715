// Generated by purs version 0.14.4
"use strict";
var Components_Alert = require("../Components.Alert/index.js");
var Components_ApiError = require("../Components.ApiError/index.js");
var Components_App_Action = require("../Components.App.Action/index.js");
var Components_App_Alert = require("../Components.App.Alert/index.js");
var Components_App_Request = require("../Components.App.Request/index.js");
var Components_App_State = require("../Components.App.State/index.js");
var Components_ConsistencyError = require("../Components.ConsistencyError/index.js");
var Components_Controls = require("../Components.Controls/index.js");
var Components_Copy = require("../Components.Copy/index.js");
var Components_Definitions = require("../Components.Definitions/index.js");
var Components_Expressions = require("../Components.Expressions/index.js");
var Components_Footer = require("../Components.Footer/index.js");
var Components_Help = require("../Components.Help/index.js");
var Components_Input = require("../Components.Input/index.js");
var Components_Level = require("../Components.Level/index.js");
var Components_Modal = require("../Components.Modal/index.js");
var Components_ParseError = require("../Components.ParseError/index.js");
var Components_Spinner = require("../Components.Spinner/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Grammar = require("../Data.Grammar/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Lambda_Env = require("../Lambda.Env/index.js");
var Lambda_Flags = require("../Lambda.Flags/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Aff = require("../React.Basic.Hooks.Aff/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var stepsHeader = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return React_Basic_DOM_Generated.h3()({
            className: "text-muted",
            children: [ React_Basic_DOM.text("Steps") ]
        });
    };
    if (v instanceof Data_Maybe.Just) {
        return React_Basic_DOM_Generated.h3_([ React_Basic_DOM.text(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ Data_Show.show(Data_Show.showInt)(v.value0), " ", Data_Grammar.pluralizeWith("s")(v.value0)("Step") ])) ]);
    };
    throw new Error("Failed pattern match at Components.App (line 177, column 15 - line 188, column 6): " + [ v.constructor.name ]);
};
var split = function (lhs) {
    return function (rhs) {
        return React_Basic_DOM_Generated.div()({
            className: "row",
            children: [ React_Basic_DOM_Generated.div()({
                className: "col-sm-6",
                children: [ lhs ]
            }), React_Basic_DOM_Generated.div()({
                className: "col-sm-6",
                children: [ rhs ]
            }) ]
        });
    };
};
var row = function (child) {
    return React_Basic_DOM_Generated.div()({
        className: "row",
        children: [ React_Basic_DOM_Generated.div()({
            className: "col-sm-12",
            children: [ child ]
        }) ]
    });
};
var linkModal = function __do() {
    var copy = Components_Copy["new"]();
    var modal = Components_Modal["new"]();
    return function (v) {
        return modal({
            dismiss: v.dismiss,
            level: Components_Level.Info.value,
            title: "Copy Link To This Machine",
            children: [ copy({
                text: Lambda_Env.host + ("/?code=" + (Data_Newtype.unwrap()(v.code) + Lambda_Flags.param(v.flags)))
            }) ]
        });
    };
};
var helpAlert = function (dismiss) {
    return Components_Alert.component({
        dismiss: dismiss,
        level: Components_Level.Info.value,
        child: Components_Help.component({})
    });
};
var errorAlert = function (dismiss) {
    return function (v) {
        if (v instanceof Components_App_Alert.ApiError) {
            return Components_Alert.component({
                dismiss: dismiss,
                level: Components_Level.Danger.value,
                child: Components_ApiError.component({
                    error: v.value0
                })
            });
        };
        if (v instanceof Components_App_Alert.SaveError) {
            return Components_Alert.component({
                dismiss: dismiss,
                level: Components_Level.Danger.value,
                child: React_Basic_DOM.text(v.value0)
            });
        };
        if (v instanceof Components_App_Alert.ParseError) {
            return Components_Alert.component({
                dismiss: dismiss,
                level: Components_Level.Danger.value,
                child: Components_ParseError.component({
                    input: v.value0,
                    error: v.value1
                })
            });
        };
        if (v instanceof Components_App_Alert.Inconsistent) {
            return Components_Alert.component({
                dismiss: dismiss,
                level: Components_Level.Danger.value,
                child: Components_ConsistencyError.component({
                    error: v.value0
                })
            });
        };
        throw new Error("Failed pattern match at Components.App (line 110, column 22 - line 133, column 6): " + [ v.constructor.name ]);
    };
};
var $$new = function __do() {
    var reducer = React_Basic_Hooks.mkReducer(Components_App_State.reduce)();
    var spinner = Components_Spinner["new"]();
    var modal = linkModal();
    return React_Basic_Hooks.component("App")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useReducer(Components_App_State["new"](v.flags)(v.code))(reducer))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(Data_Maybe.eqMaybe(Components_App_Request.eqRequest))(v1.value0.request)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Components_App_State.handle(v1.value1)(v1.value0))(v1.value0.request)))(function () {
                var dismiss = v1.value1(Components_App_Action.Dismiss.value);
                var alert = (function () {
                    if (v1.value0.alert instanceof Data_Maybe.Just && v1.value0.alert.value0 instanceof Components_App_Alert.Help) {
                        return row(helpAlert(dismiss));
                    };
                    if (v1.value0.alert instanceof Data_Maybe.Just && v1.value0.alert.value0 instanceof Components_App_Alert["Error"]) {
                        return row(errorAlert(dismiss)(v1.value0.alert.value0.value0));
                    };
                    if (v1.value0.alert instanceof Data_Maybe.Just && v1.value0.alert.value0 instanceof Components_App_Alert.Link) {
                        return row(modal({
                            dismiss: dismiss,
                            code: v1.value0.alert.value0.value0,
                            flags: v1.value0.flags
                        }));
                    };
                    if (v1.value0.alert instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    throw new Error("Failed pattern match at Components.App (line 53, column 15 - line 61, column 22): " + [ v1.value0.alert.constructor.name ]);
                })();
                var v2 = Components_App_State.status(v1.value0);
                var dispatchIf = function (cond) {
                    return function ($36) {
                        return (function (v3) {
                            return Data_Functor.voidRight(Data_Maybe.functorMaybe)(v3)(Control_Alternative.guard(Data_Maybe.alternativeMaybe)(cond));
                        })(v1.value1($36));
                    };
                };
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.fragment([ React_Basic_DOM_Generated.div()({
                    className: "container",
                    children: [ row(React_Basic_DOM_Generated.h2()({
                        className: "page-header",
                        children: [ React_Basic_DOM.text("Lambda Machine") ]
                    })), alert, row(Components_Input.component({
                        text: v1.value0.text,
                        onChange: function ($37) {
                            return v1.value1(Components_App_Action.Update.create($37));
                        },
                        onSubmit: v1.value1(Components_App_Action.Parse.value),
                        onHelp: v1.value1(Components_App_Action.Help.value)
                    })), row(React_Basic_DOM_Generated.h3_([ React_Basic_DOM.text("Definitions") ])), row(Components_Definitions.component({
                        defs: v1.value0.defs,
                        rep: v1.value0.rep,
                        onDelete: function ($38) {
                            return v1.value1(Components_App_Action.Delete.create($38));
                        }
                    })), split(stepsHeader(v1.value0.steps))(Components_Controls.component({
                        flags: v.flags,
                        onStep: dispatchIf(!v2.isHalted)(Components_App_Action.Step.value),
                        onClear: dispatchIf(v2.hasMachine)(Components_App_Action.Clear.value),
                        onShare: dispatchIf(v2.hasProgram)(new Components_App_Action.Enqueue(Components_App_Request.Store.value)),
                        onSave: dispatchIf(v2.hasProgram)(new Components_App_Action.Enqueue(Components_App_Request.Save.value)),
                        onSugar: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v1.value1(Components_App_Action.Toggle.value)),
                        rep: v1.value0.rep
                    })), row(Components_Expressions.component({
                        history: v1.value0.history,
                        rep: v1.value0.rep
                    })), row(Components_Footer.component({})) ]
                }), Data_Maybe.maybe(React_Basic.empty)(function (v3) {
                    return spinner({});
                })(v1.value0.request) ]));
            });
        });
    })();
};
module.exports = {
    "new": $$new
};
