// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_HashMap = require("../Data.HashMap/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Machine_Heap = require("../Lambda.Machine.Heap/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var get = function (dictMonadState) {
    return function (name) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
            return v.globals;
        }))(function (globals) {
            var v = Data_HashMap.lookup(Lambda_Language_Name.hashableName)(name)(globals);
            if (v instanceof Data_Maybe.Just) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(v.value0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Partial_Unsafe.unsafeCrashWith("No global binding for " + Data_Show.show(Lambda_Language_Name.showName)(name));
            };
            throw new Error("Failed pattern match at Lambda.Machine.Globals (line 50, column 3 - line 52, column 71): " + [ v.constructor.name ]);
        });
    };
};
var empty = Data_HashMap.empty;
var add = function (dictMonadState) {
    return function (name) {
        return function (gen) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
                return v.globals;
            }))(function (globals) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Lambda_Machine_Heap.reserve(dictMonadState))(function (p) {
                    return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (v) {
                        var $8 = {};
                        for (var $9 in v) {
                            if ({}.hasOwnProperty.call(v, $9)) {
                                $8[$9] = v[$9];
                            };
                        };
                        $8.globals = Data_HashMap.insert(Lambda_Language_Name.hashableName)(name)(p)(globals);
                        return $8;
                    }))(function () {
                        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(gen(Data_Unit.unit))(function (node) {
                            return Lambda_Machine_Heap.update(dictMonadState)(p)(node);
                        });
                    });
                });
            });
        };
    };
};
module.exports = {
    empty: empty,
    add: add,
    get: get
};
