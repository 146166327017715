// Generated by purs version 0.14.4
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var inputGroupBtn = function (child) {
    return React_Basic_DOM_Generated.div()({
        className: "input-group-btn",
        children: [ child ]
    });
};
var inputGroup = function (children) {
    return React_Basic_DOM_Generated.div()({
        className: "input-group",
        children: children
    });
};
var component = function (v) {
    return React_Basic_DOM_Generated.form()({
        onSubmit: React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Data_Function["const"](v.onSubmit)),
        children: [ inputGroup([ inputGroupBtn(React_Basic_DOM_Generated.button()({
            className: "btn btn-info",
            type: "button",
            onClick: React_Basic_Events.handler_(v.onHelp),
            children: [ React_Basic_DOM.text("Help") ]
        })), React_Basic_DOM_Generated.input()({
            className: "form-control monospace-font",
            autoComplete: "off",
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: false,
            placeholder: "expression or definition",
            onChange: React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v.onChange)),
            value: v.text
        }), inputGroupBtn(React_Basic_DOM_Generated.button()({
            className: "btn btn-default",
            type: "submit",
            onClick: React_Basic_Events.handler_(v.onSubmit),
            children: [ React_Basic_DOM.text("Parse") ]
        })) ]) ]
    });
};
module.exports = {
    component: component
};
