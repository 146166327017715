// Generated by purs version 0.14.4
"use strict";
var Components_App = require("../Components.App/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_DOM = require("../Effect.DOM/index.js");
var Effect_QueryParams = require("../Effect.QueryParams/index.js");
var Lambda_Flags = require("../Lambda.Flags/index.js");
var Lambda_Language_Snapshot_Code = require("../Lambda.Language.Snapshot.Code/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var main = function __do() {
    var app = Components_App["new"]();
    var code = Effect_QueryParams.get(Lambda_Language_Snapshot_Code.parseCode)("code")();
    var flags = Data_Functor.map(Effect.functorEffect)(Data_Maybe.fromMaybe(Lambda_Flags.none))(Effect_QueryParams.getWith(Lambda_Flags.parse)("f"))();
    var node = app({
        code: code,
        flags: flags
    });
    return Control_Bind.bindFlipped(Effect.bindEffect)(React_Basic_DOM.render(node))(Effect_DOM.getRoot)();
};
module.exports = {
    main: main
};
