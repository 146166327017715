// Generated by purs version 0.14.4
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Lambda_Language_History = require("../Lambda.Language.History/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var truncate = (function () {
    var item = function (body) {
        return React_Basic_DOM_Generated.li()({
            className: "expression",
            children: [ body ]
        });
    };
    var loop = function (n) {
        return function (v) {
            if (v instanceof Data_List_Types.Nil) {
                return Data_List_Types.Nil.value;
            };
            if (v instanceof Data_List_Types.Cons) {
                if (n <= 0) {
                    return new Data_List_Types.Cons(item(React_Basic_DOM.text("\u2026")), Data_List_Types.Nil.value);
                };
                if (Data_Boolean.otherwise) {
                    return new Data_List_Types.Cons(item(v.value0), loop(n - 1 | 0)(v.value1));
                };
            };
            throw new Error("Failed pattern match at Components.Expressions (line 29, column 12 - line 33, column 46): " + [ v.constructor.name ]);
        };
    };
    var $8 = Data_Array.fromFoldable(Data_List_Types.foldableList);
    var $9 = loop(20);
    return function ($10) {
        return $8($9($10));
    };
})();
var component = function (v) {
    return React_Basic_DOM_Generated.ul()({
        className: "unstyled scroll-overflow",
        children: truncate(Lambda_Language_History.toJSX(v.rep)(v.history))
    });
};
module.exports = {
    component: component
};
