// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Nameless = require("../Lambda.Language.Nameless/index.js");
var Lambda_Machine_Address = require("../Lambda.Machine.Address/index.js");
var Lambda_Machine_Globals = require("../Lambda.Machine.Globals/index.js");
var Lambda_Machine_Heap = require("../Lambda.Machine.Heap/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var StuckVar = (function () {
    function StuckVar(value0) {
        this.value0 = value0;
    };
    StuckVar.create = function (value0) {
        return new StuckVar(value0);
    };
    return StuckVar;
})();
var StuckLambda = (function () {
    function StuckLambda(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    StuckLambda.create = function (value0) {
        return function (value1) {
            return new StuckLambda(value0, value1);
        };
    };
    return StuckLambda;
})();
var StuckApply = (function () {
    function StuckApply(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    StuckApply.create = function (value0) {
        return function (value1) {
            return new StuckApply(value0, value1);
        };
    };
    return StuckApply;
})();
var Apply = (function () {
    function Apply(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Apply.create = function (value0) {
        return function (value1) {
            return new Apply(value0, value1);
        };
    };
    return Apply;
})();
var Closure = (function () {
    function Closure(value0) {
        this.value0 = value0;
    };
    Closure.create = function (value0) {
        return new Closure(value0);
    };
    return Closure;
})();
var Global = (function () {
    function Global(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Global.create = function (value0) {
        return function (value1) {
            return new Global(value0, value1);
        };
    };
    return Global;
})();
var Pointer = (function () {
    function Pointer(value0) {
        this.value0 = value0;
    };
    Pointer.create = function (value0) {
        return new Pointer(value0);
    };
    return Pointer;
})();
var Stuck = (function () {
    function Stuck(value0) {
        this.value0 = value0;
    };
    Stuck.create = function (value0) {
        return new Stuck(value0);
    };
    return Stuck;
})();
var genericStuck = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new StuckVar(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new StuckLambda(x.value0.value0.value0, x.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new StuckApply(x.value0.value0.value0, x.value0.value0.value1);
        };
        throw new Error("Failed pattern match at Lambda.Machine.Node (line 59, column 1 - line 59, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof StuckVar) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof StuckLambda) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1)));
        };
        if (x instanceof StuckApply) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1)));
        };
        throw new Error("Failed pattern match at Lambda.Machine.Node (line 59, column 1 - line 59, column 48): " + [ x.constructor.name ]);
    }
};
var showStuck = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericStuck)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))({
            reflectSymbol: function () {
                return "StuckVar";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress)))({
            reflectSymbol: function () {
                return "StuckLambda";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress))(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress)))({
            reflectSymbol: function () {
                return "StuckApply";
            }
        }))))(x);
    }
};
var genericNode = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Apply(x.value0.value0, x.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Closure(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Global(x.value0.value0.value0.value0, x.value0.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new Pointer(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new Stuck(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Lambda.Machine.Node (line 47, column 1 - line 47, column 46): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Apply) {
            return new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1));
        };
        if (x instanceof Closure) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Global) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1))));
        };
        if (x instanceof Pointer) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof Stuck) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at Lambda.Machine.Node (line 47, column 1 - line 47, column 46): " + [ x.constructor.name ]);
    }
};
var showNode = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericNode)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress))(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress)))({
            reflectSymbol: function () {
                return "Apply";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "body";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "env";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "fvs";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Show.showRecordFieldsNil)(Lambda_Language_Name.showName))(Data_Show.showArray(Lambda_Machine_Address.showAddress)))(Data_List_Types.showList(Lambda_Machine_Address.showAddress)))(Lambda_Language_Nameless.showNameless))))({
            reflectSymbol: function () {
                return "Closure";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress)))({
            reflectSymbol: function () {
                return "Global";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Machine_Address.showAddress))({
            reflectSymbol: function () {
                return "Pointer";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(showStuck))({
            reflectSymbol: function () {
                return "Stuck";
            }
        }))))))(x);
    }
};
var eqStuck = {
    eq: function (x) {
        return function (y) {
            if (x instanceof StuckVar && y instanceof StuckVar) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0);
            };
            if (x instanceof StuckLambda && y instanceof StuckLambda) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0) && Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value1)(y.value1);
            };
            if (x instanceof StuckApply && y instanceof StuckApply) {
                return Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value0)(y.value0) && Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value1)(y.value1);
            };
            return false;
        };
    }
};
var eqNode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Apply && y instanceof Apply) {
                return Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value0)(y.value0) && Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value1)(y.value1);
            };
            if (x instanceof Closure && y instanceof Closure) {
                return Data_Eq.eq(Lambda_Language_Nameless.eqNameless)(x.value0.body)(y.value0.body) && Data_Eq.eq(Data_List_Types.eqList(Lambda_Machine_Address.eqAddress))(x.value0.env)(y.value0.env) && Data_Eq.eq(Data_Eq.eqArray(Lambda_Machine_Address.eqAddress))(x.value0.fvs)(y.value0.fvs) && Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0.name)(y.value0.name);
            };
            if (x instanceof Global && y instanceof Global) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0) && Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value1)(y.value1);
            };
            if (x instanceof Pointer && y instanceof Pointer) {
                return Data_Eq.eq(Lambda_Machine_Address.eqAddress)(x.value0)(y.value0);
            };
            if (x instanceof Stuck && y instanceof Stuck) {
                return Data_Eq.eq(eqStuck)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var deref = function (dictShow) {
    return function (i) {
        return function (env) {
            var v = Data_List.index(env)(i);
            if (v instanceof Data_Maybe.Just) {
                return v.value0;
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Partial_Unsafe.unsafeCrashWith(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "Invalid De Bruijn index ", Data_Show.show(Data_Show.showInt)(i), " in environment ", Data_Show.show(Data_List_Types.showList(dictShow))(env) ]));
            };
            throw new Error("Failed pattern match at Lambda.Machine.Node (line 71, column 15 - line 78, column 6): " + [ v.constructor.name ]);
        };
    };
};
var instantiate = function (dictMonadState) {
    return function (env) {
        return function (v) {
            if (v instanceof Lambda_Language_Nameless.Lambda) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Data_Traversable.traverse(Data_Traversable.traversableArray)((dictMonadState.Monad0()).Applicative0())(Lambda_Machine_Globals.get(dictMonadState))(Data_Array.fromFoldable(Data_Set.foldableSet)(v.value1)))(function (fvs) {
                    return Lambda_Machine_Heap.alloc(dictMonadState)(new Closure({
                        fvs: fvs,
                        env: env,
                        name: v.value0,
                        body: v.value2
                    }));
                });
            };
            if (v instanceof Lambda_Language_Nameless.Apply) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(instantiate(dictMonadState)(env)(v.value1))(function (a) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(instantiate(dictMonadState)(env)(v.value0))(function (f) {
                        return Lambda_Machine_Heap.alloc(dictMonadState)(new Apply(f, a));
                    });
                });
            };
            if (v instanceof Lambda_Language_Nameless.Bound) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(deref(Lambda_Machine_Address.showAddress)(v.value0)(env));
            };
            if (v instanceof Lambda_Language_Nameless.Free) {
                return Lambda_Machine_Globals.get(dictMonadState)(v.value0);
            };
            throw new Error("Failed pattern match at Lambda.Machine.Node (line 107, column 19 - line 117, column 41): " + [ v.constructor.name ]);
        };
    };
};
var instantiateAt = function (dictMonadState) {
    return function (target) {
        return function (env) {
            return function (v) {
                if (v instanceof Lambda_Language_Nameless.Lambda) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Data_Traversable.traverse(Data_Traversable.traversableArray)((dictMonadState.Monad0()).Applicative0())(Lambda_Machine_Globals.get(dictMonadState))(Data_Array.fromFoldable(Data_Set.foldableSet)(v.value1)))(function (fvs) {
                        return Lambda_Machine_Heap.update(dictMonadState)(target)(new Closure({
                            fvs: fvs,
                            env: env,
                            name: v.value0,
                            body: v.value2
                        }));
                    });
                };
                if (v instanceof Lambda_Language_Nameless.Apply) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(instantiate(dictMonadState)(env)(v.value1))(function (a) {
                        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(instantiate(dictMonadState)(env)(v.value0))(function (f) {
                            return Lambda_Machine_Heap.update(dictMonadState)(target)(new Apply(f, a));
                        });
                    });
                };
                if (v instanceof Lambda_Language_Nameless.Bound) {
                    return Lambda_Machine_Heap.update(dictMonadState)(target)(Pointer.create(deref(Lambda_Machine_Address.showAddress)(v.value0)(env)));
                };
                if (v instanceof Lambda_Language_Nameless.Free) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Lambda_Machine_Globals.get(dictMonadState)(v.value0))(function (addr) {
                        return Lambda_Machine_Heap.update(dictMonadState)(target)(new Pointer(addr));
                    });
                };
                throw new Error("Failed pattern match at Lambda.Machine.Node (line 129, column 28 - line 141, column 38): " + [ v.constructor.name ]);
            };
        };
    };
};
var compile = function (dictMonadState) {
    return instantiate(dictMonadState)(Data_List_Types.Nil.value);
};
var define = function (dictMonadState) {
    return function (name) {
        return function (expr) {
            return Lambda_Machine_Globals.add(dictMonadState)(name)(function (v) {
                return Data_Functor.map((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Global.create(name))(compile(dictMonadState)(expr));
            });
        };
    };
};
var children = function (v) {
    if (v instanceof Apply) {
        return [ v.value0, v.value1 ];
    };
    if (v instanceof Closure) {
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0.fvs)(Data_Array.fromFoldable(Data_List_Types.foldableList)(v.value0.env));
    };
    if (v instanceof Global) {
        return [ v.value1 ];
    };
    if (v instanceof Stuck && v.value0 instanceof StuckVar) {
        return [  ];
    };
    if (v instanceof Stuck && v.value0 instanceof StuckLambda) {
        return [ v.value0.value1 ];
    };
    if (v instanceof Stuck && v.value0 instanceof StuckApply) {
        return [ v.value0.value0, v.value0.value1 ];
    };
    if (v instanceof Pointer) {
        return [ v.value0 ];
    };
    throw new Error("Failed pattern match at Lambda.Machine.Node (line 146, column 12 - line 153, column 25): " + [ v.constructor.name ]);
};
module.exports = {
    Apply: Apply,
    Closure: Closure,
    Global: Global,
    Pointer: Pointer,
    Stuck: Stuck,
    StuckVar: StuckVar,
    StuckLambda: StuckLambda,
    StuckApply: StuckApply,
    deref: deref,
    define: define,
    compile: compile,
    instantiate: instantiate,
    instantiateAt: instantiateAt,
    children: children,
    eqNode: eqNode,
    genericNode: genericNode,
    showNode: showNode,
    eqStuck: eqStuck,
    genericStuck: genericStuck,
    showStuck: showStuck
};
