// Generated by purs version 0.14.4
"use strict";
var Help = (function () {
    function Help() {

    };
    Help.value = new Help();
    return Help;
})();
var Dismiss = (function () {
    function Dismiss() {

    };
    Dismiss.value = new Dismiss();
    return Dismiss;
})();
var Update = (function () {
    function Update(value0) {
        this.value0 = value0;
    };
    Update.create = function (value0) {
        return new Update(value0);
    };
    return Update;
})();
var Parse = (function () {
    function Parse() {

    };
    Parse.value = new Parse();
    return Parse;
})();
var Delete = (function () {
    function Delete(value0) {
        this.value0 = value0;
    };
    Delete.create = function (value0) {
        return new Delete(value0);
    };
    return Delete;
})();
var Step = (function () {
    function Step() {

    };
    Step.value = new Step();
    return Step;
})();
var Clear = (function () {
    function Clear() {

    };
    Clear.value = new Clear();
    return Clear;
})();
var Toggle = (function () {
    function Toggle() {

    };
    Toggle.value = new Toggle();
    return Toggle;
})();
var Enqueue = (function () {
    function Enqueue(value0) {
        this.value0 = value0;
    };
    Enqueue.create = function (value0) {
        return new Enqueue(value0);
    };
    return Enqueue;
})();
var Examine = (function () {
    function Examine(value0) {
        this.value0 = value0;
    };
    Examine.create = function (value0) {
        return new Examine(value0);
    };
    return Examine;
})();
module.exports = {
    Help: Help,
    Dismiss: Dismiss,
    Update: Update,
    Parse: Parse,
    Delete: Delete,
    Step: Step,
    Clear: Clear,
    Toggle: Toggle,
    Enqueue: Enqueue,
    Examine: Examine
};
