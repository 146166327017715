// Generated by purs version 0.14.4
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Record = require("../Record/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var EventFn = function (x) {
    return x;
};
var unsafeEventFn = EventFn;
var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
var mergeNil = {
    mergeImpl: function (v) {
        return function (v1) {
            return function (v2) {
                return {};
            };
        };
    }
};
var mergeImpl = function (dict) {
    return dict.mergeImpl;
};
var mergeCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictCons1) {
            return function (dictLacks) {
                return function (dictLacks1) {
                    return function (dictMerge) {
                        return {
                            mergeImpl: function (v) {
                                return function (fns) {
                                    return function (a) {
                                        var v1 = mergeImpl(dictMerge)(Type_Data_RowList.RLProxy.value)(Record["delete"](dictIsSymbol)()()(Data_Symbol.SProxy.value)(fns));
                                        var v2 = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(fns);
                                        return Record.insert(dictIsSymbol)()()(Data_Symbol.SProxy.value)(v2(a))(v1(a));
                                    };
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var merge = function (dictRowToList) {
    return function (dictMerge) {
        return mergeImpl(dictMerge)(Type_Data_RowList.RLProxy.value);
    };
};
var handler_ = function ($22) {
    return Effect_Uncurried.mkEffectFn1(Data_Function["const"]($22));
};
var handler = function (v) {
    return function (cb) {
        return function ($23) {
            return cb(v($23))();
        };
    };
};
var categoryBuilder = Control_Category.categoryFn;
var syntheticEvent = Control_Category.identity(categoryBuilder);
module.exports = {
    unsafeEventFn: unsafeEventFn,
    handler: handler,
    handler_: handler_,
    syntheticEvent: syntheticEvent,
    merge: merge,
    mergeImpl: mergeImpl,
    semigroupoidBuilder: semigroupoidBuilder,
    categoryBuilder: categoryBuilder,
    mergeNil: mergeNil,
    mergeCons: mergeCons
};
