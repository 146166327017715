// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Data_Either = require("../Data.Either/index.js");
var Effect_Aff_Compat = require("../Effect.Aff.Compat/index.js");
var copy = (function () {
    var $0 = $foreign.copyImpl(Data_Either.Left.create)(Data_Either.Right.create);
    return function ($1) {
        return Effect_Aff_Compat.fromEffectFnAff($0($1));
    };
})();
module.exports = {
    copy: copy
};
