// Generated by purs version 0.14.4
"use strict";
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var _wbr$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("wbr"));
var wbr$prime = function (dictUnion) {
    return _wbr$prime;
};
var wbr = function (dictUnion) {
    return React_Basic.element(wbr$prime());
};
var _video$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("video"));
var video$prime = function (dictUnion) {
    return _video$prime;
};
var video = function (dictUnion) {
    return React_Basic.element(video$prime());
};
var video_ = function (children) {
    return video()({
        children: children
    });
};
var _var$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("var"));
var var$prime = function (dictUnion) {
    return _var$prime;
};
var $$var = function (dictUnion) {
    return React_Basic.element(var$prime());
};
var var_ = function (children) {
    return $$var()({
        children: children
    });
};
var _ul$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ul"));
var ul$prime = function (dictUnion) {
    return _ul$prime;
};
var ul = function (dictUnion) {
    return React_Basic.element(ul$prime());
};
var ul_ = function (children) {
    return ul()({
        children: children
    });
};
var _u$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("u"));
var u$prime = function (dictUnion) {
    return _u$prime;
};
var u = function (dictUnion) {
    return React_Basic.element(u$prime());
};
var u_ = function (children) {
    return u()({
        children: children
    });
};
var _track$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("track"));
var track$prime = function (dictUnion) {
    return _track$prime;
};
var track = function (dictUnion) {
    return React_Basic.element(track$prime());
};
var _tr$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tr"));
var tr$prime = function (dictUnion) {
    return _tr$prime;
};
var tr = function (dictUnion) {
    return React_Basic.element(tr$prime());
};
var tr_ = function (children) {
    return tr()({
        children: children
    });
};
var _title$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("title"));
var title$prime = function (dictUnion) {
    return _title$prime;
};
var title = function (dictUnion) {
    return React_Basic.element(title$prime());
};
var title_ = function (children) {
    return title()({
        children: children
    });
};
var _time$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("time"));
var time$prime = function (dictUnion) {
    return _time$prime;
};
var time = function (dictUnion) {
    return React_Basic.element(time$prime());
};
var time_ = function (children) {
    return time()({
        children: children
    });
};
var _thead$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("thead"));
var thead$prime = function (dictUnion) {
    return _thead$prime;
};
var thead = function (dictUnion) {
    return React_Basic.element(thead$prime());
};
var thead_ = function (children) {
    return thead()({
        children: children
    });
};
var _th$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("th"));
var th$prime = function (dictUnion) {
    return _th$prime;
};
var th = function (dictUnion) {
    return React_Basic.element(th$prime());
};
var th_ = function (children) {
    return th()({
        children: children
    });
};
var _tfoot$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tfoot"));
var tfoot$prime = function (dictUnion) {
    return _tfoot$prime;
};
var tfoot = function (dictUnion) {
    return React_Basic.element(tfoot$prime());
};
var tfoot_ = function (children) {
    return tfoot()({
        children: children
    });
};
var _textarea$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("textarea"));
var textarea$prime = function (dictUnion) {
    return _textarea$prime;
};
var textarea = function (dictUnion) {
    return React_Basic.element(textarea$prime());
};
var textarea_ = function (children) {
    return textarea()({
        children: children
    });
};
var _template$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("template"));
var template$prime = function (dictUnion) {
    return _template$prime;
};
var template = function (dictUnion) {
    return React_Basic.element(template$prime());
};
var template_ = function (children) {
    return template()({
        children: children
    });
};
var _td$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("td"));
var td$prime = function (dictUnion) {
    return _td$prime;
};
var td = function (dictUnion) {
    return React_Basic.element(td$prime());
};
var td_ = function (children) {
    return td()({
        children: children
    });
};
var _tbody$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tbody"));
var tbody$prime = function (dictUnion) {
    return _tbody$prime;
};
var tbody = function (dictUnion) {
    return React_Basic.element(tbody$prime());
};
var tbody_ = function (children) {
    return tbody()({
        children: children
    });
};
var _table$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("table"));
var table$prime = function (dictUnion) {
    return _table$prime;
};
var table = function (dictUnion) {
    return React_Basic.element(table$prime());
};
var table_ = function (children) {
    return table()({
        children: children
    });
};
var _sup$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("sup"));
var sup$prime = function (dictUnion) {
    return _sup$prime;
};
var sup = function (dictUnion) {
    return React_Basic.element(sup$prime());
};
var sup_ = function (children) {
    return sup()({
        children: children
    });
};
var _summary$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("summary"));
var summary$prime = function (dictUnion) {
    return _summary$prime;
};
var summary = function (dictUnion) {
    return React_Basic.element(summary$prime());
};
var summary_ = function (children) {
    return summary()({
        children: children
    });
};
var _sub$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("sub"));
var sub$prime = function (dictUnion) {
    return _sub$prime;
};
var sub = function (dictUnion) {
    return React_Basic.element(sub$prime());
};
var sub_ = function (children) {
    return sub()({
        children: children
    });
};
var _style$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("style"));
var style$prime = function (dictUnion) {
    return _style$prime;
};
var style = function (dictUnion) {
    return React_Basic.element(style$prime());
};
var style_ = function (children) {
    return style()({
        children: children
    });
};
var _strong$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("strong"));
var strong$prime = function (dictUnion) {
    return _strong$prime;
};
var strong = function (dictUnion) {
    return React_Basic.element(strong$prime());
};
var strong_ = function (children) {
    return strong()({
        children: children
    });
};
var _span$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("span"));
var span$prime = function (dictUnion) {
    return _span$prime;
};
var span = function (dictUnion) {
    return React_Basic.element(span$prime());
};
var span_ = function (children) {
    return span()({
        children: children
    });
};
var _source$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("source"));
var source$prime = function (dictUnion) {
    return _source$prime;
};
var source = function (dictUnion) {
    return React_Basic.element(source$prime());
};
var _small$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("small"));
var small$prime = function (dictUnion) {
    return _small$prime;
};
var small = function (dictUnion) {
    return React_Basic.element(small$prime());
};
var small_ = function (children) {
    return small()({
        children: children
    });
};
var _slot$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("slot"));
var slot$prime = function (dictUnion) {
    return _slot$prime;
};
var slot = function (dictUnion) {
    return React_Basic.element(slot$prime());
};
var slot_ = function (children) {
    return slot()({
        children: children
    });
};
var _select$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("select"));
var select$prime = function (dictUnion) {
    return _select$prime;
};
var select = function (dictUnion) {
    return React_Basic.element(select$prime());
};
var select_ = function (children) {
    return select()({
        children: children
    });
};
var _section$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("section"));
var section$prime = function (dictUnion) {
    return _section$prime;
};
var section = function (dictUnion) {
    return React_Basic.element(section$prime());
};
var section_ = function (children) {
    return section()({
        children: children
    });
};
var _script$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("script"));
var script$prime = function (dictUnion) {
    return _script$prime;
};
var script = function (dictUnion) {
    return React_Basic.element(script$prime());
};
var script_ = function (children) {
    return script()({
        children: children
    });
};
var _samp$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("samp"));
var samp$prime = function (dictUnion) {
    return _samp$prime;
};
var samp = function (dictUnion) {
    return React_Basic.element(samp$prime());
};
var samp_ = function (children) {
    return samp()({
        children: children
    });
};
var _s$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("s"));
var s$prime = function (dictUnion) {
    return _s$prime;
};
var s = function (dictUnion) {
    return React_Basic.element(s$prime());
};
var s_ = function (children) {
    return s()({
        children: children
    });
};
var _ruby$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ruby"));
var ruby$prime = function (dictUnion) {
    return _ruby$prime;
};
var ruby = function (dictUnion) {
    return React_Basic.element(ruby$prime());
};
var ruby_ = function (children) {
    return ruby()({
        children: children
    });
};
var _rtc$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rtc"));
var rtc$prime = function (dictUnion) {
    return _rtc$prime;
};
var rtc = function (dictUnion) {
    return React_Basic.element(rtc$prime());
};
var rtc_ = function (children) {
    return rtc()({
        children: children
    });
};
var _rt$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rt"));
var rt$prime = function (dictUnion) {
    return _rt$prime;
};
var rt = function (dictUnion) {
    return React_Basic.element(rt$prime());
};
var rt_ = function (children) {
    return rt()({
        children: children
    });
};
var _rp$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rp"));
var rp$prime = function (dictUnion) {
    return _rp$prime;
};
var rp = function (dictUnion) {
    return React_Basic.element(rp$prime());
};
var rp_ = function (children) {
    return rp()({
        children: children
    });
};
var _rb$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rb"));
var rb$prime = function (dictUnion) {
    return _rb$prime;
};
var rb = function (dictUnion) {
    return React_Basic.element(rb$prime());
};
var rb_ = function (children) {
    return rb()({
        children: children
    });
};
var _q$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("q"));
var q$prime = function (dictUnion) {
    return _q$prime;
};
var q = function (dictUnion) {
    return React_Basic.element(q$prime());
};
var q_ = function (children) {
    return q()({
        children: children
    });
};
var _progress$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("progress"));
var progress$prime = function (dictUnion) {
    return _progress$prime;
};
var progress = function (dictUnion) {
    return React_Basic.element(progress$prime());
};
var progress_ = function (children) {
    return progress()({
        children: children
    });
};
var _pre$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("pre"));
var pre$prime = function (dictUnion) {
    return _pre$prime;
};
var pre = function (dictUnion) {
    return React_Basic.element(pre$prime());
};
var pre_ = function (children) {
    return pre()({
        children: children
    });
};
var _picture$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("picture"));
var picture$prime = function (dictUnion) {
    return _picture$prime;
};
var picture = function (dictUnion) {
    return React_Basic.element(picture$prime());
};
var picture_ = function (children) {
    return picture()({
        children: children
    });
};
var _param$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("param"));
var param$prime = function (dictUnion) {
    return _param$prime;
};
var param = function (dictUnion) {
    return React_Basic.element(param$prime());
};
var _p$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("p"));
var p$prime = function (dictUnion) {
    return _p$prime;
};
var p = function (dictUnion) {
    return React_Basic.element(p$prime());
};
var p_ = function (children) {
    return p()({
        children: children
    });
};
var _output$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("output"));
var output$prime = function (dictUnion) {
    return _output$prime;
};
var output = function (dictUnion) {
    return React_Basic.element(output$prime());
};
var output_ = function (children) {
    return output()({
        children: children
    });
};
var _option$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("option"));
var option$prime = function (dictUnion) {
    return _option$prime;
};
var option = function (dictUnion) {
    return React_Basic.element(option$prime());
};
var option_ = function (children) {
    return option()({
        children: children
    });
};
var _optgroup$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("optgroup"));
var optgroup$prime = function (dictUnion) {
    return _optgroup$prime;
};
var optgroup = function (dictUnion) {
    return React_Basic.element(optgroup$prime());
};
var optgroup_ = function (children) {
    return optgroup()({
        children: children
    });
};
var _ol$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ol"));
var ol$prime = function (dictUnion) {
    return _ol$prime;
};
var ol = function (dictUnion) {
    return React_Basic.element(ol$prime());
};
var ol_ = function (children) {
    return ol()({
        children: children
    });
};
var _object$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("object"));
var object$prime = function (dictUnion) {
    return _object$prime;
};
var object = function (dictUnion) {
    return React_Basic.element(object$prime());
};
var object_ = function (children) {
    return object()({
        children: children
    });
};
var _noscript$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("noscript"));
var noscript$prime = function (dictUnion) {
    return _noscript$prime;
};
var noscript = function (dictUnion) {
    return React_Basic.element(noscript$prime());
};
var noscript_ = function (children) {
    return noscript()({
        children: children
    });
};
var _nav$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("nav"));
var nav$prime = function (dictUnion) {
    return _nav$prime;
};
var nav = function (dictUnion) {
    return React_Basic.element(nav$prime());
};
var nav_ = function (children) {
    return nav()({
        children: children
    });
};
var _meter$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("meter"));
var meter$prime = function (dictUnion) {
    return _meter$prime;
};
var meter = function (dictUnion) {
    return React_Basic.element(meter$prime());
};
var meter_ = function (children) {
    return meter()({
        children: children
    });
};
var _meta$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("meta"));
var meta$prime = function (dictUnion) {
    return _meta$prime;
};
var meta = function (dictUnion) {
    return React_Basic.element(meta$prime());
};
var _menuitem$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("menuitem"));
var menuitem$prime = function (dictUnion) {
    return _menuitem$prime;
};
var menuitem = function (dictUnion) {
    return React_Basic.element(menuitem$prime());
};
var menuitem_ = function (children) {
    return menuitem()({
        children: children
    });
};
var _menu$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("menu"));
var menu$prime = function (dictUnion) {
    return _menu$prime;
};
var menu = function (dictUnion) {
    return React_Basic.element(menu$prime());
};
var menu_ = function (children) {
    return menu()({
        children: children
    });
};
var _math$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("math"));
var math$prime = function (dictUnion) {
    return _math$prime;
};
var math = function (dictUnion) {
    return React_Basic.element(math$prime());
};
var math_ = function (children) {
    return math()({
        children: children
    });
};
var _mark$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("mark"));
var mark$prime = function (dictUnion) {
    return _mark$prime;
};
var mark = function (dictUnion) {
    return React_Basic.element(mark$prime());
};
var mark_ = function (children) {
    return mark()({
        children: children
    });
};
var _map$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("map"));
var map$prime = function (dictUnion) {
    return _map$prime;
};
var map = function (dictUnion) {
    return React_Basic.element(map$prime());
};
var map_ = function (children) {
    return map()({
        children: children
    });
};
var _main$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("main"));
var main$prime = function (dictUnion) {
    return _main$prime;
};
var main = function (dictUnion) {
    return React_Basic.element(main$prime());
};
var main_ = function (children) {
    return main()({
        children: children
    });
};
var _link$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("link"));
var link$prime = function (dictUnion) {
    return _link$prime;
};
var link = function (dictUnion) {
    return React_Basic.element(link$prime());
};
var _li$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("li"));
var li$prime = function (dictUnion) {
    return _li$prime;
};
var li = function (dictUnion) {
    return React_Basic.element(li$prime());
};
var li_ = function (children) {
    return li()({
        children: children
    });
};
var _legend$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("legend"));
var legend$prime = function (dictUnion) {
    return _legend$prime;
};
var legend = function (dictUnion) {
    return React_Basic.element(legend$prime());
};
var legend_ = function (children) {
    return legend()({
        children: children
    });
};
var _label$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("label"));
var label$prime = function (dictUnion) {
    return _label$prime;
};
var label = function (dictUnion) {
    return React_Basic.element(label$prime());
};
var label_ = function (children) {
    return label()({
        children: children
    });
};
var _keygen$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("keygen"));
var keygen$prime = function (dictUnion) {
    return _keygen$prime;
};
var keygen = function (dictUnion) {
    return React_Basic.element(keygen$prime());
};
var keygen_ = function (children) {
    return keygen()({
        children: children
    });
};
var _kbd$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("kbd"));
var kbd$prime = function (dictUnion) {
    return _kbd$prime;
};
var kbd = function (dictUnion) {
    return React_Basic.element(kbd$prime());
};
var kbd_ = function (children) {
    return kbd()({
        children: children
    });
};
var _ins$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ins"));
var ins$prime = function (dictUnion) {
    return _ins$prime;
};
var ins = function (dictUnion) {
    return React_Basic.element(ins$prime());
};
var ins_ = function (children) {
    return ins()({
        children: children
    });
};
var _input$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("input"));
var input$prime = function (dictUnion) {
    return _input$prime;
};
var input = function (dictUnion) {
    return React_Basic.element(input$prime());
};
var _img$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("img"));
var img$prime = function (dictUnion) {
    return _img$prime;
};
var img = function (dictUnion) {
    return React_Basic.element(img$prime());
};
var _iframe$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("iframe"));
var iframe$prime = function (dictUnion) {
    return _iframe$prime;
};
var iframe = function (dictUnion) {
    return React_Basic.element(iframe$prime());
};
var iframe_ = function (children) {
    return iframe()({
        children: children
    });
};
var _i$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("i"));
var i$prime = function (dictUnion) {
    return _i$prime;
};
var i = function (dictUnion) {
    return React_Basic.element(i$prime());
};
var i_ = function (children) {
    return i()({
        children: children
    });
};
var _html$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("html"));
var html$prime = function (dictUnion) {
    return _html$prime;
};
var html = function (dictUnion) {
    return React_Basic.element(html$prime());
};
var html_ = function (children) {
    return html()({
        children: children
    });
};
var _hr$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("hr"));
var hr$prime = function (dictUnion) {
    return _hr$prime;
};
var hr = function (dictUnion) {
    return React_Basic.element(hr$prime());
};
var _hgroup$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("hgroup"));
var hgroup$prime = function (dictUnion) {
    return _hgroup$prime;
};
var hgroup = function (dictUnion) {
    return React_Basic.element(hgroup$prime());
};
var hgroup_ = function (children) {
    return hgroup()({
        children: children
    });
};
var _header$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("header"));
var header$prime = function (dictUnion) {
    return _header$prime;
};
var header = function (dictUnion) {
    return React_Basic.element(header$prime());
};
var header_ = function (children) {
    return header()({
        children: children
    });
};
var _head$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("head"));
var head$prime = function (dictUnion) {
    return _head$prime;
};
var head = function (dictUnion) {
    return React_Basic.element(head$prime());
};
var head_ = function (children) {
    return head()({
        children: children
    });
};
var _h6$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h6"));
var h6$prime = function (dictUnion) {
    return _h6$prime;
};
var h6 = function (dictUnion) {
    return React_Basic.element(h6$prime());
};
var h6_ = function (children) {
    return h6()({
        children: children
    });
};
var _h5$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h5"));
var h5$prime = function (dictUnion) {
    return _h5$prime;
};
var h5 = function (dictUnion) {
    return React_Basic.element(h5$prime());
};
var h5_ = function (children) {
    return h5()({
        children: children
    });
};
var _h4$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h4"));
var h4$prime = function (dictUnion) {
    return _h4$prime;
};
var h4 = function (dictUnion) {
    return React_Basic.element(h4$prime());
};
var h4_ = function (children) {
    return h4()({
        children: children
    });
};
var _h3$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h3"));
var h3$prime = function (dictUnion) {
    return _h3$prime;
};
var h3 = function (dictUnion) {
    return React_Basic.element(h3$prime());
};
var h3_ = function (children) {
    return h3()({
        children: children
    });
};
var _h2$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h2"));
var h2$prime = function (dictUnion) {
    return _h2$prime;
};
var h2 = function (dictUnion) {
    return React_Basic.element(h2$prime());
};
var h2_ = function (children) {
    return h2()({
        children: children
    });
};
var _h1$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h1"));
var h1$prime = function (dictUnion) {
    return _h1$prime;
};
var h1 = function (dictUnion) {
    return React_Basic.element(h1$prime());
};
var h1_ = function (children) {
    return h1()({
        children: children
    });
};
var _form$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("form"));
var form$prime = function (dictUnion) {
    return _form$prime;
};
var form = function (dictUnion) {
    return React_Basic.element(form$prime());
};
var form_ = function (children) {
    return form()({
        children: children
    });
};
var _footer$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("footer"));
var footer$prime = function (dictUnion) {
    return _footer$prime;
};
var footer = function (dictUnion) {
    return React_Basic.element(footer$prime());
};
var footer_ = function (children) {
    return footer()({
        children: children
    });
};
var _figure$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("figure"));
var figure$prime = function (dictUnion) {
    return _figure$prime;
};
var figure = function (dictUnion) {
    return React_Basic.element(figure$prime());
};
var figure_ = function (children) {
    return figure()({
        children: children
    });
};
var _figcaption$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("figcaption"));
var figcaption$prime = function (dictUnion) {
    return _figcaption$prime;
};
var figcaption = function (dictUnion) {
    return React_Basic.element(figcaption$prime());
};
var figcaption_ = function (children) {
    return figcaption()({
        children: children
    });
};
var _fieldset$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("fieldset"));
var fieldset$prime = function (dictUnion) {
    return _fieldset$prime;
};
var fieldset = function (dictUnion) {
    return React_Basic.element(fieldset$prime());
};
var fieldset_ = function (children) {
    return fieldset()({
        children: children
    });
};
var _embed$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("embed"));
var embed$prime = function (dictUnion) {
    return _embed$prime;
};
var embed = function (dictUnion) {
    return React_Basic.element(embed$prime());
};
var _em$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("em"));
var em$prime = function (dictUnion) {
    return _em$prime;
};
var em = function (dictUnion) {
    return React_Basic.element(em$prime());
};
var em_ = function (children) {
    return em()({
        children: children
    });
};
var _dt$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dt"));
var dt$prime = function (dictUnion) {
    return _dt$prime;
};
var dt = function (dictUnion) {
    return React_Basic.element(dt$prime());
};
var dt_ = function (children) {
    return dt()({
        children: children
    });
};
var _dl$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dl"));
var dl$prime = function (dictUnion) {
    return _dl$prime;
};
var dl = function (dictUnion) {
    return React_Basic.element(dl$prime());
};
var dl_ = function (children) {
    return dl()({
        children: children
    });
};
var _div$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("div"));
var div$prime = function (dictUnion) {
    return _div$prime;
};
var div = function (dictUnion) {
    return React_Basic.element(div$prime());
};
var div_ = function (children) {
    return div()({
        children: children
    });
};
var _dialog$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dialog"));
var dialog$prime = function (dictUnion) {
    return _dialog$prime;
};
var dialog = function (dictUnion) {
    return React_Basic.element(dialog$prime());
};
var dialog_ = function (children) {
    return dialog()({
        children: children
    });
};
var _dfn$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dfn"));
var dfn$prime = function (dictUnion) {
    return _dfn$prime;
};
var dfn = function (dictUnion) {
    return React_Basic.element(dfn$prime());
};
var dfn_ = function (children) {
    return dfn()({
        children: children
    });
};
var _details$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("details"));
var details$prime = function (dictUnion) {
    return _details$prime;
};
var details = function (dictUnion) {
    return React_Basic.element(details$prime());
};
var details_ = function (children) {
    return details()({
        children: children
    });
};
var _del$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("del"));
var del$prime = function (dictUnion) {
    return _del$prime;
};
var del = function (dictUnion) {
    return React_Basic.element(del$prime());
};
var del_ = function (children) {
    return del()({
        children: children
    });
};
var _dd$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dd"));
var dd$prime = function (dictUnion) {
    return _dd$prime;
};
var dd = function (dictUnion) {
    return React_Basic.element(dd$prime());
};
var dd_ = function (children) {
    return dd()({
        children: children
    });
};
var _datalist$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("datalist"));
var datalist$prime = function (dictUnion) {
    return _datalist$prime;
};
var datalist = function (dictUnion) {
    return React_Basic.element(datalist$prime());
};
var datalist_ = function (children) {
    return datalist()({
        children: children
    });
};
var _data$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("data"));
var data$prime$prime = function (dictUnion) {
    return _data$prime;
};
var data$prime = function (dictUnion) {
    return React_Basic.element(data$prime$prime());
};
var data_ = function (children) {
    return data$prime()({
        children: children
    });
};
var _colgroup$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("colgroup"));
var colgroup$prime = function (dictUnion) {
    return _colgroup$prime;
};
var colgroup = function (dictUnion) {
    return React_Basic.element(colgroup$prime());
};
var colgroup_ = function (children) {
    return colgroup()({
        children: children
    });
};
var _col$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("col"));
var col$prime = function (dictUnion) {
    return _col$prime;
};
var col = function (dictUnion) {
    return React_Basic.element(col$prime());
};
var _code$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("code"));
var code$prime = function (dictUnion) {
    return _code$prime;
};
var code = function (dictUnion) {
    return React_Basic.element(code$prime());
};
var code_ = function (children) {
    return code()({
        children: children
    });
};
var _cite$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("cite"));
var cite$prime = function (dictUnion) {
    return _cite$prime;
};
var cite = function (dictUnion) {
    return React_Basic.element(cite$prime());
};
var cite_ = function (children) {
    return cite()({
        children: children
    });
};
var _caption$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("caption"));
var caption$prime = function (dictUnion) {
    return _caption$prime;
};
var caption = function (dictUnion) {
    return React_Basic.element(caption$prime());
};
var caption_ = function (children) {
    return caption()({
        children: children
    });
};
var _canvas$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("canvas"));
var canvas$prime = function (dictUnion) {
    return _canvas$prime;
};
var canvas = function (dictUnion) {
    return React_Basic.element(canvas$prime());
};
var canvas_ = function (children) {
    return canvas()({
        children: children
    });
};
var _button$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("button"));
var button$prime = function (dictUnion) {
    return _button$prime;
};
var button = function (dictUnion) {
    return React_Basic.element(button$prime());
};
var button_ = function (children) {
    return button()({
        children: children
    });
};
var _br$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("br"));
var br$prime = function (dictUnion) {
    return _br$prime;
};
var br = function (dictUnion) {
    return React_Basic.element(br$prime());
};
var _body$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("body"));
var body$prime = function (dictUnion) {
    return _body$prime;
};
var body = function (dictUnion) {
    return React_Basic.element(body$prime());
};
var body_ = function (children) {
    return body()({
        children: children
    });
};
var _blockquote$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("blockquote"));
var blockquote$prime = function (dictUnion) {
    return _blockquote$prime;
};
var blockquote = function (dictUnion) {
    return React_Basic.element(blockquote$prime());
};
var blockquote_ = function (children) {
    return blockquote()({
        children: children
    });
};
var _bdo$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdo"));
var bdo$prime = function (dictUnion) {
    return _bdo$prime;
};
var bdo = function (dictUnion) {
    return React_Basic.element(bdo$prime());
};
var bdo_ = function (children) {
    return bdo()({
        children: children
    });
};
var _bdi$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdi"));
var bdi$prime = function (dictUnion) {
    return _bdi$prime;
};
var bdi = function (dictUnion) {
    return React_Basic.element(bdi$prime());
};
var bdi_ = function (children) {
    return bdi()({
        children: children
    });
};
var _base$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("base"));
var base$prime = function (dictUnion) {
    return _base$prime;
};
var base = function (dictUnion) {
    return React_Basic.element(base$prime());
};
var _b$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("b"));
var b$prime = function (dictUnion) {
    return _b$prime;
};
var b = function (dictUnion) {
    return React_Basic.element(b$prime());
};
var b_ = function (children) {
    return b()({
        children: children
    });
};
var _audio$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("audio"));
var audio$prime = function (dictUnion) {
    return _audio$prime;
};
var audio = function (dictUnion) {
    return React_Basic.element(audio$prime());
};
var audio_ = function (children) {
    return audio()({
        children: children
    });
};
var _aside$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("aside"));
var aside$prime = function (dictUnion) {
    return _aside$prime;
};
var aside = function (dictUnion) {
    return React_Basic.element(aside$prime());
};
var aside_ = function (children) {
    return aside()({
        children: children
    });
};
var _article$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("article"));
var article$prime = function (dictUnion) {
    return _article$prime;
};
var article = function (dictUnion) {
    return React_Basic.element(article$prime());
};
var article_ = function (children) {
    return article()({
        children: children
    });
};
var _area$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("area"));
var area$prime = function (dictUnion) {
    return _area$prime;
};
var area = function (dictUnion) {
    return React_Basic.element(area$prime());
};
var _address$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("address"));
var address$prime = function (dictUnion) {
    return _address$prime;
};
var address = function (dictUnion) {
    return React_Basic.element(address$prime());
};
var address_ = function (children) {
    return address()({
        children: children
    });
};
var _abbr$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("abbr"));
var abbr$prime = function (dictUnion) {
    return _abbr$prime;
};
var abbr = function (dictUnion) {
    return React_Basic.element(abbr$prime());
};
var abbr_ = function (children) {
    return abbr()({
        children: children
    });
};
var _a$prime = Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("a"));
var a$prime = function (dictUnion) {
    return _a$prime;
};
var a = function (dictUnion) {
    return React_Basic.element(a$prime());
};
var a_ = function (children) {
    return a()({
        children: children
    });
};
module.exports = {
    a: a,
    a_: a_,
    "a'": a$prime,
    "_a'": _a$prime,
    abbr: abbr,
    abbr_: abbr_,
    "abbr'": abbr$prime,
    "_abbr'": _abbr$prime,
    address: address,
    address_: address_,
    "address'": address$prime,
    "_address'": _address$prime,
    area: area,
    "area'": area$prime,
    "_area'": _area$prime,
    article: article,
    article_: article_,
    "article'": article$prime,
    "_article'": _article$prime,
    aside: aside,
    aside_: aside_,
    "aside'": aside$prime,
    "_aside'": _aside$prime,
    audio: audio,
    audio_: audio_,
    "audio'": audio$prime,
    "_audio'": _audio$prime,
    b: b,
    b_: b_,
    "b'": b$prime,
    "_b'": _b$prime,
    base: base,
    "base'": base$prime,
    "_base'": _base$prime,
    bdi: bdi,
    bdi_: bdi_,
    "bdi'": bdi$prime,
    "_bdi'": _bdi$prime,
    bdo: bdo,
    bdo_: bdo_,
    "bdo'": bdo$prime,
    "_bdo'": _bdo$prime,
    blockquote: blockquote,
    blockquote_: blockquote_,
    "blockquote'": blockquote$prime,
    "_blockquote'": _blockquote$prime,
    body: body,
    body_: body_,
    "body'": body$prime,
    "_body'": _body$prime,
    br: br,
    "br'": br$prime,
    "_br'": _br$prime,
    button: button,
    button_: button_,
    "button'": button$prime,
    "_button'": _button$prime,
    canvas: canvas,
    canvas_: canvas_,
    "canvas'": canvas$prime,
    "_canvas'": _canvas$prime,
    caption: caption,
    caption_: caption_,
    "caption'": caption$prime,
    "_caption'": _caption$prime,
    cite: cite,
    cite_: cite_,
    "cite'": cite$prime,
    "_cite'": _cite$prime,
    code: code,
    code_: code_,
    "code'": code$prime,
    "_code'": _code$prime,
    col: col,
    "col'": col$prime,
    "_col'": _col$prime,
    colgroup: colgroup,
    colgroup_: colgroup_,
    "colgroup'": colgroup$prime,
    "_colgroup'": _colgroup$prime,
    "data'": data$prime,
    data_: data_,
    "data''": data$prime$prime,
    "_data'": _data$prime,
    datalist: datalist,
    datalist_: datalist_,
    "datalist'": datalist$prime,
    "_datalist'": _datalist$prime,
    dd: dd,
    dd_: dd_,
    "dd'": dd$prime,
    "_dd'": _dd$prime,
    del: del,
    del_: del_,
    "del'": del$prime,
    "_del'": _del$prime,
    details: details,
    details_: details_,
    "details'": details$prime,
    "_details'": _details$prime,
    dfn: dfn,
    dfn_: dfn_,
    "dfn'": dfn$prime,
    "_dfn'": _dfn$prime,
    dialog: dialog,
    dialog_: dialog_,
    "dialog'": dialog$prime,
    "_dialog'": _dialog$prime,
    div: div,
    div_: div_,
    "div'": div$prime,
    "_div'": _div$prime,
    dl: dl,
    dl_: dl_,
    "dl'": dl$prime,
    "_dl'": _dl$prime,
    dt: dt,
    dt_: dt_,
    "dt'": dt$prime,
    "_dt'": _dt$prime,
    em: em,
    em_: em_,
    "em'": em$prime,
    "_em'": _em$prime,
    embed: embed,
    "embed'": embed$prime,
    "_embed'": _embed$prime,
    fieldset: fieldset,
    fieldset_: fieldset_,
    "fieldset'": fieldset$prime,
    "_fieldset'": _fieldset$prime,
    figcaption: figcaption,
    figcaption_: figcaption_,
    "figcaption'": figcaption$prime,
    "_figcaption'": _figcaption$prime,
    figure: figure,
    figure_: figure_,
    "figure'": figure$prime,
    "_figure'": _figure$prime,
    footer: footer,
    footer_: footer_,
    "footer'": footer$prime,
    "_footer'": _footer$prime,
    form: form,
    form_: form_,
    "form'": form$prime,
    "_form'": _form$prime,
    h1: h1,
    h1_: h1_,
    "h1'": h1$prime,
    "_h1'": _h1$prime,
    h2: h2,
    h2_: h2_,
    "h2'": h2$prime,
    "_h2'": _h2$prime,
    h3: h3,
    h3_: h3_,
    "h3'": h3$prime,
    "_h3'": _h3$prime,
    h4: h4,
    h4_: h4_,
    "h4'": h4$prime,
    "_h4'": _h4$prime,
    h5: h5,
    h5_: h5_,
    "h5'": h5$prime,
    "_h5'": _h5$prime,
    h6: h6,
    h6_: h6_,
    "h6'": h6$prime,
    "_h6'": _h6$prime,
    head: head,
    head_: head_,
    "head'": head$prime,
    "_head'": _head$prime,
    header: header,
    header_: header_,
    "header'": header$prime,
    "_header'": _header$prime,
    hgroup: hgroup,
    hgroup_: hgroup_,
    "hgroup'": hgroup$prime,
    "_hgroup'": _hgroup$prime,
    hr: hr,
    "hr'": hr$prime,
    "_hr'": _hr$prime,
    html: html,
    html_: html_,
    "html'": html$prime,
    "_html'": _html$prime,
    i: i,
    i_: i_,
    "i'": i$prime,
    "_i'": _i$prime,
    iframe: iframe,
    iframe_: iframe_,
    "iframe'": iframe$prime,
    "_iframe'": _iframe$prime,
    img: img,
    "img'": img$prime,
    "_img'": _img$prime,
    input: input,
    "input'": input$prime,
    "_input'": _input$prime,
    ins: ins,
    ins_: ins_,
    "ins'": ins$prime,
    "_ins'": _ins$prime,
    kbd: kbd,
    kbd_: kbd_,
    "kbd'": kbd$prime,
    "_kbd'": _kbd$prime,
    keygen: keygen,
    keygen_: keygen_,
    "keygen'": keygen$prime,
    "_keygen'": _keygen$prime,
    label: label,
    label_: label_,
    "label'": label$prime,
    "_label'": _label$prime,
    legend: legend,
    legend_: legend_,
    "legend'": legend$prime,
    "_legend'": _legend$prime,
    li: li,
    li_: li_,
    "li'": li$prime,
    "_li'": _li$prime,
    link: link,
    "link'": link$prime,
    "_link'": _link$prime,
    main: main,
    main_: main_,
    "main'": main$prime,
    "_main'": _main$prime,
    map: map,
    map_: map_,
    "map'": map$prime,
    "_map'": _map$prime,
    mark: mark,
    mark_: mark_,
    "mark'": mark$prime,
    "_mark'": _mark$prime,
    math: math,
    math_: math_,
    "math'": math$prime,
    "_math'": _math$prime,
    menu: menu,
    menu_: menu_,
    "menu'": menu$prime,
    "_menu'": _menu$prime,
    menuitem: menuitem,
    menuitem_: menuitem_,
    "menuitem'": menuitem$prime,
    "_menuitem'": _menuitem$prime,
    meta: meta,
    "meta'": meta$prime,
    "_meta'": _meta$prime,
    meter: meter,
    meter_: meter_,
    "meter'": meter$prime,
    "_meter'": _meter$prime,
    nav: nav,
    nav_: nav_,
    "nav'": nav$prime,
    "_nav'": _nav$prime,
    noscript: noscript,
    noscript_: noscript_,
    "noscript'": noscript$prime,
    "_noscript'": _noscript$prime,
    object: object,
    object_: object_,
    "object'": object$prime,
    "_object'": _object$prime,
    ol: ol,
    ol_: ol_,
    "ol'": ol$prime,
    "_ol'": _ol$prime,
    optgroup: optgroup,
    optgroup_: optgroup_,
    "optgroup'": optgroup$prime,
    "_optgroup'": _optgroup$prime,
    option: option,
    option_: option_,
    "option'": option$prime,
    "_option'": _option$prime,
    output: output,
    output_: output_,
    "output'": output$prime,
    "_output'": _output$prime,
    p: p,
    p_: p_,
    "p'": p$prime,
    "_p'": _p$prime,
    param: param,
    "param'": param$prime,
    "_param'": _param$prime,
    picture: picture,
    picture_: picture_,
    "picture'": picture$prime,
    "_picture'": _picture$prime,
    pre: pre,
    pre_: pre_,
    "pre'": pre$prime,
    "_pre'": _pre$prime,
    progress: progress,
    progress_: progress_,
    "progress'": progress$prime,
    "_progress'": _progress$prime,
    q: q,
    q_: q_,
    "q'": q$prime,
    "_q'": _q$prime,
    rb: rb,
    rb_: rb_,
    "rb'": rb$prime,
    "_rb'": _rb$prime,
    rp: rp,
    rp_: rp_,
    "rp'": rp$prime,
    "_rp'": _rp$prime,
    rt: rt,
    rt_: rt_,
    "rt'": rt$prime,
    "_rt'": _rt$prime,
    rtc: rtc,
    rtc_: rtc_,
    "rtc'": rtc$prime,
    "_rtc'": _rtc$prime,
    ruby: ruby,
    ruby_: ruby_,
    "ruby'": ruby$prime,
    "_ruby'": _ruby$prime,
    s: s,
    s_: s_,
    "s'": s$prime,
    "_s'": _s$prime,
    samp: samp,
    samp_: samp_,
    "samp'": samp$prime,
    "_samp'": _samp$prime,
    script: script,
    script_: script_,
    "script'": script$prime,
    "_script'": _script$prime,
    section: section,
    section_: section_,
    "section'": section$prime,
    "_section'": _section$prime,
    select: select,
    select_: select_,
    "select'": select$prime,
    "_select'": _select$prime,
    slot: slot,
    slot_: slot_,
    "slot'": slot$prime,
    "_slot'": _slot$prime,
    small: small,
    small_: small_,
    "small'": small$prime,
    "_small'": _small$prime,
    source: source,
    "source'": source$prime,
    "_source'": _source$prime,
    span: span,
    span_: span_,
    "span'": span$prime,
    "_span'": _span$prime,
    strong: strong,
    strong_: strong_,
    "strong'": strong$prime,
    "_strong'": _strong$prime,
    style: style,
    style_: style_,
    "style'": style$prime,
    "_style'": _style$prime,
    sub: sub,
    sub_: sub_,
    "sub'": sub$prime,
    "_sub'": _sub$prime,
    summary: summary,
    summary_: summary_,
    "summary'": summary$prime,
    "_summary'": _summary$prime,
    sup: sup,
    sup_: sup_,
    "sup'": sup$prime,
    "_sup'": _sup$prime,
    table: table,
    table_: table_,
    "table'": table$prime,
    "_table'": _table$prime,
    tbody: tbody,
    tbody_: tbody_,
    "tbody'": tbody$prime,
    "_tbody'": _tbody$prime,
    td: td,
    td_: td_,
    "td'": td$prime,
    "_td'": _td$prime,
    template: template,
    template_: template_,
    "template'": template$prime,
    "_template'": _template$prime,
    textarea: textarea,
    textarea_: textarea_,
    "textarea'": textarea$prime,
    "_textarea'": _textarea$prime,
    tfoot: tfoot,
    tfoot_: tfoot_,
    "tfoot'": tfoot$prime,
    "_tfoot'": _tfoot$prime,
    th: th,
    th_: th_,
    "th'": th$prime,
    "_th'": _th$prime,
    thead: thead,
    thead_: thead_,
    "thead'": thead$prime,
    "_thead'": _thead$prime,
    time: time,
    time_: time_,
    "time'": time$prime,
    "_time'": _time$prime,
    title: title,
    title_: title_,
    "title'": title$prime,
    "_title'": _title$prime,
    tr: tr,
    tr_: tr_,
    "tr'": tr$prime,
    "_tr'": _tr$prime,
    track: track,
    "track'": track$prime,
    "_track'": _track$prime,
    u: u,
    u_: u_,
    "u'": u$prime,
    "_u'": _u$prime,
    ul: ul,
    ul_: ul_,
    "ul'": ul$prime,
    "_ul'": _ul$prime,
    "var": $$var,
    var_: var_,
    "var'": var$prime,
    "_var'": _var$prime,
    video: video,
    video_: video_,
    "video'": video$prime,
    "_video'": _video$prime,
    wbr: wbr,
    "wbr'": wbr$prime,
    "_wbr'": _wbr$prime
};
