// Generated by purs version 0.14.4
"use strict";
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Tag = function (x) {
    return x;
};
var newtypeTag = {
    Coercible0: function () {
        return undefined;
    }
};
var eqTag = Data_Eq.eqInt;
var _VAR = 1;
var _TAK = 3;
var _NAT = 2;
var _LAM = 4;
var _DEF = 5;
var _APV = 6;
var showTag = {
    show: function (tag) {
        if (Data_Eq.eq(eqTag)(tag)(_VAR)) {
            return "_VAR";
        };
        if (Data_Eq.eq(eqTag)(tag)(_NAT)) {
            return "_NAT";
        };
        if (Data_Eq.eq(eqTag)(tag)(_TAK)) {
            return "_TAK";
        };
        if (Data_Eq.eq(eqTag)(tag)(_LAM)) {
            return "_LAM";
        };
        if (Data_Eq.eq(eqTag)(tag)(_DEF)) {
            return "_DEF";
        };
        if (Data_Eq.eq(eqTag)(tag)(_APV)) {
            return "_APV";
        };
        if (Data_Eq.eq(eqTag)(tag)(_APV)) {
            return "_AP0";
        };
        if (Data_Boolean.otherwise) {
            return "?" + Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Tag)(tag));
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot.Tag (line 22, column 1 - line 31, column 43): " + [ tag.constructor.name ]);
    }
};
var prettyTag = {
    pretty: function (v) {
        var $2 = Data_Show.show(showTag);
        return function ($3) {
            return Lambda_Language_Pretty.text($2($3));
        };
    }
};
var _AP0 = 7;
module.exports = {
    Tag: Tag,
    "_VAR": _VAR,
    "_NAT": _NAT,
    "_TAK": _TAK,
    "_LAM": _LAM,
    "_DEF": _DEF,
    "_APV": _APV,
    "_AP0": _AP0,
    newtypeTag: newtypeTag,
    eqTag: eqTag,
    showTag: showTag,
    prettyTag: prettyTag
};
