// Generated by purs version 0.14.4
"use strict";
var Control_Alternative = require("../Control.Alternative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var parse = (function () {
    var set = function (cs) {
        return {
            sharing: Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqChar)("s")(cs),
            loading: Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqChar)("l")(cs)
        };
    };
    return function ($4) {
        return Data_Maybe.Just.create(set(Data_String_CodeUnits.toCharArray($4)));
    };
})();
var param = function (v) {
    return Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(Data_Array.catMaybes([ Data_Functor.voidRight(Data_Maybe.functorMaybe)("&f=")(Control_Alternative.guard(Data_Maybe.alternativeMaybe)(v.sharing || v.loading)), Data_Functor.voidRight(Data_Maybe.functorMaybe)("s")(Control_Alternative.guard(Data_Maybe.alternativeMaybe)(v.sharing)), Data_Functor.voidRight(Data_Maybe.functorMaybe)("l")(Control_Alternative.guard(Data_Maybe.alternativeMaybe)(v.loading)) ]));
};
var none = {
    sharing: false,
    loading: false
};
module.exports = {
    none: none,
    parse: parse,
    param: param
};
