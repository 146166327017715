// Generated by purs version 0.14.4
"use strict";
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Position = function (x) {
    return x;
};
var updatePosString = function (pos$prime) {
    return function (str) {
        var updatePosChar = function (v) {
            return function (c) {
                if (c === "\x0a") {
                    return {
                        line: v.line + 1 | 0,
                        column: 1
                    };
                };
                if (c === "\x0d") {
                    return {
                        line: v.line + 1 | 0,
                        column: 1
                    };
                };
                if (c === "\x09") {
                    return {
                        line: v.line,
                        column: (v.column + 8 | 0) - Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v.column - 1 | 0)(8) | 0
                    };
                };
                return {
                    line: v.line,
                    column: v.column + 1 | 0
                };
            };
        };
        return Data_Foldable.foldl(Data_Foldable.foldableArray)(updatePosChar)(pos$prime)(Data_String_Common.split(Data_Newtype.wrap()(""))(str));
    };
};
var showPosition = {
    show: function (v) {
        return "(Position { line: " + (Data_Show.show(Data_Show.showInt)(v.line) + (", column: " + (Data_Show.show(Data_Show.showInt)(v.column) + " })")));
    }
};
var initialPos = {
    line: 1,
    column: 1
};
var genericPosition = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var eqPosition = {
    eq: function (x) {
        return function (y) {
            return x.column === y.column && x.line === y.line;
        };
    }
};
var ordPosition = {
    compare: function (x) {
        return function (y) {
            var v = Data_Ord.compare(Data_Ord.ordInt)(x.column)(y.column);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordInt)(x.line)(y.line);
        };
    },
    Eq0: function () {
        return eqPosition;
    }
};
module.exports = {
    Position: Position,
    initialPos: initialPos,
    updatePosString: updatePosString,
    genericPosition: genericPosition,
    showPosition: showPosition,
    eqPosition: eqPosition,
    ordPosition: ordPosition
};
