// Generated by purs version 0.14.4
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_Reader = require("../Control.Monad.Reader/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Monad_State = require("../Control.Monad.State/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Control_MonadZero = require("../Control.MonadZero/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode = require("../Data.Argonaut.Decode/index.js");
var Data_Argonaut_Encode = require("../Data.Argonaut.Encode/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_HashMap = require("../Data.HashMap/index.js");
var Data_HashSet = require("../Data.HashSet/index.js");
var Data_Hashable = require("../Data.Hashable/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List = require("../Data.List/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Tuple_Nested = require("../Data.Tuple.Nested/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Debug = require("../Debug/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Prelude = require("../Prelude/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var withState = Data_Function.flip(Control_Monad_State.evalState);
var withReader = Data_Function.flip(Control_Monad_Reader.runReader);
var runIdentity = Data_Newtype.unwrap();
var map2 = function (dictFunctor) {
    return function (dictFunctor1) {
        return function (f) {
            return Data_Functor.map(dictFunctor)(Data_Functor.map(dictFunctor1)(f));
        };
    };
};
module.exports = {
    withReader: withReader,
    withState: withState,
    runIdentity: runIdentity,
    map2: map2,
    catchError: Control_Monad_Error_Class.catchError,
    throwError: Control_Monad_Error_Class.throwError,
    runExceptT: Control_Monad_Except_Trans.runExceptT,
    runReader: Control_Monad_Reader.runReader,
    ask: Control_Monad_Reader_Class.ask,
    asks: Control_Monad_Reader_Class.asks,
    local: Control_Monad_Reader_Class.local,
    runReaderT: Control_Monad_Reader_Trans.runReaderT,
    Done: Control_Monad_Rec_Class.Done,
    Loop: Control_Monad_Rec_Class.Loop,
    tailRecM: Control_Monad_Rec_Class.tailRecM,
    evalState: Control_Monad_State.evalState,
    execState: Control_Monad_State.execState,
    runState: Control_Monad_State.runState,
    get: Control_Monad_State_Class.get,
    gets: Control_Monad_State_Class.gets,
    modify_: Control_Monad_State_Class.modify_,
    put: Control_Monad_State_Class.put,
    evalStateT: Control_Monad_State_Trans.evalStateT,
    execStateT: Control_Monad_State_Trans.execStateT,
    runStateT: Control_Monad_State_Trans.runStateT,
    guard: Control_MonadZero.guard,
    stringify: Data_Argonaut_Core.stringify,
    AtIndex: Data_Argonaut_Decode.AtIndex,
    AtKey: Data_Argonaut_Decode.AtKey,
    MissingValue: Data_Argonaut_Decode.MissingValue,
    Named: Data_Argonaut_Decode.Named,
    TypeMismatch: Data_Argonaut_Decode.TypeMismatch,
    UnexpectedValue: Data_Argonaut_Decode.UnexpectedValue,
    decodeJson: Data_Argonaut_Decode.decodeJson,
    printJsonDecodeError: Data_Argonaut_Decode.printJsonDecodeError,
    encodeJson: Data_Argonaut_Encode.encodeJson,
    bimap: Data_Bifunctor.bimap,
    lmap: Data_Bifunctor.lmap,
    rmap: Data_Bifunctor.rmap,
    Left: Data_Either.Left,
    Right: Data_Either.Right,
    either: Data_Either.either,
    hush: Data_Either.hush,
    note: Data_Either.note,
    fold: Data_Foldable.fold,
    foldM: Data_Foldable.foldM,
    foldMap: Data_Foldable.foldMap,
    foldl: Data_Foldable.foldl,
    foldr: Data_Foldable.foldr,
    for_: Data_Foldable.for_,
    sequence_: Data_Foldable.sequence_,
    traverse_: Data_Foldable.traverse_,
    hash: Data_Hashable.hash,
    Identity: Data_Identity.Identity,
    Cons: Data_List.Cons,
    Nil: Data_List.Nil,
    Just: Data_Maybe.Just,
    Nothing: Data_Maybe.Nothing,
    fromMaybe: Data_Maybe.fromMaybe,
    "fromMaybe'": Data_Maybe["fromMaybe'"],
    isJust: Data_Maybe.isJust,
    isNothing: Data_Maybe.isNothing,
    maybe: Data_Maybe.maybe,
    "maybe'": Data_Maybe["maybe'"],
    un: Data_Newtype.un,
    unwrap: Data_Newtype.unwrap,
    wrap: Data_Newtype.wrap,
    genericShow: Data_Show_Generic.genericShow,
    "for": Data_Traversable["for"],
    traverse: Data_Traversable.traverse,
    Tuple: Data_Tuple.Tuple,
    fst: Data_Tuple.fst,
    snd: Data_Tuple.snd,
    uncurry: Data_Tuple.uncurry,
    replicate: Data_Unfoldable.replicate,
    replicateA: Data_Unfoldable.replicateA,
    "debugger": Debug["debugger"],
    spy: Debug.spy,
    trace: Debug.trace,
    traceM: Debug.traceM,
    liftEffect: Effect_Class.liftEffect,
    EQ: Prelude.EQ,
    GT: Prelude.GT,
    LT: Prelude.LT,
    absurd: Prelude.absurd,
    add: Prelude.add,
    ap: Prelude.ap,
    append: Prelude.append,
    apply: Prelude.apply,
    between: Prelude.between,
    bind: Prelude.bind,
    bottom: Prelude.bottom,
    clamp: Prelude.clamp,
    compare: Prelude.compare,
    comparing: Prelude.comparing,
    compose: Prelude.compose,
    conj: Prelude.conj,
    "const": Prelude["const"],
    degree: Prelude.degree,
    discard: Prelude.discard,
    disj: Prelude.disj,
    div: Prelude.div,
    eq: Prelude.eq,
    flap: Prelude.flap,
    flip: Prelude.flip,
    gcd: Prelude.gcd,
    identity: Prelude.identity,
    ifM: Prelude.ifM,
    join: Prelude.join,
    lcm: Prelude.lcm,
    liftA1: Prelude.liftA1,
    liftM1: Prelude.liftM1,
    map: Prelude.map,
    max: Prelude.max,
    mempty: Prelude.mempty,
    min: Prelude.min,
    mod: Prelude.mod,
    mul: Prelude.mul,
    negate: Prelude.negate,
    not: Prelude.not,
    notEq: Prelude.notEq,
    one: Prelude.one,
    otherwise: Prelude.otherwise,
    pure: Prelude.pure,
    recip: Prelude.recip,
    show: Prelude.show,
    sub: Prelude.sub,
    top: Prelude.top,
    unit: Prelude.unit,
    unless: Prelude.unless,
    unlessM: Prelude.unlessM,
    "void": Prelude["void"],
    when: Prelude.when,
    whenM: Prelude.whenM,
    zero: Prelude.zero,
    coerce: Safe_Coerce.coerce
};
