// Generated by purs version 0.14.4
"use strict";
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var identity = function (dict) {
    return dict.identity;
};
var categoryFn = {
    identity: function (x) {
        return x;
    },
    Semigroupoid0: function () {
        return Control_Semigroupoid.semigroupoidFn;
    }
};
module.exports = {
    identity: identity,
    categoryFn: categoryFn,
    compose: Control_Semigroupoid.compose
};
