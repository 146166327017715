// Generated by purs version 0.14.4
"use strict";
var Components_App_Action = require("../Components.App.Action/index.js");
var Components_App_Alert = require("../Components.App.Alert/index.js");
var Components_App_Request = require("../Components.App.Request/index.js");
var Components_App_Response = require("../Components.App.Response/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Save = require("../Effect.Save/index.js");
var Lambda_Api = require("../Lambda.Api/index.js");
var Lambda_Language_Definition = require("../Lambda.Language.Definition/index.js");
var Lambda_Language_Expression = require("../Lambda.Language.Expression/index.js");
var Lambda_Language_History = require("../Lambda.Language.History/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Nameless = require("../Lambda.Language.Nameless/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Lambda_Language_Prelude = require("../Lambda.Language.Prelude/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Lambda_Language_Statement = require("../Lambda.Language.Statement/index.js");
var Lambda_Language_World = require("../Lambda.Language.World/index.js");
var Lambda_Machine = require("../Lambda.Machine/index.js");
var withCode = function (code) {
    return function (v) {
        return {
            text: v.text,
            defs: v.defs,
            expr: v.expr,
            world: v.world,
            machine: v.machine,
            history: v.history,
            steps: v.steps,
            alert: v.alert,
            request: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new Components_App_Request.Fetch(code)),
            flags: v.flags,
            rep: v.rep
        };
    };
};
var update = function (text) {
    return function (v) {
        return {
            text: text,
            defs: v.defs,
            expr: v.expr,
            world: v.world,
            machine: v.machine,
            history: v.history,
            steps: v.steps,
            alert: Data_Maybe.Nothing.value,
            request: v.request,
            flags: v.flags,
            rep: v.rep
        };
    };
};
var toggle = function (s) {
    return {
        text: s.text,
        defs: s.defs,
        expr: s.expr,
        world: s.world,
        machine: s.machine,
        history: s.history,
        steps: s.steps,
        alert: s.alert,
        request: s.request,
        flags: s.flags,
        rep: Lambda_Language_Pretty.toggle(s.rep)
    };
};
var step = function (s) {
    return Data_Maybe.fromMaybe(s)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Lambda_Machine.step)(s.machine))(function (machine) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
            machine: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(machine),
            steps: Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return 1 + v1 | 0;
            })(s.steps),
            history: Lambda_Language_History.add(Lambda_Language_Expression.prettyExpression)(Lambda_Machine.snapshot(machine))(s.history),
            alert: s.alert,
            defs: s.defs,
            expr: s.expr,
            flags: s.flags,
            rep: s.rep,
            request: s.request,
            text: s.text,
            world: s.world
        });
    }));
};
var status = function (v) {
    return {
        isHalted: Data_Maybe.maybe(true)(function (v1) {
            return v1.halted;
        })(v.machine),
        hasProgram: !(Data_Array["null"](v.defs) && Data_Maybe.isNothing(v.expr)),
        hasMachine: Data_Maybe.isJust(v.machine)
    };
};
var help = function (v) {
    return {
        text: v.text,
        defs: v.defs,
        expr: v.expr,
        world: v.world,
        machine: v.machine,
        history: v.history,
        steps: v.steps,
        alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert.Help.value),
        request: v.request,
        flags: v.flags,
        rep: v.rep
    };
};
var formatSession = function (v) {
    var layout = function (v1) {
        return function (v2) {
            if (v1.length === 0) {
                return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("\x0a")(v2);
            };
            if (v2.length === 0) {
                return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("\x0a")(v1);
            };
            return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)("\x0a")(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidArray)([ v1, Control_Applicative.pure(Control_Applicative.applicativeArray)(""), v2 ]));
        };
    };
    return layout(Data_Functor.map(Data_Functor.functorArray)((function () {
        var $102 = Lambda_Language_Pretty.pretty(Lambda_Language_Definition.prettyDefinition)(v.rep);
        return function ($103) {
            return Lambda_Language_Pretty.toString($102($103));
        };
    })())(v.defs))(Data_Array.fromFoldable(Data_List_Types.foldableList)(Data_List.reverse(Lambda_Language_History.toStrings(v.rep)(v.history))));
};
var extractProgram = function (v) {
    return {
        defs: v.defs,
        expr: v.expr
    };
};
var handle = function (dispatch) {
    return function (s) {
        var done = (function () {
            var $104 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
            return function ($105) {
                return $104(dispatch(Components_App_Action.Examine.create($105)));
            };
        })();
        return function (v) {
            if (v instanceof Components_App_Request.Fetch) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Lambda_Api.fetch(v.value0))(function (result) {
                    return done(Data_Either.either(Components_App_Response.ApiError.create)(Components_App_Response.Fetched.create)(result));
                });
            };
            if (v instanceof Components_App_Request.Store) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Lambda_Api.store(extractProgram(s)))(function (result) {
                    return done(Data_Either.either(Components_App_Response.ApiError.create)(Components_App_Response.Stored.create)(result));
                });
            };
            if (v instanceof Components_App_Request.Save) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Save.save({
                    text: formatSession(s),
                    to: "evaluation.txt"
                })))(function (result) {
                    return done(Data_Either.either(Components_App_Response.SaveError.create)(Data_Function["const"](Components_App_Response.Saved.value))(result));
                });
            };
            throw new Error("Failed pattern match at Components.App.State (line 80, column 21 - line 92, column 67): " + [ v.constructor.name ]);
        };
    };
};
var enqueue = function (request) {
    return function (s) {
        if (request instanceof Components_App_Request.Fetch && !s.flags.loading) {
            return s;
        };
        if (request instanceof Components_App_Request.Store && !s.flags.sharing) {
            return s;
        };
        return {
            text: s.text,
            defs: s.defs,
            expr: s.expr,
            world: s.world,
            machine: s.machine,
            history: s.history,
            steps: s.steps,
            alert: s.alert,
            request: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(request),
            flags: s.flags,
            rep: s.rep
        };
    };
};
var empty = function (flags) {
    return {
        text: "",
        expr: Data_Maybe.Nothing.value,
        defs: [  ],
        world: Lambda_Language_World.empty,
        machine: Data_Maybe.Nothing.value,
        history: Lambda_Language_History.empty,
        rep: Lambda_Language_Pretty.Sugar.value,
        alert: Data_Maybe.Nothing.value,
        steps: Data_Maybe.Nothing.value,
        request: Data_Maybe.Nothing.value,
        flags: flags
    };
};
var dismiss = function (v) {
    return {
        text: v.text,
        defs: v.defs,
        expr: v.expr,
        world: v.world,
        machine: v.machine,
        history: v.history,
        steps: v.steps,
        alert: Data_Maybe.Nothing.value,
        request: v.request,
        flags: v.flags,
        rep: v.rep
    };
};
var deleteByName = function (name) {
    return Data_Array.filter(function ($106) {
        return (function (v) {
            return Data_Eq.notEq(Lambda_Language_Name.eqName)(v)(name);
        })((function (v) {
            return v.name;
        })(Lambda_Language_Definition.split($106)));
    });
};
var $$delete = function (name) {
    return function (s) {
        var v = Lambda_Language_World.undefine(name)(s.world);
        if (v instanceof Data_Either.Left) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert["Error"].create(new Components_App_Alert.Inconsistent(v.value0))),
                request: s.request,
                flags: s.flags,
                rep: s.rep
            };
        };
        if (v instanceof Data_Either.Right) {
            return {
                text: s.text,
                defs: deleteByName(name)(s.defs),
                expr: s.expr,
                world: v.value0,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Data_Maybe.Nothing.value,
                request: s.request,
                flags: s.flags,
                rep: s.rep
            };
        };
        throw new Error("Failed pattern match at Components.App.State (line 177, column 17 - line 185, column 6): " + [ v.constructor.name ]);
    };
};
var defsToGlobals = Data_Functor.map(Data_Functor.functorArray)(function (def) {
    var v = Lambda_Language_Definition.split(def);
    return Data_Tuple.Tuple.create(v.name)(Lambda_Language_Nameless.from(v.expr));
});
var setExpr = function (syntax) {
    return function (s) {
        var globals = defsToGlobals(s.defs);
        var expr = Lambda_Language_Nameless.from(syntax);
        var v = Lambda_Language_World.focus(expr)(s.world);
        if (v instanceof Data_Either.Left) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert["Error"].create(new Components_App_Alert.Inconsistent(v.value0))),
                request: s.request,
                flags: s.flags,
                rep: s.rep
            };
        };
        if (v instanceof Data_Either.Right) {
            var machine = Lambda_Machine["new"](Data_Foldable.foldableArray)(globals)(expr);
            var snapshot = Lambda_Machine.snapshot(machine);
            return {
                text: "",
                defs: s.defs,
                expr: new Data_Maybe.Just(syntax),
                world: v.value0,
                machine: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(machine),
                history: Lambda_Language_History["new"](Lambda_Language_Expression.prettyExpression)(snapshot),
                steps: new Data_Maybe.Just(0),
                alert: Data_Maybe.Nothing.value,
                request: s.request,
                flags: s.flags,
                rep: s.rep
            };
        };
        throw new Error("Failed pattern match at Components.App.State (line 278, column 20 - line 294, column 8): " + [ v.constructor.name ]);
    };
};
var withPrelude = function (v) {
    return {
        text: v.text,
        defs: Lambda_Language_Prelude.defs,
        expr: v.expr,
        world: Lambda_Language_World["new"](defsToGlobals(Lambda_Language_Prelude.defs)),
        machine: v.machine,
        history: v.history,
        steps: v.steps,
        alert: v.alert,
        request: v.request,
        flags: v.flags,
        rep: v.rep
    };
};
var $$new = function (flags) {
    var state = empty(flags);
    return function (v) {
        if (v instanceof Data_Maybe.Just && flags.loading) {
            return withCode(v.value0)(state);
        };
        return withPrelude(state);
    };
};
var clear = function (s) {
    return {
        text: s.text,
        defs: s.defs,
        expr: Data_Maybe.Nothing.value,
        world: Lambda_Language_World.unfocus(s.world),
        machine: Data_Maybe.Nothing.value,
        history: Lambda_Language_History.empty,
        steps: Data_Maybe.Nothing.value,
        alert: s.alert,
        request: s.request,
        flags: s.flags,
        rep: s.rep
    };
};
var addDef = function (def) {
    return function (s) {
        var v = Lambda_Language_Definition.split(def);
        var nameless = Lambda_Language_Nameless.from(v.expr);
        var v1 = Lambda_Language_World.define(v.name)(nameless)(s.world);
        if (v1 instanceof Data_Either.Left) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert["Error"].create(new Components_App_Alert.Inconsistent(v1.value0))),
                request: s.request,
                flags: s.flags,
                rep: s.rep
            };
        };
        if (v1 instanceof Data_Either.Right) {
            return {
                text: "",
                defs: Data_Array.snoc(deleteByName(v.name)(s.defs))(def),
                expr: s.expr,
                world: v1.value0,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Data_Maybe.Nothing.value,
                request: s.request,
                flags: s.flags,
                rep: s.rep
            };
        };
        throw new Error("Failed pattern match at Components.App.State (line 261, column 16 - line 270, column 6): " + [ v1.constructor.name ]);
    };
};
var load = function (v) {
    return function (old) {
        var alertFail = function (state) {
            if (state.alert instanceof Data_Maybe.Just && state.alert.value0 instanceof Components_App_Alert["Error"]) {
                return new Data_Either.Left(state.alert.value0);
            };
            return new Data_Either.Right(state);
        };
        var define = function (state) {
            var $107 = Data_Function.flip(addDef)(state);
            return function ($108) {
                return alertFail($107($108));
            };
        };
        var focus = function (state) {
            var $109 = Data_Function.flip(setExpr)(state);
            return function ($110) {
                return alertFail($109($110));
            };
        };
        var result = Control_Bind.bind(Data_Either.bindEither)(Data_Foldable.foldM(Data_Foldable.foldableArray)(Data_Either.monadEither)(define)(empty(old.flags))(v.defs))(function (state) {
            return Data_Maybe.maybe(Control_Applicative.pure(Data_Either.applicativeEither)(state))(focus(state))(v.expr);
        });
        if (result instanceof Data_Either.Left) {
            return {
                text: old.text,
                defs: old.defs,
                expr: old.expr,
                world: old.world,
                machine: old.machine,
                history: old.history,
                steps: old.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(result.value0),
                request: Data_Maybe.Nothing.value,
                flags: old.flags,
                rep: old.rep
            };
        };
        if (result instanceof Data_Either.Right) {
            return result.value0;
        };
        throw new Error("Failed pattern match at Components.App.State (line 243, column 27 - line 245, column 23): " + [ result.constructor.name ]);
    };
};
var examine = function (response) {
    return function (s) {
        if (response instanceof Components_App_Response.Saved) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: s.alert,
                request: Data_Maybe.Nothing.value,
                flags: s.flags,
                rep: s.rep
            };
        };
        if (response instanceof Components_App_Response.Fetched) {
            return load(response.value0)(s);
        };
        if (response instanceof Components_App_Response.Stored) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new Components_App_Alert.Link(response.value0)),
                request: Data_Maybe.Nothing.value,
                flags: s.flags,
                rep: s.rep
            };
        };
        if (response instanceof Components_App_Response.ApiError) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert["Error"].create(new Components_App_Alert.ApiError(response.value0))),
                request: Data_Maybe.Nothing.value,
                flags: s.flags,
                rep: s.rep
            };
        };
        if (response instanceof Components_App_Response.SaveError) {
            return {
                text: s.text,
                defs: s.defs,
                expr: s.expr,
                world: s.world,
                machine: s.machine,
                history: s.history,
                steps: s.steps,
                alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert["Error"].create(new Components_App_Alert.SaveError(response.value0))),
                request: Data_Maybe.Nothing.value,
                flags: s.flags,
                rep: s.rep
            };
        };
        throw new Error("Failed pattern match at Components.App.State (line 223, column 22 - line 239, column 6): " + [ response.constructor.name ]);
    };
};
var parse = function (text) {
    return function (s) {
        if (text === "") {
            return s;
        };
        if (Data_Boolean.otherwise) {
            var v = Lambda_Language_Parser.run(Lambda_Language_Parser.parse(Lambda_Language_Statement.parseStatement))(text);
            if (v instanceof Data_Either.Left) {
                return {
                    text: s.text,
                    defs: s.defs,
                    expr: s.expr,
                    world: s.world,
                    machine: s.machine,
                    history: s.history,
                    steps: s.steps,
                    alert: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Components_App_Alert["Error"].create(new Components_App_Alert.ParseError(text, v.value0))),
                    request: s.request,
                    flags: s.flags,
                    rep: s.rep
                };
            };
            if (v instanceof Data_Either.Right && v.value0 instanceof Lambda_Language_Statement.Define) {
                return addDef(v.value0.value0)(s);
            };
            if (v instanceof Data_Either.Right && v.value0 instanceof Lambda_Language_Statement.Eval) {
                return setExpr(v.value0.value0)(s);
            };
            throw new Error("Failed pattern match at Components.App.State (line 166, column 17 - line 171, column 40): " + [ v.constructor.name ]);
        };
        throw new Error("Failed pattern match at Components.App.State (line 163, column 1 - line 163, column 34): " + [ text.constructor.name, s.constructor.name ]);
    };
};
var reduce = function (s) {
    return function (v) {
        if (v instanceof Components_App_Action.Help) {
            return help(s);
        };
        if (v instanceof Components_App_Action.Dismiss) {
            return dismiss(s);
        };
        if (v instanceof Components_App_Action.Update) {
            return update(v.value0)(s);
        };
        if (v instanceof Components_App_Action.Parse) {
            return parse(s.text)(s);
        };
        if (v instanceof Components_App_Action.Delete) {
            return $$delete(v.value0)(s);
        };
        if (v instanceof Components_App_Action.Step) {
            return step(s);
        };
        if (v instanceof Components_App_Action.Clear) {
            return clear(s);
        };
        if (v instanceof Components_App_Action.Toggle) {
            return toggle(s);
        };
        if (v instanceof Components_App_Action.Enqueue) {
            return enqueue(v.value0)(s);
        };
        if (v instanceof Components_App_Action.Examine) {
            return examine(v.value0)(dismiss(s));
        };
        throw new Error("Failed pattern match at Components.App.State (line 136, column 12 - line 146, column 58): " + [ v.constructor.name ]);
    };
};
module.exports = {
    "new": $$new,
    reduce: reduce,
    status: status,
    handle: handle
};
