// Generated by purs version 0.14.4
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var History = function (x) {
    return x;
};
var toListWith = function (f) {
    return function (rep) {
        var $10 = Data_Functor.map(Data_List_Types.functorList)(f);
        var $11 = Lambda_Language_Pretty.select(rep);
        var $12 = Safe_Coerce.coerce();
        return function ($13) {
            return $10($11($12($13)));
        };
    };
};
var toStrings = toListWith(function (v) {
    return v.text;
});
var toJSX = toListWith(function (v) {
    return v.jsx;
});
var empty = {
    sugar: Data_List_Types.Nil.value,
    raw: Data_List_Types.Nil.value
};
var bundle = function (dictPretty) {
    return function (rep) {
        return function (a) {
            var node = Lambda_Language_Pretty.pretty(dictPretty)(rep)(a);
            return {
                jsx: Lambda_Language_Pretty.toJSX(node),
                text: Lambda_Language_Pretty.toString(node)
            };
        };
    };
};
var add = function (dictPretty) {
    return function (a) {
        return function (v) {
            return {
                sugar: new Data_List_Types.Cons(bundle(dictPretty)(Lambda_Language_Pretty.Sugar.value)(a), v.sugar),
                raw: new Data_List_Types.Cons(bundle(dictPretty)(Lambda_Language_Pretty.Raw.value)(a), v.raw)
            };
        };
    };
};
var $$new = function (dictPretty) {
    return function (a) {
        return add(dictPretty)(a)(empty);
    };
};
module.exports = {
    empty: empty,
    "new": $$new,
    add: add,
    toStrings: toStrings,
    toJSX: toJSX
};
