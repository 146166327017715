// Generated by purs version 0.14.4
"use strict";
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Show = require("../Data.Show/index.js");
var Lambda_Language_Prelude = require("../Lambda.Language.Prelude/index.js");
var Signature = function (x) {
    return x;
};
var showSignature = Data_Show.showInt;
var nil = 0;
var inflate = function (v) {
    var used = function (v1) {
        return (v1.id & v) !== 0;
    };
    return Data_Array.filter(used)(Lambda_Language_Prelude.defs);
};
var eqSignature = Data_Eq.eqInt;
var encodeJsonSignature = Data_Argonaut_Encode_Class.encodeJsonInt;
var deflate = (function () {
    var $8 = Data_Function.flip(Data_Foldable.foldl(Data_Foldable.foldableArray))(0)(function (acc) {
        return function (v) {
            return acc | v.id;
        };
    });
    return function ($9) {
        return Signature($8($9));
    };
})();
var decodeJsonSignature = Data_Argonaut_Decode_Class.decodeJsonInt;
module.exports = {
    deflate: deflate,
    inflate: inflate,
    nil: nil,
    eqSignature: eqSignature,
    showSignature: showSignature,
    encodeJsonSignature: encodeJsonSignature,
    decodeJsonSignature: decodeJsonSignature
};
