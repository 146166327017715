// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Lazy = require("../Control.Lazy/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Text_Parsing_Parser_Pos = require("../Text.Parsing.Parser.Pos/index.js");
var ParseState = (function () {
    function ParseState(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    ParseState.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new ParseState(value0, value1, value2);
            };
        };
    };
    return ParseState;
})();
var ParseError = (function () {
    function ParseError(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ParseError.create = function (value0) {
        return function (value1) {
            return new ParseError(value0, value1);
        };
    };
    return ParseError;
})();
var ParserT = function (x) {
    return x;
};
var showParseError = {
    show: function (v) {
        return "(ParseError " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Text_Parsing_Parser_Pos.showPosition)(v.value1) + ")")));
    }
};
var parseErrorPosition = function (v) {
    return v.value1;
};
var parseErrorMessage = function (v) {
    return v.value0;
};
var newtypeParserT = {
    Coercible0: function () {
        return undefined;
    }
};
var runParserT = function (dictMonad) {
    return function (s) {
        return function (p) {
            var initialState = new ParseState(s, Text_Parsing_Parser_Pos.initialPos, false);
            return Control_Monad_State_Trans.evalStateT(((dictMonad.Bind1()).Apply0()).Functor0())(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap()(p)))(initialState);
        };
    };
};
var runParser = function (s) {
    var $88 = Data_Newtype.unwrap();
    var $89 = runParserT(Data_Identity.monadIdentity)(s);
    return function ($90) {
        return $88($89($90));
    };
};
var monadTransParserT = {
    lift: function (dictMonad) {
        var $91 = Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Control_Monad_State_Trans.monadStateT(dictMonad));
        var $92 = Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT)(dictMonad);
        return function ($93) {
            return ParserT($91($92($93)));
        };
    }
};
var monadThrowParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.monadThrowExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};
var monadStateParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.monadStateExceptT(Control_Monad_State_Trans.monadStateStateT(dictMonad));
};
var position = function (dictMonad) {
    return Control_Monad_State_Class.gets(monadStateParserT(dictMonad))(function (v) {
        return v.value1;
    });
};
var monadRecParserT = function (dictMonadRec) {
    return Control_Monad_Except_Trans.monadRecExceptT(Control_Monad_State_Trans.monadRecStateT(dictMonadRec));
};
var monadParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.monadExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};
var monadErrorParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.monadErrorExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};
var region = function (dictMonad) {
    return function (context) {
        return function (p) {
            return Control_Monad_Error_Class.catchError(monadErrorParserT(dictMonad))(p)(function (err) {
                return Control_Monad_Error_Class.throwError(monadThrowParserT(dictMonad))(context(err));
            });
        };
    };
};
var mapParserT = (function () {
    var $94 = Data_Newtype.over()()(ParserT);
    return function ($95) {
        return $94(Control_Monad_Except_Trans.mapExceptT(Control_Monad_State_Trans.mapStateT($95)));
    };
})();
var lazyParserT = {
    defer: function (f) {
        return Control_Lazy.defer(Control_Monad_State_Trans.lazyStateT)((function () {
            var $96 = Data_Newtype.unwrap();
            return function ($97) {
                return Control_Monad_Except_Trans.runExceptT($96(f($97)));
            };
        })());
    }
};
var hoistParserT = mapParserT;
var functorParserT = function (dictFunctor) {
    return Control_Monad_Except_Trans.functorExceptT(Control_Monad_State_Trans.functorStateT(dictFunctor));
};
var failWithPosition = function (dictMonad) {
    return function (message) {
        return function (pos) {
            return Control_Monad_Error_Class.throwError(monadThrowParserT(dictMonad))(new ParseError(message, pos));
        };
    };
};
var eqParseError = {
    eq: function (x) {
        return function (y) {
            return x.value0 === y.value0 && Data_Eq.eq(Text_Parsing_Parser_Pos.eqPosition)(x.value1)(y.value1);
        };
    }
};
var ordParseError = {
    compare: function (x) {
        return function (y) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Text_Parsing_Parser_Pos.ordPosition)(x.value1)(y.value1);
        };
    },
    Eq0: function () {
        return eqParseError;
    }
};
var consume = function (dictMonad) {
    return Control_Monad_State_Class.modify_(monadStateParserT(dictMonad))(function (v) {
        return new ParseState(v.value0, v.value1, true);
    });
};
var bindParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.bindExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};
var fail = function (dictMonad) {
    return function (message) {
        return Control_Bind.bindFlipped(bindParserT(dictMonad))(failWithPosition(dictMonad)(message))(position(dictMonad));
    };
};
var applyParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.applyExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};
var semigroupParserT = function (dictMonad) {
    return function (dictSemigroup) {
        return {
            append: Control_Apply.lift2(applyParserT(dictMonad))(Data_Semigroup.append(dictSemigroup))
        };
    };
};
var applicativeParserT = function (dictMonad) {
    return Control_Monad_Except_Trans.applicativeExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};
var monoidParserT = function (dictMonad) {
    return function (dictMonoid) {
        return {
            mempty: Control_Applicative.pure(applicativeParserT(dictMonad))(Data_Monoid.mempty(dictMonoid)),
            Semigroup0: function () {
                return semigroupParserT(dictMonad)(dictMonoid.Semigroup0());
            }
        };
    };
};
var altParserT = function (dictMonad) {
    return {
        alt: function (p1) {
            return function (p2) {
                return ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
                    return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap()(p1)))(new ParseState(v.value0, v.value1, false)))(function (v1) {
                        if (v1.value0 instanceof Data_Either.Left && !v1.value1.value2) {
                            return Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap()(p2)))(v);
                        };
                        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
                    });
                })));
            };
        },
        Functor0: function () {
            return functorParserT(((dictMonad.Bind1()).Apply0()).Functor0());
        }
    };
};
var plusParserT = function (dictMonad) {
    return {
        empty: fail(dictMonad)("No alternative"),
        Alt0: function () {
            return altParserT(dictMonad);
        }
    };
};
var alternativeParserT = function (dictMonad) {
    return {
        Applicative0: function () {
            return applicativeParserT(dictMonad);
        },
        Plus1: function () {
            return plusParserT(dictMonad);
        }
    };
};
var monadPlusParserT = function (dictMonad) {
    return {
        Monad0: function () {
            return monadParserT(dictMonad);
        },
        Alternative1: function () {
            return alternativeParserT(dictMonad);
        }
    };
};
var monadZeroParserT = function (dictMonad) {
    return {
        Monad0: function () {
            return monadParserT(dictMonad);
        },
        Alternative1: function () {
            return alternativeParserT(dictMonad);
        },
        MonadZeroIsDeprecated2: function () {
            return undefined;
        }
    };
};
module.exports = {
    ParseError: ParseError,
    parseErrorMessage: parseErrorMessage,
    parseErrorPosition: parseErrorPosition,
    ParseState: ParseState,
    ParserT: ParserT,
    runParser: runParser,
    runParserT: runParserT,
    hoistParserT: hoistParserT,
    mapParserT: mapParserT,
    consume: consume,
    position: position,
    fail: fail,
    failWithPosition: failWithPosition,
    region: region,
    showParseError: showParseError,
    eqParseError: eqParseError,
    ordParseError: ordParseError,
    newtypeParserT: newtypeParserT,
    lazyParserT: lazyParserT,
    semigroupParserT: semigroupParserT,
    monoidParserT: monoidParserT,
    functorParserT: functorParserT,
    applyParserT: applyParserT,
    applicativeParserT: applicativeParserT,
    bindParserT: bindParserT,
    monadParserT: monadParserT,
    monadRecParserT: monadRecParserT,
    monadStateParserT: monadStateParserT,
    monadThrowParserT: monadThrowParserT,
    monadErrorParserT: monadErrorParserT,
    altParserT: altParserT,
    plusParserT: plusParserT,
    alternativeParserT: alternativeParserT,
    monadZeroParserT: monadZeroParserT,
    monadPlusParserT: monadPlusParserT,
    monadTransParserT: monadTransParserT
};
