// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var singleton = function (top) {
    return {
        top: top,
        rest: Data_List_Types.Nil.value
    };
};
var roots = function (v) {
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ v.top ])(Data_Array.fromFoldable(Data_List_Types.foldableList)(v.rest));
};
var replace = function (dictMonadState) {
    return function (address) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
            return v.stack;
        }))(function (v) {
            return Control_Monad_State_Class.modify_(dictMonadState)(function (v1) {
                var $21 = {};
                for (var $22 in v1) {
                    if ({}.hasOwnProperty.call(v1, $22)) {
                        $21[$22] = v1[$22];
                    };
                };
                $21.stack = {
                    top: address,
                    rest: v.rest
                };
                return $21;
            });
        });
    };
};
var push = function (dictMonadState) {
    return function (address) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
            return v.stack;
        }))(function (v) {
            return Control_Monad_State_Class.modify_(dictMonadState)(function (v1) {
                var $26 = {};
                for (var $27 in v1) {
                    if ({}.hasOwnProperty.call(v1, $27)) {
                        $26[$27] = v1[$27];
                    };
                };
                $26.stack = {
                    top: address,
                    rest: new Data_List_Types.Cons(v.top, v.rest)
                };
                return $26;
            });
        });
    };
};
var peek = function (dictMonadState) {
    return function (i) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
            return v.stack;
        }))(function (v) {
            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_List.index(new Data_List_Types.Cons(v.top, v.rest))(i));
        });
    };
};
var fromList = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just({
            top: v.value0,
            rest: v.value1
        });
    };
    throw new Error("Failed pattern match at Lambda.Machine.Stack (line 56, column 12 - line 58, column 36): " + [ v.constructor.name ]);
};
var discard = function (dictMonadState) {
    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
        return v.stack;
    }))(function (v) {
        if (v.rest instanceof Data_List_Types.Cons) {
            return Control_Monad_State_Class.modify_(dictMonadState)(function (v1) {
                var $39 = {};
                for (var $40 in v1) {
                    if ({}.hasOwnProperty.call(v1, $40)) {
                        $39[$40] = v1[$40];
                    };
                };
                $39.stack = {
                    top: v.rest.value0,
                    rest: v.rest.value1
                };
                return $39;
            });
        };
        if (v.rest instanceof Data_List_Types.Nil) {
            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at Lambda.Machine.Stack (line 38, column 3 - line 40, column 21): " + [ v.rest.constructor.name ]);
    });
};
module.exports = {
    singleton: singleton,
    push: push,
    discard: discard,
    replace: replace,
    peek: peek,
    fromList: fromList,
    roots: roots
};
