// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Monad_State = require("../Control.Monad.State/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HashSet = require("../Data.HashSet/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Lambda_Language_Expression = require("../Lambda.Language.Expression/index.js");
var Lambda_Language_Nameless = require("../Lambda.Language.Nameless/index.js");
var Lambda_Machine_Address = require("../Lambda.Machine.Address/index.js");
var Lambda_Machine_Globals = require("../Lambda.Machine.Globals/index.js");
var Lambda_Machine_Heap = require("../Lambda.Machine.Heap/index.js");
var Lambda_Machine_Node = require("../Lambda.Machine.Node/index.js");
var Lambda_Machine_Stack = require("../Lambda.Machine.Stack/index.js");
var Lambda_Machine_Stash = require("../Lambda.Machine.Stash/index.js");
var Lambda_Prelude = require("../Lambda.Prelude/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Fetch = (function () {
    function Fetch(value0) {
        this.value0 = value0;
    };
    Fetch.create = function (value0) {
        return new Fetch(value0);
    };
    return Fetch;
})();
var Redex = (function () {
    function Redex(value0) {
        this.value0 = value0;
    };
    Redex.create = function (value0) {
        return new Redex(value0);
    };
    return Redex;
})();
var syntax = function (env) {
    return function (v) {
        if (v instanceof Lambda_Language_Nameless.Lambda) {
            return Lambda_Language_Expression.Lambda.create(v.value0)(syntax(new Data_List_Types.Cons(new Lambda_Language_Expression.Var(v.value0), env))(v.value2));
        };
        if (v instanceof Lambda_Language_Nameless.Apply) {
            return new Lambda_Language_Expression.Apply(syntax(env)(v.value0), syntax(env)(v.value1));
        };
        if (v instanceof Lambda_Language_Nameless.Bound) {
            return Lambda_Machine_Node.deref(Lambda_Language_Expression.showExpression)(v.value0)(env);
        };
        if (v instanceof Lambda_Language_Nameless.Free) {
            return new Lambda_Language_Expression.Var(v.value0);
        };
        throw new Error("Failed pattern match at Lambda.Machine (line 322, column 14 - line 326, column 38): " + [ v.constructor.name ]);
    };
};
var setFocus = function (dictMonadState) {
    return function (focus) {
        return Control_Monad_State_Class.modify_(dictMonadState)(function (v) {
            var $42 = {};
            for (var $43 in v) {
                if ({}.hasOwnProperty.call(v, $43)) {
                    $42[$43] = v[$43];
                };
            };
            $42.focus = focus;
            $42.halted = Data_Maybe.isNothing(focus);
            return $42;
        });
    };
};
var restoreFocus = function (dictMonadState) {
    return function (body) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
            return v.focus;
        }))(function (focus) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(body)(function (r) {
                return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (v) {
                    var $45 = {};
                    for (var $46 in v) {
                        if ({}.hasOwnProperty.call(v, $46)) {
                            $45[$46] = v[$46];
                        };
                    };
                    $45.focus = focus;
                    return $45;
                }))(function () {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(r);
                });
            });
        });
    };
};
var highlight = function (v) {
    if (v instanceof Fetch) {
        return v.value0.global;
    };
    if (v instanceof Redex) {
        return v.value0.target;
    };
    throw new Error("Failed pattern match at Lambda.Machine (line 341, column 13 - line 343, column 29): " + [ v.constructor.name ]);
};
var getRoots = function (dictMonadState) {
    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
        return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidArray)([ [ v.root ], Lambda_Machine_Stack.roots(v.stack), Lambda_Machine_Stash.roots(v.stash) ]));
    });
};
var formatStuckWith = function (dictMonadState) {
    return function (seen) {
        return function (v) {
            if (v instanceof Lambda_Machine_Node.StuckVar) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(new Lambda_Language_Expression.Var(v.value0));
            };
            if (v instanceof Lambda_Machine_Node.StuckLambda) {
                return Data_Functor.map((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Lambda_Language_Expression.Lambda.create(v.value0))(formatNodeWith(dictMonadState)(seen)(v.value1));
            };
            if (v instanceof Lambda_Machine_Node.StuckApply) {
                return Control_Apply.apply(((dictMonadState.Monad0()).Bind1()).Apply0())(Data_Functor.map((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Lambda_Language_Expression.Apply.create)(formatNodeWith(dictMonadState)(seen)(v.value0)))(formatNodeWith(dictMonadState)(seen)(v.value1));
            };
            throw new Error("Failed pattern match at Lambda.Machine (line 335, column 24 - line 338, column 89): " + [ v.constructor.name ]);
        };
    };
};
var formatNodeWith = function (dictMonadState) {
    return function (seen0) {
        return function (address) {
            if (Data_HashSet.member(Lambda_Machine_Address.hashableAddress)(address)(seen0)) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Lambda_Language_Expression.Cycle.value);
            };
            if (Data_Boolean.otherwise) {
                var format = function (seen) {
                    return function (v) {
                        if (v instanceof Lambda_Machine_Node.Apply) {
                            return Control_Apply.apply(((dictMonadState.Monad0()).Bind1()).Apply0())(Data_Functor.map((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Lambda_Language_Expression.Apply.create)(formatNodeWith(dictMonadState)(seen)(v.value0)))(formatNodeWith(dictMonadState)(seen)(v.value1));
                        };
                        if (v instanceof Lambda_Machine_Node.Closure) {
                            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Data_Traversable.traverse(Data_List_Types.traversableList)((dictMonadState.Monad0()).Applicative0())(formatNodeWith(dictMonadState)(seen))(v.value0.env))(function (formatted) {
                                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Lambda_Language_Expression.Lambda.create(v.value0.name)(syntax(new Data_List_Types.Cons(new Lambda_Language_Expression.Var(v.value0.name), formatted))(v.value0.body)));
                            });
                        };
                        if (v instanceof Lambda_Machine_Node.Stuck) {
                            return formatStuckWith(dictMonadState)(seen)(v.value0);
                        };
                        if (v instanceof Lambda_Machine_Node.Pointer) {
                            return formatNodeWith(dictMonadState)(seen)(v.value0);
                        };
                        if (v instanceof Lambda_Machine_Node.Global) {
                            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(new Lambda_Language_Expression.Var(v.value0));
                        };
                        throw new Error("Failed pattern match at Lambda.Machine (line 308, column 17 - line 315, column 48): " + [ v.constructor.name ]);
                    };
                };
                var seen = Data_HashSet.insert(Lambda_Machine_Address.hashableAddress)(address)(seen0);
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Lambda_Machine_Heap.fetch(dictMonadState)(address))(function (node) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
                        return v.focus;
                    }))(function (focus) {
                        var $76 = Data_Eq.eq(Data_Maybe.eqMaybe(Lambda_Machine_Address.eqAddress))(Data_Functor.map(Data_Maybe.functorMaybe)(highlight)(focus))(new Data_Maybe.Just(address));
                        if ($76) {
                            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (v) {
                                var $77 = {};
                                for (var $78 in v) {
                                    if ({}.hasOwnProperty.call(v, $78)) {
                                        $77[$78] = v[$78];
                                    };
                                };
                                $77.focus = Data_Maybe.Nothing.value;
                                return $77;
                            }))(function () {
                                return Data_Functor.map((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Lambda_Language_Expression.Highlight.create)(format(seen)(node));
                            });
                        };
                        return format(seen)(node);
                    });
                });
            };
            throw new Error("Failed pattern match at Lambda.Machine (line 290, column 1 - line 295, column 18): " + [ seen0.constructor.name, address.constructor.name ]);
        };
    };
};
var formatRoot = function (dictMonadState) {
    var $117 = restoreFocus(dictMonadState);
    var $118 = formatNodeWith(dictMonadState)(Data_HashSet.empty);
    return function ($119) {
        return $117($118($119));
    };
};
var snapshot = Control_Monad_State.evalState(Control_Bind.bindFlipped(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(formatRoot(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(Control_Monad_State_Class.gets(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (v) {
    return v.root;
})));
var fetchArg = function (dictMonadState) {
    return function (address) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Lambda_Machine_Heap.fetch(dictMonadState)(address))(function (node) {
            if (node instanceof Lambda_Machine_Node.Apply) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(node.value1);
            };
            return Partial_Unsafe.unsafeCrashWith(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "Non-application below top of stack: ", Data_Show.show(Lambda_Machine_Node.showNode)(node) ]));
        });
    };
};
var unwind = function (dictMonadState) {
    return function (dictMonadRec) {
        var go = function (v) {
            return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v1) {
                return v1.stack;
            }))(function (v1) {
                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Heap.fetch(dictMonadState)(v1.top))(function (node) {
                    if (node instanceof Lambda_Machine_Node.Apply) {
                        return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.push(dictMonadState)(node.value0))(function () {
                            return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Data_Unit.unit));
                        });
                    };
                    if (node instanceof Lambda_Machine_Node.Pointer) {
                        if (Data_Eq.eq(Lambda_Machine_Address.eqAddress)(node.value0)(v1.top)) {
                            return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(Data_Maybe.Nothing.value));
                        };
                        if (Data_Boolean.otherwise) {
                            return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.peek(dictMonadState)(1))(function (parent) {
                                return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Data_Foldable.for_((dictMonadRec.Monad0()).Applicative0())(Data_Foldable.foldableMaybe)(parent)(function (app) {
                                    return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(fetchArg(dictMonadState)(app))(function (arg) {
                                        return Lambda_Machine_Heap.update(dictMonadState)(app)(new Lambda_Machine_Node.Apply(node.value0, arg));
                                    });
                                }))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.replace(dictMonadState)(node.value0))(function () {
                                        return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Data_Unit.unit));
                                    });
                                });
                            });
                        };
                    };
                    if (node instanceof Lambda_Machine_Node.Global) {
                        return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.peek(dictMonadState)(1))(function (v2) {
                            if (v2 instanceof Data_Maybe.Just) {
                                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(fetchArg(dictMonadState)(v2.value0))(function (arg) {
                                    return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(Control_Monad_Rec_Class.Done.create(Data_Maybe.Just.create(new Fetch({
                                        global: v1.top,
                                        target: v2.value0,
                                        address: node.value1,
                                        node: new Lambda_Machine_Node.Apply(node.value1, arg)
                                    }))));
                                });
                            };
                            if (v2 instanceof Data_Maybe.Nothing) {
                                return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(Control_Monad_Rec_Class.Done.create(Data_Maybe.Just.create(new Fetch({
                                    global: v1.top,
                                    target: v1.top,
                                    address: node.value1,
                                    node: new Lambda_Machine_Node.Pointer(node.value1)
                                }))));
                            };
                            throw new Error("Failed pattern match at Lambda.Machine (line 203, column 26 - line 218, column 16): " + [ v2.constructor.name ]);
                        });
                    };
                    if (node instanceof Lambda_Machine_Node.Closure) {
                        return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.peek(dictMonadState)(1))(function (v2) {
                            if (v2 instanceof Data_Maybe.Just) {
                                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(fetchArg(dictMonadState)(v2.value0))(function (arg) {
                                    return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(Control_Monad_Rec_Class.Done.create(Data_Maybe.Just.create(new Redex({
                                        target: v2.value0,
                                        env: new Data_List_Types.Cons(arg, node.value0.env),
                                        body: node.value0.body
                                    }))));
                                });
                            };
                            if (v2 instanceof Data_Maybe.Nothing) {
                                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Heap.alloc(dictMonadState)(Lambda_Machine_Node.Stuck.create(new Lambda_Machine_Node.StuckVar(node.value0.name))))(function (arg) {
                                    return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Node.instantiate(dictMonadState)(new Data_List_Types.Cons(arg, node.value0.env))(node.value0.body))(function (inst) {
                                        return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Heap.update(dictMonadState)(v1.top)(Lambda_Machine_Node.Stuck.create(new Lambda_Machine_Node.StuckLambda(node.value0.name, inst))))(function () {
                                            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.replace(dictMonadState)(inst))(function () {
                                                return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Data_Unit.unit));
                                            });
                                        });
                                    });
                                });
                            };
                            throw new Error("Failed pattern match at Lambda.Machine (line 228, column 26 - line 241, column 29): " + [ v2.constructor.name ]);
                        });
                    };
                    if (node instanceof Lambda_Machine_Node.Stuck) {
                        return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.peek(dictMonadState)(1))(function (v2) {
                            if (v2 instanceof Data_Maybe.Just) {
                                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(fetchArg(dictMonadState)(v2.value0))(function (arg) {
                                    return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Heap.update(dictMonadState)(v2.value0)(Lambda_Machine_Node.Stuck.create(new Lambda_Machine_Node.StuckApply(v1.top, arg))))(function () {
                                        return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.discard(dictMonadState))(function () {
                                            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stack.replace(dictMonadState)(arg))(function () {
                                                return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stash.suspend(dictMonadState))(function () {
                                                    return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Data_Unit.unit));
                                                });
                                            });
                                        });
                                    });
                                });
                            };
                            if (v2 instanceof Data_Maybe.Nothing) {
                                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Lambda_Machine_Stash.restore(dictMonadState))(function (v3) {
                                    if (v3 instanceof Data_Maybe.Just) {
                                        return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Data_Unit.unit));
                                    };
                                    if (v3 instanceof Data_Maybe.Nothing) {
                                        return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(Data_Maybe.Nothing.value));
                                    };
                                    throw new Error("Failed pattern match at Lambda.Machine (line 258, column 31 - line 260, column 45): " + [ v3.constructor.name ]);
                                });
                            };
                            throw new Error("Failed pattern match at Lambda.Machine (line 249, column 26 - line 260, column 45): " + [ v2.constructor.name ]);
                        });
                    };
                    throw new Error("Failed pattern match at Lambda.Machine (line 178, column 5 - line 260, column 45): " + [ node.constructor.name ]);
                });
            });
        };
        return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go)(Data_Unit.unit);
    };
};
var $$new = function (dictFoldable) {
    return function (rawGlobals) {
        return function (expr) {
            var empty = {
                heap: Lambda_Machine_Heap.empty,
                globals: Lambda_Machine_Globals.empty
            };
            var v = Lambda_Prelude.withState(empty)(Control_Bind.discard(Control_Bind.discardUnit)(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Foldable.traverse_(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(dictFoldable)(Data_Tuple.uncurry(Lambda_Machine_Node.define(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))))(rawGlobals))(function () {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Lambda_Machine_Node.compile(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(expr))(function (root) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Lambda_Machine_Heap.gc(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(Control_Monad_State_Trans.monadRecStateT(Control_Monad_Rec_Class.monadRecIdentity))(Data_Foldable.foldableArray)([ root ])(Lambda_Machine_Node.children))(function () {
                        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.gets(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (v1) {
                            return v1.heap;
                        }))(function (heap) {
                            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.gets(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (v1) {
                                return v1.globals;
                            }))(function (globals) {
                                return Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))({
                                    root: root,
                                    globals: globals,
                                    heap: heap
                                });
                            });
                        });
                    });
                });
            }));
            return Control_Monad_State.execState(Control_Bind.bindFlipped(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(setFocus(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(unwind(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(Control_Monad_State_Trans.monadRecStateT(Control_Monad_Rec_Class.monadRecIdentity))))({
                root: v.root,
                heap: v.heap,
                globals: v.globals,
                stack: Lambda_Machine_Stack.singleton(v.root),
                stash: Lambda_Machine_Stash.empty,
                focus: Data_Maybe.Nothing.value,
                halted: false
            });
        };
    };
};
var $$eval = function (dictMonadState) {
    return function (v) {
        if (v instanceof Fetch) {
            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Lambda_Machine_Heap.update(dictMonadState)(v.value0.target)(v.value0.node))(function () {
                return Lambda_Machine_Stack.replace(dictMonadState)(v.value0.address);
            });
        };
        if (v instanceof Redex) {
            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Lambda_Machine_Node.instantiateAt(dictMonadState)(v.value0.target)(v.value0.env)(v.value0.body))(function () {
                return Lambda_Machine_Stack.discard(dictMonadState);
            });
        };
        throw new Error("Failed pattern match at Lambda.Machine (line 163, column 8 - line 169, column 18): " + [ v.constructor.name ]);
    };
};
var step = Control_Monad_State.execState(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.gets(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (v) {
    return v.focus;
}))(function (mFocus) {
    return Data_Foldable.for_(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(Data_Foldable.foldableMaybe)(mFocus)(function (focus) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))($$eval(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(focus))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Control_Bind.bindFlipped(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(setFocus(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(unwind(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(Control_Monad_State_Trans.monadRecStateT(Control_Monad_Rec_Class.monadRecIdentity))))(function () {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(getRoots(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(function (roots) {
                    return Lambda_Machine_Heap.gc(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(Control_Monad_State_Trans.monadRecStateT(Control_Monad_Rec_Class.monadRecIdentity))(Data_Foldable.foldableArray)(roots)(Lambda_Machine_Node.children);
                });
            });
        });
    });
}));
module.exports = {
    Fetch: Fetch,
    Redex: Redex,
    "new": $$new,
    step: step,
    snapshot: snapshot
};
