// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Grammar = require("../Data.Grammar/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Nameless = require("../Lambda.Language.Nameless/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Global = (function () {
    function Global(value0) {
        this.value0 = value0;
    };
    Global.create = function (value0) {
        return new Global(value0);
    };
    return Global;
})();
var Root = (function () {
    function Root() {

    };
    Root.value = new Root();
    return Root;
})();
var World = function (x) {
    return x;
};
var Undefined = (function () {
    function Undefined(value0) {
        this.value0 = value0;
    };
    Undefined.create = function (value0) {
        return new Undefined(value0);
    };
    return Undefined;
})();
var CannotDelete = (function () {
    function CannotDelete(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    CannotDelete.create = function (value0) {
        return function (value1) {
            return new CannotDelete(value0, value1);
        };
    };
    return CannotDelete;
})();
var CannotRedefine = (function () {
    function CannotRedefine(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    CannotRedefine.create = function (value0) {
        return function (value1) {
            return new CannotRedefine(value0, value1);
        };
    };
    return CannotRedefine;
})();
var showEntity = {
    show: function (v) {
        if (v instanceof Global) {
            return Data_Show.show(Lambda_Language_Name.showName)(v.value0);
        };
        if (v instanceof Root) {
            return "the input";
        };
        throw new Error("Failed pattern match at Lambda.Language.World (line 46, column 10 - line 48, column 24): " + [ v.constructor.name ]);
    }
};
var showWorld = Data_Map_Internal.showMap(showEntity)(Data_Set.showSet(showEntity));
var showConsistencyError = {
    show: (function () {
        var join = function (dictShow) {
            return function (dictFoldable) {
                var $110 = Data_Grammar.joinWith(Data_Monoid.monoidString)(Data_Foldable.foldableArray)({
                    inject: Control_Category.identity(Control_Category.categoryFn),
                    conjunction: "and"
                });
                var $111 = Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $114 = Data_Show.show(Data_Show.showString);
                    var $115 = Data_Show.show(dictShow);
                    return function ($116) {
                        return $114($115($116));
                    };
                })());
                var $112 = Data_Array.fromFoldable(dictFoldable);
                return function ($113) {
                    return $110($111($112($113)));
                };
            };
        };
        return function (v) {
            if (v instanceof Undefined) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "No top-level ", Data_Grammar.pluralizeWith("s")(Data_Set.size(v.value0))("definition"), " for ", join(Lambda_Language_Name.showName)(Data_Set.foldableSet)(v.value0) ]);
            };
            if (v instanceof CannotDelete) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "Cannot delete ", Data_Show.show(Lambda_Language_Name.showName)(v.value0), " because it's still referenced by ", join(showEntity)(Data_Set.foldableSet)(v.value1) ]);
            };
            if (v instanceof CannotRedefine) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "Cannot redefine ", Data_Show.show(Lambda_Language_Name.showName)(v.value0), " because it's still referenced by ", join(showEntity)(Data_Set.foldableSet)(v.value1) ]);
            };
            throw new Error("Failed pattern match at Lambda.Language.World (line 61, column 10 - line 79, column 8): " + [ v.constructor.name ]);
        };
    })()
};
var newtypeWorld = {
    Coercible0: function () {
        return undefined;
    }
};
var globals = (function () {
    var name = function (v) {
        if (v instanceof Root) {
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Global) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v.value0);
        };
        throw new Error("Failed pattern match at Lambda.Language.World (line 151, column 10 - line 153, column 23): " + [ v.constructor.name ]);
    };
    var $117 = Data_Set.mapMaybe(Lambda_Language_Name.ordName)(name);
    var $118 = Data_Newtype.un()(World);
    return function ($119) {
        return $117(Data_Map.keys($118($119)));
    };
})();
var eqEntity = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Global && y instanceof Global) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0);
            };
            if (x instanceof Root && y instanceof Root) {
                return true;
            };
            return false;
        };
    }
};
var eqWorld = Data_Map_Internal.eqMap(eqEntity)(Data_Set.eqSet(eqEntity));
var ordEntity = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Global && y instanceof Global) {
                return Data_Ord.compare(Lambda_Language_Name.ordName)(x.value0)(y.value0);
            };
            if (x instanceof Global) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Global) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Root && y instanceof Root) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Lambda.Language.World (line 43, column 1 - line 43, column 40): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqEntity;
    }
};
var fromFreeVars = function (entity) {
    return function (fvs) {
        var others = Control_Bind.bind(Control_Bind.bindArray)(Data_Array.fromFoldable(Data_Set.foldableSet)(fvs))(function (name) {
            return Control_Applicative.pure(Control_Applicative.applicativeArray)(Data_Tuple.Tuple.create(new Global(name))(Data_Set.singleton(entity)));
        });
        var graph = Data_Map_Internal.fromFoldable(ordEntity)(Data_Foldable.foldableArray)(Data_Array.snoc(others)(new Data_Tuple.Tuple(entity, Data_Set.empty)));
        return graph;
    };
};
var referencing = function (name) {
    var $120 = Data_Maybe.fromMaybe(Data_Set.empty);
    var $121 = Data_Map_Internal.lookup(ordEntity)(new Global(name));
    var $122 = Data_Newtype.un()(World);
    return function ($123) {
        return $120($121($122($123)));
    };
};
var remove = function (entity) {
    var $124 = Data_Functor.map(Data_Map_Internal.functorMap)(Data_Set["delete"](ordEntity)(entity));
    var $125 = Data_Map_Internal["delete"](ordEntity)(entity);
    var $126 = Data_Newtype.un()(World);
    return function ($127) {
        return World($124($125($126($127))));
    };
};
var undefine = function (name) {
    return function (world) {
        var refs = referencing(name)(world);
        var $65 = Data_Set.size(refs) === 0;
        if ($65) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(remove(new Global(name))(world));
        };
        return Data_Either.Left.create(new CannotDelete(name, refs));
    };
};
var unfocus = remove(Root.value);
var eqConsistencyError = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Undefined && y instanceof Undefined) {
                return Data_Eq.eq(Data_Set.eqSet(Lambda_Language_Name.eqName))(x.value0)(y.value0);
            };
            if (x instanceof CannotDelete && y instanceof CannotDelete) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0) && Data_Eq.eq(Data_Set.eqSet(eqEntity))(x.value1)(y.value1);
            };
            if (x instanceof CannotRedefine && y instanceof CannotRedefine) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0) && Data_Eq.eq(Data_Set.eqSet(eqEntity))(x.value1)(y.value1);
            };
            return false;
        };
    }
};
var ordConsistencyError = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Undefined && y instanceof Undefined) {
                return Data_Ord.compare(Data_Set.ordSet(Lambda_Language_Name.ordName))(x.value0)(y.value0);
            };
            if (x instanceof Undefined) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Undefined) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CannotDelete && y instanceof CannotDelete) {
                var v = Data_Ord.compare(Lambda_Language_Name.ordName)(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(Data_Set.ordSet(ordEntity))(x.value1)(y.value1);
            };
            if (x instanceof CannotDelete) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CannotDelete) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CannotRedefine && y instanceof CannotRedefine) {
                var v = Data_Ord.compare(Lambda_Language_Name.ordName)(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(Data_Set.ordSet(ordEntity))(x.value1)(y.value1);
            };
            throw new Error("Failed pattern match at Lambda.Language.World (line 58, column 1 - line 58, column 60): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqConsistencyError;
    }
};
var empty = Data_Map_Internal.empty;
var combine = function (v) {
    return function (v1) {
        return World(Data_Map_Internal.unionWith(ordEntity)(Data_Set.union(ordEntity))(v)(v1));
    };
};
var add = function (entity) {
    return function (expr) {
        return function (world) {
            var fvs = Lambda_Language_Nameless.freeVars(expr);
            var missing = Data_Set.difference(Lambda_Language_Name.ordName)(fvs)(globals(world));
            var isClosed = (function () {
                if (entity instanceof Global) {
                    return Data_Set.size(missing) === 0 || Data_Eq.eq(Data_Set.eqSet(Lambda_Language_Name.eqName))(missing)(Data_Set.singleton(entity.value0));
                };
                if (entity instanceof Root) {
                    return Data_Set.size(missing) === 0;
                };
                throw new Error("Failed pattern match at Lambda.Language.World (line 136, column 16 - line 138, column 36): " + [ entity.constructor.name ]);
            })();
            if (isClosed) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(combine(world)(fromFreeVars(entity)(fvs)));
            };
            return Data_Either.Left.create(new Undefined(missing));
        };
    };
};
var define = function (name) {
    return function (x) {
        return function (world) {
            var refs = referencing(name)(world);
            var $103 = Data_Set.size(refs) === 0;
            if ($103) {
                return add(new Global(name))(x)(world);
            };
            return Data_Either.Left.create(new CannotRedefine(name, refs));
        };
    };
};
var focus = add(Root.value);
var $$new = function (prelude) {
    var addGlobal = function (v) {
        return add(new Global(v.value0))(v.value1);
    };
    var v = Data_Foldable.foldM(Data_Foldable.foldableArray)(Data_Either.monadEither)(Data_Function.flip(addGlobal))(empty)(prelude);
    if (v instanceof Data_Either.Left) {
        return Partial_Unsafe.unsafeCrashWith("Malformed prelude: " + Data_Show.show(showConsistencyError)(v.value0));
    };
    if (v instanceof Data_Either.Right) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Lambda.Language.World (line 93, column 15 - line 95, column 23): " + [ v.constructor.name ]);
};
module.exports = {
    World: World,
    Undefined: Undefined,
    CannotDelete: CannotDelete,
    CannotRedefine: CannotRedefine,
    "new": $$new,
    empty: empty,
    define: define,
    undefine: undefine,
    focus: focus,
    unfocus: unfocus,
    Global: Global,
    Root: Root,
    newtypeWorld: newtypeWorld,
    eqWorld: eqWorld,
    showWorld: showWorld,
    eqEntity: eqEntity,
    ordEntity: ordEntity,
    showEntity: showEntity,
    eqConsistencyError: eqConsistencyError,
    ordConsistencyError: ordConsistencyError,
    showConsistencyError: showConsistencyError
};
