// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var getWith = $foreign.getParamImpl(Data_Maybe.Nothing.value);
var get = function (dictParse) {
    return getWith((function () {
        var $1 = Lambda_Language_Parser.run(Lambda_Language_Parser.parse(dictParse));
        return function ($2) {
            return Data_Either.hush($1($2));
        };
    })());
};
module.exports = {
    get: get,
    getWith: getWith
};
