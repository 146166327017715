// Generated by purs version 0.14.4
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Lazy = require("../Control.Lazy/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var $$Function = (function () {
    function $$Function() {

    };
    $$Function.value = new $$Function();
    return $$Function;
})();
var Argument = (function () {
    function Argument() {

    };
    Argument.value = new Argument();
    return Argument;
})();
var Global = (function () {
    function Global() {

    };
    Global.value = new Global();
    return Global;
})();
var Var = (function () {
    function Var(value0) {
        this.value0 = value0;
    };
    Var.create = function (value0) {
        return new Var(value0);
    };
    return Var;
})();
var Nat = (function () {
    function Nat(value0) {
        this.value0 = value0;
    };
    Nat.create = function (value0) {
        return new Nat(value0);
    };
    return Nat;
})();
var List = (function () {
    function List(value0) {
        this.value0 = value0;
    };
    List.create = function (value0) {
        return new List(value0);
    };
    return List;
})();
var Lambda = (function () {
    function Lambda(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Lambda.create = function (value0) {
        return function (value1) {
            return new Lambda(value0, value1);
        };
    };
    return Lambda;
})();
var Apply = (function () {
    function Apply(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Apply.create = function (value0) {
        return function (value1) {
            return new Apply(value0, value1);
        };
    };
    return Apply;
})();
var Highlight = (function () {
    function Highlight(value0) {
        this.value0 = value0;
    };
    Highlight.create = function (value0) {
        return new Highlight(value0);
    };
    return Highlight;
})();
var Cycle = (function () {
    function Cycle() {

    };
    Cycle.value = new Cycle();
    return Cycle;
})();
var unHighlight = function (v) {
    if (v instanceof Var) {
        return v;
    };
    if (v instanceof Nat) {
        return v;
    };
    if (v instanceof List) {
        return List.create(Data_Functor.map(Data_List_Types.functorList)(unHighlight)(v.value0));
    };
    if (v instanceof Lambda) {
        return Lambda.create(v.value0)(unHighlight(v.value1));
    };
    if (v instanceof Apply) {
        return new Apply(unHighlight(v.value0), unHighlight(v.value1));
    };
    if (v instanceof Highlight) {
        return v.value0;
    };
    if (v instanceof Cycle) {
        return Cycle.value;
    };
    throw new Error("Failed pattern match at Lambda.Language.Expression (line 38, column 15 - line 45, column 17): " + [ v.constructor.name ]);
};
var parseVar = Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Var.create)(Lambda_Language_Parser.parse(Lambda_Language_Name.parseName));
var parseList = function (p) {
    return Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(List.create)(Lambda_Language_Parser.brackets(Text_Parsing_Parser_Combinators.sepBy(Data_Identity.monadIdentity)(p)(Lambda_Language_Parser.token(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(",")))));
};
var match = function (v) {
    if (v instanceof Lambda && (v.value1 instanceof Highlight && v.value1.value0 instanceof Lambda)) {
        return new Data_Maybe.Just({
            f: v.value0,
            z: v.value1.value0.value0,
            body: v.value1.value0.value1
        });
    };
    if (v instanceof Lambda && v.value1 instanceof Lambda) {
        return new Data_Maybe.Just({
            f: v.value0,
            z: v.value1.value0,
            body: v.value1.value1
        });
    };
    return Data_Maybe.Nothing.value;
};
var isLambda = function (v) {
    if (v instanceof Lambda) {
        return true;
    };
    return false;
};
var isDigit = function (c) {
    return "0" <= c && c <= "9";
};
var parseNat = Lambda_Language_Parser.token(Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(isDigit)))(function (digits) {
    var n = Data_Maybe.fromJust()(Data_Int.fromString(Data_String_CodeUnits.fromCharArray(digits)));
    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(new Nat(n));
}));
var isComposite = function (v) {
    if (v instanceof Apply) {
        return true;
    };
    if (v instanceof Lambda) {
        return true;
    };
    return false;
};
var highlight = function ($178) {
    return Lambda_Language_Pretty.style((function (v) {
        if (v instanceof $$Function) {
            return "highlight-function";
        };
        if (v instanceof Argument) {
            return "highlight-argument";
        };
        if (v instanceof Global) {
            return "highlight-global";
        };
        throw new Error("Failed pattern match at Lambda.Language.Expression (line 102, column 23 - line 105, column 31): " + [ v.constructor.name ]);
    })($178));
};
var genericExpression = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Var(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Nat(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new List(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new Lambda(x.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new Apply(x.value0.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new Highlight(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
            return Cycle.value;
        };
        throw new Error("Failed pattern match at Lambda.Language.Expression (line 49, column 1 - line 49, column 58): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Var) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Nat) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof List) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof Lambda) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1)))));
        };
        if (x instanceof Apply) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1))))));
        };
        if (x instanceof Highlight) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof Cycle) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))));
        };
        throw new Error("Failed pattern match at Lambda.Language.Expression (line 49, column 1 - line 49, column 58): " + [ x.constructor.name ]);
    }
};
var showExpression = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericExpression)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))({
            reflectSymbol: function () {
                return "Var";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt))({
            reflectSymbol: function () {
                return "Nat";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_List_Types.showList(showExpression)))({
            reflectSymbol: function () {
                return "List";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Name.showName))(Data_Show_Generic.genericShowArgsArgument(showExpression)))({
            reflectSymbol: function () {
                return "Lambda";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(showExpression))(Data_Show_Generic.genericShowArgsArgument(showExpression)))({
            reflectSymbol: function () {
                return "Apply";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(showExpression))({
            reflectSymbol: function () {
                return "Highlight";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
            reflectSymbol: function () {
                return "Cycle";
            }
        }))))))))(x);
    }
};
var formatList = function (xs) {
    return Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text("["))(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Data_Foldable.intercalate(Data_List_Types.foldableList)(Lambda_Language_Pretty.monoidBuilder)(Lambda_Language_Pretty.text(", "))(xs))(Lambda_Language_Pretty.text("]")));
};
var failCycle = Lambda_Language_Parser.token(Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("\u2026"))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("...")))(function (ellipsis) {
    return Text_Parsing_Parser.fail(Data_Identity.monadIdentity)(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "We don't know how to parse \u201c", ellipsis, "\u201d! (Hint: it might indicate cyclic data or truncated output) " ]));
}));
var parseAtom = function (parseExpr) {
    var parseLambda = Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Lambda_Language_Parser.token(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("\\"))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("\u03bb")))))(function () {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Lambda_Language_Parser.parse(Lambda_Language_Name.parseName)))(function (names) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Lambda_Language_Parser.token(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("."))))(function () {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(parseExpr)(function (body) {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(Data_Foldable.foldr(Data_Foldable.foldableArray)(Lambda.create)(body)(names));
                });
            });
        });
    });
    return Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(failCycle)(parseLambda))(parseNat))(parseList(parseExpr)))(Lambda_Language_Parser.parens(parseExpr)))(parseVar);
};
var parseExpression = {
    parse: Control_Lazy.fix(Text_Parsing_Parser.lazyParserT)(function (parseExpr) {
        return Control_Apply.apply(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_Foldable.foldl(Data_Foldable.foldableArray)(Apply.create))(parseAtom(parseExpr)))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(parseAtom(parseExpr)));
    })
};
var eqExpression = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Var && y instanceof Var) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0);
            };
            if (x instanceof Nat && y instanceof Nat) {
                return x.value0 === y.value0;
            };
            if (x instanceof List && y instanceof List) {
                return Data_Eq.eq(Data_List_Types.eqList(eqExpression))(x.value0)(y.value0);
            };
            if (x instanceof Lambda && y instanceof Lambda) {
                return Data_Eq.eq(Lambda_Language_Name.eqName)(x.value0)(y.value0) && Data_Eq.eq(eqExpression)(x.value1)(y.value1);
            };
            if (x instanceof Apply && y instanceof Apply) {
                return Data_Eq.eq(eqExpression)(x.value0)(y.value0) && Data_Eq.eq(eqExpression)(x.value1)(y.value1);
            };
            if (x instanceof Highlight && y instanceof Highlight) {
                return Data_Eq.eq(eqExpression)(x.value0)(y.value0);
            };
            if (x instanceof Cycle && y instanceof Cycle) {
                return true;
            };
            return false;
        };
    }
};
var encodeNat = function (n) {
    var z = Lambda_Language_Name.from("z");
    var s = Lambda_Language_Name.from("s");
    var loop = function (k) {
        if (k <= 0) {
            return new Var(z);
        };
        if (Data_Boolean.otherwise) {
            return new Apply(new Var(s), loop(k - 1 | 0));
        };
        throw new Error("Failed pattern match at Lambda.Language.Expression (line 245, column 3 - line 247, column 47): " + [ k.constructor.name ]);
    };
    return new Lambda(s, new Lambda(z, loop(n)));
};
var encodeList = function (cons) {
    return function (nil) {
        return function (xs) {
            var loop = function (v) {
                if (v instanceof Data_List_Types.Nil) {
                    return new Var(nil);
                };
                if (v instanceof Data_List_Types.Cons) {
                    return new Apply(new Apply(new Var(cons), v.value0), loop(v.value1));
                };
                throw new Error("Failed pattern match at Lambda.Language.Expression (line 259, column 10 - line 261, column 54): " + [ v.constructor.name ]);
            };
            return Lambda.create(cons)(Lambda.create(nil)(loop(xs)));
        };
    };
};
var check = function (head) {
    var z = Lambda_Language_Name.base(head.z);
    var f = Lambda_Language_Name.base(head.f);
    if (f === "s" && z === "z") {
        return Data_Maybe.Just.create(new Data_Either.Right(head));
    };
    if (f === "cons" && z === "nil") {
        return Data_Maybe.Just.create(new Data_Either.Left(head));
    };
    if (f === "f" && z === "z") {
        return Data_Maybe.Just.create(new Data_Either.Left(head));
    };
    if (Data_Boolean.otherwise) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Lambda.Language.Expression (line 134, column 14 - line 144, column 27): " + [ Data_Unit.unit.constructor.name ]);
};
var asNat = function (v) {
    var walk = function ($copy_v1) {
        return function ($copy_v2) {
            var $tco_var_v1 = $copy_v1;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v1, v2) {
                if (v2 instanceof Apply && (v2.value0 instanceof Var && Data_Eq.eq(Lambda_Language_Name.eqName)(v2.value0.value0)(v.f))) {
                    $tco_var_v1 = 1 + v1 | 0;
                    $copy_v2 = v2.value1;
                    return;
                };
                if (v2 instanceof Var && Data_Eq.eq(Lambda_Language_Name.eqName)(v2.value0)(v.z)) {
                    $tco_done = true;
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new Nat(v1));
                };
                $tco_done = true;
                return Data_Maybe.Nothing.value;
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v1, $copy_v2);
            };
            return $tco_result;
        };
    };
    return walk(0)(v.body);
};
var quote = function (v) {
    if (v instanceof Var) {
        return v;
    };
    if (v instanceof Nat) {
        return v;
    };
    if (v instanceof List) {
        return List.create(Data_Functor.map(Data_List_Types.functorList)(quote)(v.value0));
    };
    if (v instanceof Lambda) {
        return Data_Maybe["fromMaybe'"](function (v1) {
            return Lambda.create(v.value0)(quote(v.value1));
        })(Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(check)(match(new Lambda(v.value0, v.value1))))(function (head) {
            return Data_Either.either(asList)(asNat)(head);
        }));
    };
    if (v instanceof Apply) {
        return new Apply(quote(v.value0), quote(v.value1));
    };
    if (v instanceof Highlight) {
        return Highlight.create(quote(v.value0));
    };
    if (v instanceof Cycle) {
        return Cycle.value;
    };
    throw new Error("Failed pattern match at Lambda.Language.Expression (line 83, column 9 - line 92, column 17): " + [ v.constructor.name ]);
};
var asList = function (v) {
    var walk = function ($copy_v1) {
        return function ($copy_v2) {
            var $tco_var_v1 = $copy_v1;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v1, v2) {
                if (v2 instanceof Apply && (v2.value0 instanceof Apply && (v2.value0.value0 instanceof Var && Data_Eq.eq(Lambda_Language_Name.eqName)(v2.value0.value0.value0)(v.f)))) {
                    $tco_var_v1 = new Data_List_Types.Cons(quote(v2.value0.value1), v1);
                    $copy_v2 = v2.value1;
                    return;
                };
                if (v2 instanceof Var && Data_Eq.eq(Lambda_Language_Name.eqName)(v2.value0)(v.z)) {
                    $tco_done = true;
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(List.create(Data_List.reverse(v1)));
                };
                $tco_done = true;
                return Data_Maybe.Nothing.value;
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v1, $copy_v2);
            };
            return $tco_result;
        };
    };
    return walk(Data_List_Types.Nil.value)(v.body);
};
var prettyExpression = {
    pretty: function (rep) {
        var loop = function (inApp) {
            return function (v) {
                if (v instanceof Var) {
                    return Lambda_Language_Pretty.text(Data_Show.show(Lambda_Language_Name.showName)(v.value0));
                };
                if (v instanceof Nat) {
                    return Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v.value0));
                };
                if (v instanceof List) {
                    return formatList(Data_Functor.map(Data_List_Types.functorList)(loop(false))(v.value0));
                };
                if (v instanceof Lambda) {
                    return Lambda_Language_Pretty.parensIf(inApp)(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text("\u03bb" + (Data_Show.show(Lambda_Language_Name.showName)(v.value0) + ". ")))(loop(false)(v.value1)));
                };
                if (v instanceof Apply) {
                    return Lambda_Language_Pretty.parensIf(inApp)(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(loop(isLambda(v.value0))(v.value0))(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text(" "))(loop(isComposite(v.value1))(v.value1))));
                };
                if (v instanceof Highlight && v.value0 instanceof Apply) {
                    return Lambda_Language_Pretty.parensIf(inApp)(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(highlight($$Function.value)(loop(isLambda(v.value0.value0))(v.value0.value0)))(Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text(" "))(highlight(Argument.value)(loop(isComposite(v.value0.value1))(v.value0.value1)))));
                };
                if (v instanceof Highlight) {
                    return highlight(Global.value)(loop(inApp)(v.value0));
                };
                if (v instanceof Cycle) {
                    return Lambda_Language_Pretty.text("\u2026");
                };
                throw new Error("Failed pattern match at Lambda.Language.Expression (line 57, column 18 - line 75, column 24): " + [ v.constructor.name ]);
            };
        };
        var $179 = loop(false);
        var $180 = Lambda_Language_Pretty.select(rep)({
            sugar: quote,
            raw: Control_Category.identity(Control_Category.categoryFn)
        });
        return function ($181) {
            return $179($180($181));
        };
    }
};
module.exports = {
    Var: Var,
    Nat: Nat,
    List: List,
    Lambda: Lambda,
    Apply: Apply,
    Highlight: Highlight,
    Cycle: Cycle,
    unHighlight: unHighlight,
    encodeNat: encodeNat,
    encodeList: encodeList,
    eqExpression: eqExpression,
    genericExpression: genericExpression,
    showExpression: showExpression,
    prettyExpression: prettyExpression,
    parseExpression: parseExpression
};
