// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var monadEffect = {
    Applicative0: function () {
        return applicativeEffect;
    },
    Bind1: function () {
        return bindEffect;
    }
};
var bindEffect = {
    bind: $foreign.bindE,
    Apply0: function () {
        return applyEffect;
    }
};
var applyEffect = {
    apply: Control_Monad.ap(monadEffect),
    Functor0: function () {
        return functorEffect;
    }
};
var applicativeEffect = {
    pure: $foreign.pureE,
    Apply0: function () {
        return applyEffect;
    }
};
var functorEffect = {
    map: Control_Applicative.liftA1(applicativeEffect)
};
var semigroupEffect = function (dictSemigroup) {
    return {
        append: Control_Apply.lift2(applyEffect)(Data_Semigroup.append(dictSemigroup))
    };
};
var monoidEffect = function (dictMonoid) {
    return {
        mempty: $foreign.pureE(Data_Monoid.mempty(dictMonoid)),
        Semigroup0: function () {
            return semigroupEffect(dictMonoid.Semigroup0());
        }
    };
};
module.exports = {
    functorEffect: functorEffect,
    applyEffect: applyEffect,
    applicativeEffect: applicativeEffect,
    bindEffect: bindEffect,
    monadEffect: monadEffect,
    semigroupEffect: semigroupEffect,
    monoidEffect: monoidEffect,
    untilE: $foreign.untilE,
    whileE: $foreign.whileE,
    forE: $foreign.forE,
    foreachE: $foreign.foreachE
};
