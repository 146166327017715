// Generated by purs version 0.14.4
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Lambda_Language_Definition = require("../Lambda.Language.Definition/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var addPreludeIds = function (ds) {
    if (Data_Array.length(ds) > 32) {
        return Partial_Unsafe.unsafeCrashWith("too many prelude defs");
    };
    if (Data_Boolean.otherwise) {
        var setPreludeId = function (i) {
            return function (v) {
                return Lambda_Language_Definition.Definition({
                    id: 1 << i,
                    name: v.name,
                    args: v.args,
                    expr: v.expr
                });
            };
        };
        return Data_Array.mapWithIndex(setPreludeId)(ds);
    };
    throw new Error("Failed pattern match at Lambda.Language.Prelude (line 53, column 1 - line 53, column 54): " + [ ds.constructor.name ]);
};
var defs = addPreludeIds(Data_Functor.map(Data_Functor.functorArray)(Lambda_Language_Parser.unsafeRun(Lambda_Language_Parser.parse(Lambda_Language_Definition.parseDefinition)))([ "identity x = x", "const x y = x", "compose f g x = f (g x)", "fix f = f (fix f)", "true t f = t", "false t f = f", "and x y = x y false", "or x y = x true y", "not x = x false true", "succ n s z = s (n s z)", "pred n s z = n (\u03bbg. \u03bbh. h (g s)) (\u03bbu. z) (\u03bbu. u)", "add m n s z = m s (n s z)", "mul m n s z = m (n s) z", "zero? n = n (\u03bbx. false) true", "cons x xs f z = f x (xs f z)", "nil f z = z", "foldr f z l = l f z", "map f = foldr (compose cons f) nil", "any = foldr or false", "all = foldr and true", "iterate f x = cons x (iterate f (f x))", "repeat x = cons x (repeat x)" ]));
module.exports = {
    defs: defs
};
