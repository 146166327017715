// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Lambda_Language_Snapshot_Error = require("../Lambda.Language.Snapshot.Error/index.js");
var Lambda_Language_Snapshot_Tag = require("../Lambda.Language.Snapshot.Tag/index.js");
var Var = (function () {
    function Var(value0) {
        this.value0 = value0;
    };
    Var.create = function (value0) {
        return new Var(value0);
    };
    return Var;
})();
var Nat = (function () {
    function Nat(value0) {
        this.value0 = value0;
    };
    Nat.create = function (value0) {
        return new Nat(value0);
    };
    return Nat;
})();
var Take = (function () {
    function Take(value0) {
        this.value0 = value0;
    };
    Take.create = function (value0) {
        return new Take(value0);
    };
    return Take;
})();
var Lambda = (function () {
    function Lambda(value0) {
        this.value0 = value0;
    };
    Lambda.create = function (value0) {
        return new Lambda(value0);
    };
    return Lambda;
})();
var Define = (function () {
    function Define(value0) {
        this.value0 = value0;
    };
    Define.create = function (value0) {
        return new Define(value0);
    };
    return Define;
})();
var AppVar = (function () {
    function AppVar(value0) {
        this.value0 = value0;
    };
    AppVar.create = function (value0) {
        return new AppVar(value0);
    };
    return AppVar;
})();
var Apply = (function () {
    function Apply() {

    };
    Apply.value = new Apply();
    return Apply;
})();
var showRPN = {
    show: function (v) {
        if (v instanceof Var) {
            return "var " + Data_Show.show(Data_Show.showInt)(v.value0);
        };
        if (v instanceof Nat) {
            return Data_Show.show(Data_Show.showInt)(v.value0);
        };
        if (v instanceof Take) {
            return "take " + Data_Show.show(Data_Show.showInt)(v.value0);
        };
        if (v instanceof Lambda) {
            return "lam " + Data_Show.show(Data_Show.showInt)(v.value0);
        };
        if (v instanceof Define) {
            return "def " + Data_Show.show(Data_Show.showInt)(v.value0);
        };
        if (v instanceof AppVar) {
            return "app " + Data_Show.show(Data_Show.showInt)(v.value0);
        };
        if (v instanceof Apply) {
            return "app";
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot.RPN (line 53, column 10 - line 60, column 19): " + [ v.constructor.name ]);
    }
};
var untag = function (tagged) {
    var tag = Lambda_Language_Snapshot_Tag.Tag(tagged & 7);
    var payload = tagged >>> 3;
    return {
        tag: tag,
        payload: payload
    };
};
var unsafeTag = function (tag) {
    return function (x) {
        return x << 3 | Data_Newtype.un()(Lambda_Language_Snapshot_Tag.Tag)(tag);
    };
};
var setTag = function (dictMonadThrow) {
    return function (tag) {
        return function (payload) {
            var check = function (x) {
                if (x < 0) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(new Lambda_Language_Snapshot_Error.PayloadOutOfRange(tag, payload));
                };
                if (x > 536870911) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(new Lambda_Language_Snapshot_Error.PayloadOutOfRange(tag, payload));
                };
                if (Data_Boolean.otherwise) {
                    return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(x);
                };
                throw new Error("Failed pattern match at Lambda.Language.Snapshot.RPN (line 91, column 3 - line 94, column 25): " + [ x.constructor.name ]);
            };
            return Data_Functor.map((((dictMonadThrow.Monad0()).Bind1()).Apply0()).Functor0())(unsafeTag(tag))(check(payload));
        };
    };
};
var eqRPN = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Var && y instanceof Var) {
                return x.value0 === y.value0;
            };
            if (x instanceof Nat && y instanceof Nat) {
                return x.value0 === y.value0;
            };
            if (x instanceof Take && y instanceof Take) {
                return x.value0 === y.value0;
            };
            if (x instanceof Lambda && y instanceof Lambda) {
                return x.value0 === y.value0;
            };
            if (x instanceof Define && y instanceof Define) {
                return x.value0 === y.value0;
            };
            if (x instanceof AppVar && y instanceof AppVar) {
                return x.value0 === y.value0;
            };
            if (x instanceof Apply && y instanceof Apply) {
                return true;
            };
            return false;
        };
    }
};
var encode = function (dictMonadThrow) {
    return Data_Traversable.traverse(Data_Traversable.traversableArray)((dictMonadThrow.Monad0()).Applicative0())(function (v) {
        if (v instanceof Var) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_VAR"])(v.value0);
        };
        if (v instanceof Nat) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_NAT"])(v.value0);
        };
        if (v instanceof Take) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_TAK"])(v.value0);
        };
        if (v instanceof Lambda) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_LAM"])(v.value0);
        };
        if (v instanceof Define) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_DEF"])(v.value0);
        };
        if (v instanceof AppVar) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_APV"])(v.value0);
        };
        if (v instanceof Apply) {
            return setTag(dictMonadThrow)(Lambda_Language_Snapshot_Tag["_AP0"])(1);
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot.RPN (line 64, column 19 - line 71, column 25): " + [ v.constructor.name ]);
    });
};
var decode = function (dictMonadThrow) {
    var step = function (v) {
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_VAR"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(new Var(v.payload));
        };
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_NAT"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(new Nat(v.payload));
        };
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_TAK"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(new Take(v.payload));
        };
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_LAM"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(new Lambda(v.payload));
        };
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_DEF"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(new Define(v.payload));
        };
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_APV"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(new AppVar(v.payload));
        };
        if (Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(v.tag)(Lambda_Language_Snapshot_Tag["_AP0"])) {
            return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(Apply.value);
        };
        if (Data_Boolean.otherwise) {
            return Control_Monad_Error_Class.throwError(dictMonadThrow)(new Lambda_Language_Snapshot_Error.UnrecognizedTag(v.tag));
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot.RPN (line 77, column 3 - line 85, column 51): " + [ v.constructor.name ]);
    };
    return Data_Traversable.traverse(Data_Traversable.traversableArray)((dictMonadThrow.Monad0()).Applicative0())(function ($53) {
        return step(untag($53));
    });
};
module.exports = {
    Var: Var,
    Nat: Nat,
    Take: Take,
    Lambda: Lambda,
    Define: Define,
    AppVar: AppVar,
    Apply: Apply,
    encode: encode,
    decode: decode,
    unsafeTag: unsafeTag,
    eqRPN: eqRPN,
    showRPN: showRPN
};
