// Generated by purs version 0.14.4
"use strict";
var Fetched = (function () {
    function Fetched(value0) {
        this.value0 = value0;
    };
    Fetched.create = function (value0) {
        return new Fetched(value0);
    };
    return Fetched;
})();
var Stored = (function () {
    function Stored(value0) {
        this.value0 = value0;
    };
    Stored.create = function (value0) {
        return new Stored(value0);
    };
    return Stored;
})();
var Saved = (function () {
    function Saved() {

    };
    Saved.value = new Saved();
    return Saved;
})();
var ApiError = (function () {
    function ApiError(value0) {
        this.value0 = value0;
    };
    ApiError.create = function (value0) {
        return new ApiError(value0);
    };
    return ApiError;
})();
var SaveError = (function () {
    function SaveError(value0) {
        this.value0 = value0;
    };
    SaveError.create = function (value0) {
        return new SaveError(value0);
    };
    return SaveError;
})();
module.exports = {
    Fetched: Fetched,
    Stored: Stored,
    Saved: Saved,
    ApiError: ApiError,
    SaveError: SaveError
};
