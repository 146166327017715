// Generated by purs version 0.14.4
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var Code = function (x) {
    return x;
};
var showCode = Data_Show.showString;
var newtypeCode = {
    Coercible0: function () {
        return undefined;
    }
};
var isCodeChar = function (c) {
    var pat = Data_String_Pattern.Pattern(Data_String_CodeUnits.singleton(c));
    var isUpper = "A" <= c && c <= "Z";
    var isDigit = "0" <= c && c <= "9";
    var ambiguous = Data_String_CodeUnits.contains(pat)("ILOU");
    return isDigit || isUpper && !ambiguous;
};
var parseCode = {
    parse: Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(function ($0) {
        return Code(Data_String_CodeUnits.fromCharArray($0));
    })(Data_Unfoldable.replicateA(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(Data_Unfoldable.unfoldableArray)(Data_Traversable.traversableArray)(8)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(isCodeChar)))
};
var eqCode = Data_Eq.eqString;
var encodeJsonCode = Data_Argonaut_Encode_Class.encodeJsonJString;
var decodeJsonCode = {
    decodeJson: Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Lambda_Language_Parser.liftJson(Lambda_Language_Parser.parse(parseCode)))(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeJsonString))
};
module.exports = {
    Code: Code,
    newtypeCode: newtypeCode,
    eqCode: eqCode,
    showCode: showCode,
    encodeJsonCode: encodeJsonCode,
    decodeJsonCode: decodeJsonCode,
    parseCode: parseCode
};
