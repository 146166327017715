// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Lambda_Machine_Stack = require("../Lambda.Machine.Stack/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var Stash = function (x) {
    return x;
};
var suspend = function (dictMonadState) {
    var extend = function (stash) {
        var $25 = Data_Maybe.maybe(stash)(function (v) {
            return new Data_List_Types.Cons(v, stash);
        });
        return function ($26) {
            return $25(Lambda_Machine_Stack.fromList($26));
        };
    };
    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
        return v.stack;
    }))(function (v) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v1) {
            return v1.stash;
        }))(function (v1) {
            return Control_Monad_State_Class.modify_(dictMonadState)(function (v2) {
                var $13 = {};
                for (var $14 in v2) {
                    if ({}.hasOwnProperty.call(v2, $14)) {
                        $13[$14] = v2[$14];
                    };
                };
                $13.stack = {
                    top: v.top,
                    rest: Data_List_Types.Nil.value
                };
                $13.stash = Stash(extend(v1)(v.rest));
                return $13;
            });
        });
    });
};
var roots = (function () {
    var unStash = Safe_Coerce.coerce();
    var $27 = Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidArray);
    var $28 = Data_Array.fromFoldable(Data_List_Types.foldableList);
    var $29 = Data_Functor.map(Data_List_Types.functorList)(Lambda_Machine_Stack.roots);
    return function ($30) {
        return $27($28($29(unStash($30))));
    };
})();
var restore = function (dictMonadState) {
    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
        return v.stash;
    }))(function (v) {
        if (v instanceof Data_List_Types.Cons) {
            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (v1) {
                var $20 = {};
                for (var $21 in v1) {
                    if ({}.hasOwnProperty.call(v1, $21)) {
                        $20[$21] = v1[$21];
                    };
                };
                $20.stack = v.value0;
                $20.stash = v.value1;
                return $20;
            }))(function () {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(new Data_Maybe.Just(v.value0.top));
            });
        };
        return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Maybe.Nothing.value);
    });
};
var empty = Data_List_Types.Nil.value;
module.exports = {
    empty: empty,
    suspend: suspend,
    restore: restore,
    roots: roots
};
