// Generated by purs version 0.14.4
"use strict";
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var MediaType = function (x) {
    return x;
};
var showMediaType = {
    show: function (v) {
        return "(MediaType " + (Data_Show.show(Data_Show.showString)(v) + ")");
    }
};
var newtypeMediaType = {
    Coercible0: function () {
        return undefined;
    }
};
var eqMediaType = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordMediaType = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordString)(x)(y);
        };
    },
    Eq0: function () {
        return eqMediaType;
    }
};
module.exports = {
    MediaType: MediaType,
    newtypeMediaType: newtypeMediaType,
    eqMediaType: eqMediaType,
    ordMediaType: ordMediaType,
    showMediaType: showMediaType
};
