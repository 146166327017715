// Generated by purs version 0.14.4
"use strict";
var Components_Overlay = require("../Components.Overlay/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var $$new = function __do() {
    var overlay = Components_Overlay["new"]();
    return React_Basic_Hooks.component("Spinner")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(overlay({
            dismiss: Data_Maybe.Nothing.value,
            children: [ React_Basic_DOM_Generated.div()({
                className: "spinner",
                children: [ React_Basic_DOM_Generated.span()({
                    className: "glyphicon glyphicon-refresh spin",
                    children: [  ]
                }) ]
            }) ]
        }));
    })();
};
module.exports = {
    "new": $$new
};
