// Generated by purs version 0.14.4
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_HashMap = require("../Data.HashMap/index.js");
var Data_Hashable = require("../Data.Hashable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var HashSet = function (x) {
    return x;
};
var union = function (dictHashable) {
    return function (v) {
        return function (v1) {
            return Data_HashMap.unionWith(dictHashable)(Data_Function["const"])(v)(v1);
        };
    };
};
var toMap = function (v) {
    return v;
};
var toArray = function (v) {
    return Data_HashMap.keys(v);
};
var toUnfoldable = function (dictUnfoldable) {
    var $70 = Data_Array.toUnfoldable(dictUnfoldable);
    return function ($71) {
        return $70(toArray($71));
    };
};
var size = function (v) {
    return Data_HashMap.size(v);
};
var singleton = function (dictHashable) {
    return function (a) {
        return Data_HashMap.singleton(dictHashable)(a)(Data_Unit.unit);
    };
};
var showHashSet = function (dictShow) {
    return {
        show: function (s) {
            return "(fromFoldable " + (Data_Show.show(Data_Show.showArray(dictShow))(toArray(s)) + ")");
        }
    };
};
var semigroupHashSet = function (dictHashable) {
    return {
        append: union(dictHashable)
    };
};
var member = function (dictHashable) {
    return function (a) {
        return function (v) {
            return Data_HashMap.member(dictHashable)(a)(v);
        };
    };
};
var isEmpty = function (v) {
    return Data_HashMap.isEmpty(v);
};
var intersection = function (dictHashable) {
    return function (v) {
        return function (v1) {
            return Data_HashMap.intersectionWith(dictHashable)(Data_Function["const"])(v)(v1);
        };
    };
};
var insert = function (dictHashable) {
    return function (a) {
        return function (v) {
            return Data_HashMap.insert(dictHashable)(a)(Data_Unit.unit)(v);
        };
    };
};
var hashableHashSet = function (dictHashable) {
    return Data_HashMap.hashHashMap(dictHashable)(Data_Hashable.hashableUnit);
};
var fromMap = HashSet;
var fromArray = function (dictHashable) {
    var $72 = Data_HashMap.fromArrayBy(dictHashable)(Control_Category.identity(Control_Category.categoryFn))(Data_Function["const"](Data_Unit.unit));
    return function ($73) {
        return HashSet($72($73));
    };
};
var foldableHashSet = {
    foldr: function (f) {
        return function (a) {
            return function (v) {
                return Data_FoldableWithIndex.foldrWithIndex(Data_HashMap.foldableWithIndexHashMap)(function (k) {
                    return function (v1) {
                        return f(k);
                    };
                })(a)(v);
            };
        };
    },
    foldl: function (f) {
        return function (a) {
            return function (v) {
                return Data_FoldableWithIndex.foldlWithIndex(Data_HashMap.foldableWithIndexHashMap)(function (k) {
                    return function (b) {
                        return function (v1) {
                            return f(b)(k);
                        };
                    };
                })(a)(v);
            };
        };
    },
    foldMap: function (dictMonoid) {
        return function (f) {
            return function (v) {
                return Data_FoldableWithIndex.foldMapWithIndex(Data_HashMap.foldableWithIndexHashMap)(dictMonoid)(function (k) {
                    return function (v1) {
                        return f(k);
                    };
                })(v);
            };
        };
    }
};
var filter = function (f) {
    return function (v) {
        return Data_HashMap.filterWithKey(function (k) {
            return function (v1) {
                return f(k);
            };
        })(v);
    };
};
var eqHashSet = function (dictEq) {
    return Data_HashMap.eqHashMap(dictEq)(Data_Eq.eqUnit);
};
var empty = Data_HashMap.empty;
var fromFoldable = function (dictFoldable) {
    return function (dictHashable) {
        return Data_Foldable.foldr(dictFoldable)(insert(dictHashable))(empty);
    };
};
var map = function (dictHashable) {
    return function (f) {
        return Data_Foldable.foldr(foldableHashSet)(function (x) {
            return insert(dictHashable)(f(x));
        })(empty);
    };
};
var mapMaybe = function (dictHashable) {
    return function (f) {
        return Data_Foldable.foldr(foldableHashSet)(function (a) {
            return function (s) {
                var v = f(a);
                if (v instanceof Data_Maybe.Nothing) {
                    return s;
                };
                if (v instanceof Data_Maybe.Just) {
                    return insert(dictHashable)(v.value0)(s);
                };
                throw new Error("Failed pattern match at Data.HashSet (line 124, column 18 - line 126, column 33): " + [ v.constructor.name ]);
            };
        })(empty);
    };
};
var monoidHashSet = function (dictHashable) {
    return {
        mempty: empty,
        Semigroup0: function () {
            return semigroupHashSet(dictHashable);
        }
    };
};
var unions = function (dictFoldable) {
    return function (dictHashable) {
        return Data_Foldable.fold(dictFoldable)(monoidHashSet(dictHashable));
    };
};
var difference = function (dictHashable) {
    return function (v) {
        return function (v1) {
            return Data_HashMap.difference(dictHashable)(v)(v1);
        };
    };
};
var $$delete = function (dictHashable) {
    return function (a) {
        return function (v) {
            return Data_HashMap["delete"](dictHashable)(a)(v);
        };
    };
};
module.exports = {
    empty: empty,
    singleton: singleton,
    insert: insert,
    member: member,
    "delete": $$delete,
    map: map,
    filter: filter,
    mapMaybe: mapMaybe,
    union: union,
    unions: unions,
    intersection: intersection,
    difference: difference,
    size: size,
    isEmpty: isEmpty,
    fromArray: fromArray,
    fromFoldable: fromFoldable,
    fromMap: fromMap,
    toArray: toArray,
    toMap: toMap,
    toUnfoldable: toUnfoldable,
    eqHashSet: eqHashSet,
    hashableHashSet: hashableHashSet,
    semigroupHashSet: semigroupHashSet,
    monoidHashSet: monoidHashSet,
    showHashSet: showHashSet,
    foldableHashSet: foldableHashSet
};
