// Generated by purs version 0.14.4
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Lambda_Language_Snapshot_Code = require("../Lambda.Language.Snapshot.Code/index.js");
var Fetch = (function () {
    function Fetch(value0) {
        this.value0 = value0;
    };
    Fetch.create = function (value0) {
        return new Fetch(value0);
    };
    return Fetch;
})();
var Store = (function () {
    function Store() {

    };
    Store.value = new Store();
    return Store;
})();
var Save = (function () {
    function Save() {

    };
    Save.value = new Save();
    return Save;
})();
var eqRequest = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Fetch && y instanceof Fetch) {
                return Data_Eq.eq(Lambda_Language_Snapshot_Code.eqCode)(x.value0)(y.value0);
            };
            if (x instanceof Store && y instanceof Store) {
                return true;
            };
            if (x instanceof Save && y instanceof Save) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    Fetch: Fetch,
    Store: Store,
    Save: Save,
    eqRequest: eqRequest
};
