// Generated by purs version 0.14.4
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Grammar = require("../Data.Grammar/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Lambda_Language_Snapshot_Tag = require("../Lambda.Language.Snapshot.Tag/index.js");
var PayloadOutOfRange = (function () {
    function PayloadOutOfRange(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PayloadOutOfRange.create = function (value0) {
        return function (value1) {
            return new PayloadOutOfRange(value0, value1);
        };
    };
    return PayloadOutOfRange;
})();
var UnrecognizedTag = (function () {
    function UnrecognizedTag(value0) {
        this.value0 = value0;
    };
    UnrecognizedTag.create = function (value0) {
        return new UnrecognizedTag(value0);
    };
    return UnrecognizedTag;
})();
var ExtraStackValues = (function () {
    function ExtraStackValues(value0) {
        this.value0 = value0;
    };
    ExtraStackValues.create = function (value0) {
        return new ExtraStackValues(value0);
    };
    return ExtraStackValues;
})();
var StackUnderflow = (function () {
    function StackUnderflow(value0) {
        this.value0 = value0;
    };
    StackUnderflow.create = function (value0) {
        return new StackUnderflow(value0);
    };
    return StackUnderflow;
})();
var IndexOutOfRange = (function () {
    function IndexOutOfRange(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    IndexOutOfRange.create = function (value0) {
        return function (value1) {
            return new IndexOutOfRange(value0, value1);
        };
    };
    return IndexOutOfRange;
})();
var prettyError = {
    pretty: function (rep) {
        return function (v) {
            if (v instanceof PayloadOutOfRange) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Lambda_Language_Pretty.monoidBuilder)([ Lambda_Language_Pretty.text("payload "), Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v.value1)), Lambda_Language_Pretty.text(" for tag "), Lambda_Language_Pretty.pretty(Lambda_Language_Snapshot_Tag.prettyTag)(rep)(v.value0), Lambda_Language_Pretty.text(" out of range for 29 bits") ]);
            };
            if (v instanceof UnrecognizedTag) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Lambda_Language_Pretty.monoidBuilder)([ Lambda_Language_Pretty.text("unrecognized tag "), Lambda_Language_Pretty.pretty(Lambda_Language_Snapshot_Tag.prettyTag)(rep)(v.value0) ]);
            };
            if (v instanceof ExtraStackValues) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Lambda_Language_Pretty.monoidBuilder)([ Lambda_Language_Pretty.text("malformed snapshot; "), Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v.value0)), Lambda_Language_Pretty.text(" extra expressions left on stack") ]);
            };
            if (v instanceof StackUnderflow) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Lambda_Language_Pretty.monoidBuilder)([ Lambda_Language_Pretty.text("stack underflow for "), Lambda_Language_Pretty.text(v.value0.op), Lambda_Language_Pretty.text("; wanted "), Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v.value0.wanted)), Lambda_Language_Pretty.text(Data_Grammar.pluralizeWith("s")(v.value0.wanted)("item")), Lambda_Language_Pretty.text(", saw "), Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v.value0.saw)) ]);
            };
            if (v instanceof IndexOutOfRange) {
                return Data_Foldable.fold(Data_Foldable.foldableArray)(Lambda_Language_Pretty.monoidBuilder)([ Lambda_Language_Pretty.text("index "), Lambda_Language_Pretty.text(Data_Show.show(Data_Show.showInt)(v.value0)), Lambda_Language_Pretty.text(" out of range for symbol table ["), Data_Array.intercalate(Lambda_Language_Pretty.monoidBuilder)(Lambda_Language_Pretty.text(", "))(Data_Functor.map(Data_Functor.functorArray)(Lambda_Language_Pretty.pretty(Lambda_Language_Name.prettyName)(rep))(v.value1)), Lambda_Language_Pretty.text("]") ]);
            };
            throw new Error("Failed pattern match at Lambda.Language.Snapshot.Error (line 33, column 16 - line 65, column 8): " + [ v.constructor.name ]);
        };
    }
};
var genericError = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new PayloadOutOfRange(x.value0.value0, x.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new UnrecognizedTag(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new ExtraStackValues(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new StackUnderflow(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new IndexOutOfRange(x.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value1);
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot.Error (line 27, column 1 - line 27, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof PayloadOutOfRange) {
            return new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1));
        };
        if (x instanceof UnrecognizedTag) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof ExtraStackValues) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof StackUnderflow) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof IndexOutOfRange) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1)))));
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot.Error (line 27, column 1 - line 27, column 48): " + [ x.constructor.name ]);
    }
};
var showError = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericError)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Snapshot_Tag.showTag))(Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt)))({
            reflectSymbol: function () {
                return "PayloadOutOfRange";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Snapshot_Tag.showTag))({
            reflectSymbol: function () {
                return "UnrecognizedTag";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt))({
            reflectSymbol: function () {
                return "ExtraStackValues";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "op";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "saw";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "wanted";
            }
        })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showString))))({
            reflectSymbol: function () {
                return "StackUnderflow";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsProduct(Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt))(Data_Show_Generic.genericShowArgsArgument(Data_Show.showArray(Lambda_Language_Name.showName))))({
            reflectSymbol: function () {
                return "IndexOutOfRange";
            }
        }))))))(x);
    }
};
var eqError = {
    eq: function (x) {
        return function (y) {
            if (x instanceof PayloadOutOfRange && y instanceof PayloadOutOfRange) {
                return Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(x.value0)(y.value0) && x.value1 === y.value1;
            };
            if (x instanceof UnrecognizedTag && y instanceof UnrecognizedTag) {
                return Data_Eq.eq(Lambda_Language_Snapshot_Tag.eqTag)(x.value0)(y.value0);
            };
            if (x instanceof ExtraStackValues && y instanceof ExtraStackValues) {
                return x.value0 === y.value0;
            };
            if (x instanceof StackUnderflow && y instanceof StackUnderflow) {
                return x.value0.op === y.value0.op && x.value0.saw === y.value0.saw && x.value0.wanted === y.value0.wanted;
            };
            if (x instanceof IndexOutOfRange && y instanceof IndexOutOfRange) {
                return x.value0 === y.value0 && Data_Eq.eq(Data_Eq.eqArray(Lambda_Language_Name.eqName))(x.value1)(y.value1);
            };
            return false;
        };
    }
};
module.exports = {
    PayloadOutOfRange: PayloadOutOfRange,
    UnrecognizedTag: UnrecognizedTag,
    ExtraStackValues: ExtraStackValues,
    StackUnderflow: StackUnderflow,
    IndexOutOfRange: IndexOutOfRange,
    eqError: eqError,
    genericError: genericError,
    showError: showError,
    prettyError: prettyError
};
