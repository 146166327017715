// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_ST_Internal = require("../Control.Monad.ST.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var withArray = function (f) {
    return function (xs) {
        return function __do() {
            var result = $foreign.thaw(xs)();
            f(result)();
            return $foreign.unsafeFreeze(result)();
        };
    };
};
var unshift = function (a) {
    return $foreign.unshiftAll([ a ]);
};
var sortBy = function (comp) {
    return $foreign.sortByImpl(comp)(function (v) {
        if (v instanceof Data_Ordering.GT) {
            return 1;
        };
        if (v instanceof Data_Ordering.EQ) {
            return 0;
        };
        if (v instanceof Data_Ordering.LT) {
            return -1 | 0;
        };
        throw new Error("Failed pattern match at Data.Array.ST (line 113, column 31 - line 116, column 11): " + [ v.constructor.name ]);
    });
};
var sortWith = function (dictOrd) {
    return function (f) {
        return sortBy(Data_Ord.comparing(dictOrd)(f));
    };
};
var sort = function (dictOrd) {
    return sortBy(Data_Ord.compare(dictOrd));
};
var shift = $foreign.shiftImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var run = function (st) {
    return Control_Bind.bind(Control_Monad_ST_Internal.bindST)(st)($foreign.unsafeFreeze)();
};
var push = function (a) {
    return $foreign.pushAll([ a ]);
};
var pop = $foreign.popImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var peek = $foreign.peekImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var modify = function (i) {
    return function (f) {
        return function (xs) {
            return function __do() {
                var entry = peek(i)(xs)();
                if (entry instanceof Data_Maybe.Just) {
                    return $foreign.poke(i)(f(entry.value0))(xs)();
                };
                if (entry instanceof Data_Maybe.Nothing) {
                    return false;
                };
                throw new Error("Failed pattern match at Data.Array.ST (line 198, column 3 - line 200, column 26): " + [ entry.constructor.name ]);
            };
        };
    };
};
var empty = function (dictWarn) {
    return $foreign["new"];
};
module.exports = {
    run: run,
    withArray: withArray,
    empty: empty,
    peek: peek,
    modify: modify,
    pop: pop,
    push: push,
    shift: shift,
    unshift: unshift,
    sort: sort,
    sortBy: sortBy,
    sortWith: sortWith,
    "new": $foreign["new"],
    poke: $foreign.poke,
    pushAll: $foreign.pushAll,
    unshiftAll: $foreign.unshiftAll,
    splice: $foreign.splice,
    freeze: $foreign.freeze,
    thaw: $foreign.thaw,
    unsafeFreeze: $foreign.unsafeFreeze,
    unsafeThaw: $foreign.unsafeThaw,
    toAssocArray: $foreign.toAssocArray
};
