// Generated by purs version 0.14.4
"use strict";
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var component = function (v) {
    var v1 = Lambda_Language_Parser.formatParseError(v.input)(v.error);
    return React_Basic.fragment([ React_Basic_DOM_Generated.p_([ React_Basic_DOM.text(v1.message) ]), React_Basic_DOM_Generated.p()({
        className: "preformatted",
        children: [ React_Basic_DOM.text(v1.source + ("\x0a" + v1.caret)) ]
    }) ]);
};
module.exports = {
    component: component
};
