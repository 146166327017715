// Generated by purs version 0.14.4
"use strict";
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Replacement = function (x) {
    return x;
};
var Pattern = function (x) {
    return x;
};
var showReplacement = {
    show: function (v) {
        return "(Replacement " + (Data_Show.show(Data_Show.showString)(v) + ")");
    }
};
var showPattern = {
    show: function (v) {
        return "(Pattern " + (Data_Show.show(Data_Show.showString)(v) + ")");
    }
};
var newtypeReplacement = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypePattern = {
    Coercible0: function () {
        return undefined;
    }
};
var eqReplacement = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordReplacement = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordString)(x)(y);
        };
    },
    Eq0: function () {
        return eqReplacement;
    }
};
var eqPattern = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordPattern = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordString)(x)(y);
        };
    },
    Eq0: function () {
        return eqPattern;
    }
};
module.exports = {
    Pattern: Pattern,
    Replacement: Replacement,
    eqPattern: eqPattern,
    ordPattern: ordPattern,
    newtypePattern: newtypePattern,
    showPattern: showPattern,
    eqReplacement: eqReplacement,
    ordReplacement: ordReplacement,
    newtypeReplacement: newtypeReplacement,
    showReplacement: showReplacement
};
