// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var React_Portal = require("../React.Portal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var $$new = function __do() {
    var portal = React_Portal["new"]();
    return React_Basic_Hooks.component("Overlay")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(portal({
            children: [ React_Basic_DOM_Generated.div()({
                className: "overlay",
                children: [ React_Basic_DOM_Generated.div()({
                    className: "overlay-item",
                    children: v.children
                }) ],
                onClick: React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(Data_Function["const"](Data_Foldable.sequence_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v.dismiss)))
            }) ]
        }));
    })();
};
module.exports = {
    "new": $$new
};
