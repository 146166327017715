// Generated by purs version 0.14.4
"use strict";
var Control_Extend = require("../Control.Extend/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var extract = function (dict) {
    return dict.extract;
};
module.exports = {
    extract: extract,
    duplicate: Control_Extend.duplicate,
    extend: Control_Extend.extend,
    map: Data_Functor.map,
    "void": Data_Functor["void"]
};
