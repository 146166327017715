// Generated by purs version 0.14.4
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Lambda_Language_Definition = require("../Lambda.Language.Definition/index.js");
var Lambda_Language_Expression = require("../Lambda.Language.Expression/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Define = (function () {
    function Define(value0) {
        this.value0 = value0;
    };
    Define.create = function (value0) {
        return new Define(value0);
    };
    return Define;
})();
var Eval = (function () {
    function Eval(value0) {
        this.value0 = value0;
    };
    Eval.create = function (value0) {
        return new Eval(value0);
    };
    return Eval;
})();
var prettyStatement = {
    pretty: function (rep) {
        return function (v) {
            if (v instanceof Define) {
                return Lambda_Language_Pretty.pretty(Lambda_Language_Definition.prettyDefinition)(rep)(v.value0);
            };
            if (v instanceof Eval) {
                return Lambda_Language_Pretty.pretty(Lambda_Language_Expression.prettyExpression)(rep)(v.value0);
            };
            throw new Error("Failed pattern match at Lambda.Language.Statement (line 26, column 16 - line 28, column 33): " + [ v.constructor.name ]);
        };
    }
};
var parseStatement = {
    parse: Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_Combinators["try"](Data_Identity.monadIdentity)(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Define.create)(Lambda_Language_Parser.parse(Lambda_Language_Definition.parseDefinition))))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Eval.create)(Lambda_Language_Parser.parse(Lambda_Language_Expression.parseExpression)))
};
var genericStatement = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Define(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new Eval(x.value0);
        };
        throw new Error("Failed pattern match at Lambda.Language.Statement (line 20, column 1 - line 20, column 56): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Define) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Eval) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Lambda.Language.Statement (line 20, column 1 - line 20, column 56): " + [ x.constructor.name ]);
    }
};
var showStatement = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericStatement)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Definition.showDefinition))({
            reflectSymbol: function () {
                return "Define";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Expression.showExpression))({
            reflectSymbol: function () {
                return "Eval";
            }
        })))(x);
    }
};
var eqStatement = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Define && y instanceof Define) {
                return Data_Eq.eq(Lambda_Language_Definition.eqDefinition)(x.value0)(y.value0);
            };
            if (x instanceof Eval && y instanceof Eval) {
                return Data_Eq.eq(Lambda_Language_Expression.eqExpression)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
module.exports = {
    Define: Define,
    Eval: Eval,
    eqStatement: eqStatement,
    genericStatement: genericStatement,
    showStatement: showStatement,
    prettyStatement: prettyStatement,
    parseStatement: parseStatement
};
