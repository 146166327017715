// Generated by purs version 0.14.4
"use strict";
var Components_Level = require("../Components.Level/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var component = function (v) {
    return React_Basic_DOM_Generated.div()({
        className: "alert alert-dismissable alert-" + Data_Show.show(Components_Level.showLevel)(v.level),
        children: [ React_Basic_DOM_Generated.button()({
            type: "button",
            onClick: React_Basic_Events.handler_(v.dismiss),
            className: "close",
            children: [ React_Basic_DOM_Generated.span()({
                className: "cursor-pointer glyphicon glyphicon-remove pull-right"
            }) ]
        }), v.child ]
    });
};
module.exports = {
    component: component
};
