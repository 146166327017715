// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Cont_Class = require("../Control.Monad.Cont.Class/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var ContT = function (x) {
    return x;
};
var withContT = function (f) {
    return function (v) {
        return function (k) {
            return v(f(k));
        };
    };
};
var runContT = function (v) {
    return function (k) {
        return v(k);
    };
};
var newtypeContT = {
    Coercible0: function () {
        return undefined;
    }
};
var monadTransContT = {
    lift: function (dictMonad) {
        return function (m) {
            return function (k) {
                return Control_Bind.bind(dictMonad.Bind1())(m)(k);
            };
        };
    }
};
var mapContT = function (f) {
    return function (v) {
        return function (k) {
            return f(v(k));
        };
    };
};
var functorContT = function (dictFunctor) {
    return {
        map: function (f) {
            return function (v) {
                return function (k) {
                    return v(function (a) {
                        return k(f(a));
                    });
                };
            };
        }
    };
};
var applyContT = function (dictApply) {
    return {
        apply: function (v) {
            return function (v1) {
                return function (k) {
                    return v(function (g) {
                        return v1(function (a) {
                            return k(g(a));
                        });
                    });
                };
            };
        },
        Functor0: function () {
            return functorContT(dictApply.Functor0());
        }
    };
};
var bindContT = function (dictBind) {
    return {
        bind: function (v) {
            return function (k) {
                return function (k$prime) {
                    return v(function (a) {
                        var v1 = k(a);
                        return v1(k$prime);
                    });
                };
            };
        },
        Apply0: function () {
            return applyContT(dictBind.Apply0());
        }
    };
};
var semigroupContT = function (dictApply) {
    return function (dictSemigroup) {
        return {
            append: Control_Apply.lift2(applyContT(dictApply))(Data_Semigroup.append(dictSemigroup))
        };
    };
};
var applicativeContT = function (dictApplicative) {
    return {
        pure: function (a) {
            return function (k) {
                return k(a);
            };
        },
        Apply0: function () {
            return applyContT(dictApplicative.Apply0());
        }
    };
};
var monadContT = function (dictMonad) {
    return {
        Applicative0: function () {
            return applicativeContT(dictMonad.Applicative0());
        },
        Bind1: function () {
            return bindContT(dictMonad.Bind1());
        }
    };
};
var monadAskContT = function (dictMonadAsk) {
    return {
        ask: Control_Monad_Trans_Class.lift(monadTransContT)(dictMonadAsk.Monad0())(Control_Monad_Reader_Class.ask(dictMonadAsk)),
        Monad0: function () {
            return monadContT(dictMonadAsk.Monad0());
        }
    };
};
var monadReaderContT = function (dictMonadReader) {
    return {
        local: function (f) {
            return function (v) {
                return function (k) {
                    return Control_Bind.bind(((dictMonadReader.MonadAsk0()).Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadReader.MonadAsk0()))(function (r) {
                        return Control_Monad_Reader_Class.local(dictMonadReader)(f)(v((function () {
                            var $44 = Control_Monad_Reader_Class.local(dictMonadReader)(Data_Function["const"](r));
                            return function ($45) {
                                return $44(k($45));
                            };
                        })()));
                    });
                };
            };
        },
        MonadAsk0: function () {
            return monadAskContT(dictMonadReader.MonadAsk0());
        }
    };
};
var monadContContT = function (dictMonad) {
    return {
        callCC: function (f) {
            return function (k) {
                var v = f(function (a) {
                    return function (v1) {
                        return k(a);
                    };
                });
                return v(k);
            };
        },
        Monad0: function () {
            return monadContT(dictMonad);
        }
    };
};
var monadEffectContT = function (dictMonadEffect) {
    return {
        liftEffect: (function () {
            var $46 = Control_Monad_Trans_Class.lift(monadTransContT)(dictMonadEffect.Monad0());
            var $47 = Effect_Class.liftEffect(dictMonadEffect);
            return function ($48) {
                return $46($47($48));
            };
        })(),
        Monad0: function () {
            return monadContT(dictMonadEffect.Monad0());
        }
    };
};
var monadStateContT = function (dictMonadState) {
    return {
        state: (function () {
            var $49 = Control_Monad_Trans_Class.lift(monadTransContT)(dictMonadState.Monad0());
            var $50 = Control_Monad_State_Class.state(dictMonadState);
            return function ($51) {
                return $49($50($51));
            };
        })(),
        Monad0: function () {
            return monadContT(dictMonadState.Monad0());
        }
    };
};
var monoidContT = function (dictApplicative) {
    return function (dictMonoid) {
        return {
            mempty: Control_Applicative.pure(applicativeContT(dictApplicative))(Data_Monoid.mempty(dictMonoid)),
            Semigroup0: function () {
                return semigroupContT(dictApplicative.Apply0())(dictMonoid.Semigroup0());
            }
        };
    };
};
module.exports = {
    ContT: ContT,
    runContT: runContT,
    mapContT: mapContT,
    withContT: withContT,
    newtypeContT: newtypeContT,
    monadContContT: monadContContT,
    functorContT: functorContT,
    applyContT: applyContT,
    applicativeContT: applicativeContT,
    bindContT: bindContT,
    monadContT: monadContT,
    monadTransContT: monadTransContT,
    monadEffectContT: monadEffectContT,
    monadAskContT: monadAskContT,
    monadReaderContT: monadReaderContT,
    monadStateContT: monadStateContT,
    semigroupContT: semigroupContT,
    monoidContT: monoidContT,
    callCC: Control_Monad_Cont_Class.callCC,
    lift: Control_Monad_Trans_Class.lift
};
