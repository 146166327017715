// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Lambda_Language_Expression = require("../Lambda.Language.Expression/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var Definition = function (x) {
    return x;
};
var split = function (v) {
    return {
        id: v.id,
        name: v.name,
        expr: Data_Foldable.foldr(Data_Foldable.foldableArray)(Lambda_Language_Expression.Lambda.create)(v.expr)(v.args)
    };
};
var showDefinition = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "args";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "expr";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Show.showRecordFieldsNil)(Lambda_Language_Name.showName))(Data_Show.showInt))(Lambda_Language_Expression.showExpression))(Data_Show.showArray(Lambda_Language_Name.showName)));
var prettyDefinition = {
    pretty: function (rep) {
        return function (v) {
            var prefix = Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ Data_Show.show(Lambda_Language_Name.showName)(v.name), " ", Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidArray)([ Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Lambda_Language_Name.showName))(v.args), [ "= " ] ])) ]);
            return Data_Semigroup.append(Lambda_Language_Pretty.semigroupBuilder)(Lambda_Language_Pretty.text(prefix))(Lambda_Language_Pretty.pretty(Lambda_Language_Expression.prettyExpression)(rep)(v.expr));
        };
    }
};
var parseDefinition = {
    parse: Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Lambda_Language_Parser.parse(Lambda_Language_Name.parseName))(function (name) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Lambda_Language_Parser.parse(Lambda_Language_Name.parseName)))(function (args) {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(Lambda_Language_Parser.token(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("=")))(Lambda_Language_Parser.parse(Lambda_Language_Expression.parseExpression)))(function (expr) {
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))({
                    id: 0,
                    name: name,
                    args: args,
                    expr: expr
                });
            });
        });
    })
};
var newtypeDefinition = {
    Coercible0: function () {
        return undefined;
    }
};
var join = function (name) {
    var go = function ($copy_args) {
        return function ($copy_v) {
            var $tco_var_args = $copy_args;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(args, v) {
                if (v instanceof Lambda_Language_Expression.Lambda) {
                    $tco_var_args = Data_Semigroup.append(Data_Semigroup.semigroupArray)(args)([ v.value0 ]);
                    $copy_v = v.value1;
                    return;
                };
                $tco_done = true;
                return {
                    id: 0,
                    name: name,
                    args: args,
                    expr: v
                };
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_args, $copy_v);
            };
            return $tco_result;
        };
    };
    var $16 = go([  ]);
    return function ($17) {
        return Definition($16($17));
    };
};
var eqDefinition = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "name";
    }
})(Lambda_Language_Name.eqName))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "expr";
    }
})(Lambda_Language_Expression.eqExpression))()({
    reflectSymbol: function () {
        return "args";
    }
})(Data_Eq.eqArray(Lambda_Language_Name.eqName)));
module.exports = {
    Definition: Definition,
    split: split,
    join: join,
    eqDefinition: eqDefinition,
    showDefinition: showDefinition,
    newtypeDefinition: newtypeDefinition,
    prettyDefinition: prettyDefinition,
    parseDefinition: parseDefinition
};
