// Generated by purs version 0.14.4
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary/index.js");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen/index.js");
var Queue = function (x) {
    return x;
};
var valid = function (v) {
    return !Data_List["null"](v.front) || Data_List["null"](v.back);
};
var toList = function (v) {
    return Data_Semigroup.append(Data_List_Types.semigroupList)(v.front)(Data_List.reverse(v.back));
};
var toUnfoldable = function (dictUnfoldable) {
    var $35 = Data_List.toUnfoldable(dictUnfoldable);
    return function ($36) {
        return $35(toList($36));
    };
};
var queue = function (front) {
    return function (back) {
        if (front instanceof Data_List_Types.Nil) {
            return {
                front: Data_List.reverse(back),
                back: Data_List_Types.Nil.value
            };
        };
        return {
            front: front,
            back: back
        };
    };
};
var singleton = function (a) {
    return queue(new Data_List_Types.Cons(a, Data_List_Types.Nil.value))(Data_List_Types.Nil.value);
};
var push = function (v) {
    return function (x) {
        return queue(v.front)(new Data_List_Types.Cons(x, v.back));
    };
};
var pop = function (v) {
    if (v.front instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v.front instanceof Data_List_Types.Cons) {
        return Data_Maybe.Just.create(Data_Tuple.Tuple.create(v.front.value0)(queue(v.front.value1)(v.back)));
    };
    throw new Error("Failed pattern match at Data.Queue (line 76, column 29 - line 78, column 46): " + [ v.front.constructor.name ]);
};
var functorQueue = {
    map: function (f) {
        return function (v) {
            return queue(Data_Functor.map(Data_List_Types.functorList)(f)(v.front))(Data_Functor.map(Data_List_Types.functorList)(f)(v.back));
        };
    }
};
var fromFoldable = function (dictFoldable) {
    var $37 = Data_Function.flip(queue)(Data_List_Types.Nil.value);
    var $38 = Data_List.fromFoldable(dictFoldable);
    return function ($39) {
        return $37($38($39));
    };
};
var foldableQueue = {
    foldr: function (f) {
        return function (z) {
            var $40 = Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z);
            return function ($41) {
                return $40(toList($41));
            };
        };
    },
    foldl: function (f) {
        return function (z) {
            var $42 = Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z);
            return function ($43) {
                return $42(toList($43));
            };
        };
    },
    foldMap: function (dictMonoid) {
        return function (f) {
            var $44 = Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f);
            return function ($45) {
                return $44(toList($45));
            };
        };
    }
};
var showQueue = function (dictShow) {
    return {
        show: function (q) {
            return "fromFoldable " + Data_Show.show(Data_Show.showArray(dictShow))(Data_Array.fromFoldable(foldableQueue)(q));
        }
    };
};
var traversableQueue = {
    traverse: function (dictApplicative) {
        return function (f) {
            var $46 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Function.flip(queue)(Data_List_Types.Nil.value));
            var $47 = Data_Traversable.traverse(Data_List_Types.traversableList)(dictApplicative)(f);
            return function ($48) {
                return $46($47(toList($48)));
            };
        };
    },
    sequence: function (dictApplicative) {
        return Data_Traversable.sequenceDefault(traversableQueue)(dictApplicative);
    },
    Functor0: function () {
        return functorQueue;
    },
    Foldable1: function () {
        return foldableQueue;
    }
};
var extend = function (dictFoldable) {
    return Data_Foldable.foldl(dictFoldable)(push);
};
var eqQueue = function (dictEq) {
    return {
        eq: function (lhs) {
            return function (rhs) {
                return Data_Eq.eq(Data_List_Types.eqList(dictEq))(toList(lhs))(toList(rhs));
            };
        }
    };
};
var empty = queue(Data_List_Types.Nil.value)(Data_List_Types.Nil.value);
var arbitraryQueue = function (dictArbitrary) {
    return {
        arbitrary: Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(queue)(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbitraryList(dictArbitrary))))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbitraryList(dictArbitrary)))
    };
};
module.exports = {
    fromFoldable: fromFoldable,
    toUnfoldable: toUnfoldable,
    empty: empty,
    singleton: singleton,
    pop: pop,
    push: push,
    extend: extend,
    valid: valid,
    showQueue: showQueue,
    eqQueue: eqQueue,
    functorQueue: functorQueue,
    foldableQueue: foldableQueue,
    traversableQueue: traversableQueue,
    arbitraryQueue: arbitraryQueue
};
