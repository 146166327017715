// Generated by purs version 0.14.4
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Char = require("../Data.Char/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Hashable = require("../Data.Hashable/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Lambda_Language_Parser = require("../Lambda.Language.Parser/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var Name = (function () {
    function Name(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Name.create = function (value0) {
        return function (value1) {
            return new Name(value0, value1);
        };
    };
    return Name;
})();
var withSubscript = function (n) {
    return function (s) {
        return Name.create(s)(new Data_Maybe.Just(n));
    };
};
var toDigits = function (n) {
    if (n < 10) {
        return [ n ];
    };
    if (Data_Boolean.otherwise) {
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(toDigits(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(n)(10)))([ Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(n)(10) ]);
    };
    throw new Error("Failed pattern match at Lambda.Language.Name (line 134, column 1 - line 134, column 29): " + [ n.constructor.name ]);
};
var subscriptToInt = (function () {
    var step = function (acc) {
        return function (d) {
            return (10 * acc | 0) + d | 0;
        };
    };
    return Data_Foldable.foldl(Data_Foldable.foldableArray)(step)(0);
})();
var subscriptTable = [ "\u2080", "\u2081", "\u2082", "\u2083", "\u2084", "\u2085", "\u2086", "\u2087", "\u2088", "\u2089" ];
var offsetFrom = function (lo) {
    return function (hi) {
        var inRange = function (c) {
            return lo <= c && c <= hi;
        };
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(inRange))(function (c) {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(Data_Char.toCharCode(c) - Data_Char.toCharCode(lo) | 0);
        });
    };
};
var next = function (v) {
    return Name.create(v.value0)(Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
        return v1 + 1 | 0;
    })(v.value1))(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(0)));
};
var isLower = function (c) {
    return "a" <= c && c <= "z";
};
var intToSubscript = (function () {
    var $48 = Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return subscriptTable[v];
    });
    return function ($49) {
        return Data_String_CodeUnits.fromCharArray($48(toDigits($49)));
    };
})();
var showName = {
    show: function (v) {
        return v.value0 + Data_Maybe.maybe("")(intToSubscript)(v.value1);
    }
};
var prettyName = {
    pretty: function (v) {
        var $50 = Data_Show.show(showName);
        return function ($51) {
            return Lambda_Language_Pretty.text($50($51));
        };
    }
};
var from = function (s) {
    return new Name(s, Data_Maybe.Nothing.value);
};
var firstChar = function (c) {
    return isLower(c) || c === "_";
};
var eqName = {
    eq: function (x) {
        return function (y) {
            return x.value0 === y.value0 && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(x.value1)(y.value1);
        };
    }
};
var hashableName = {
    hash: function (v) {
        return Data_Hashable.hash(Data_Hashable.hashableTuple(Data_Hashable.hashableString)(Data_Hashable.hashableMaybe(Data_Hashable.hashableInt)))(new Data_Tuple.Tuple(v.value0, v.value1));
    },
    Eq0: function () {
        return eqName;
    }
};
var ordName = {
    compare: function (x) {
        return function (y) {
            var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Maybe.ordMaybe(Data_Ord.ordInt))(x.value1)(y.value1);
        };
    },
    Eq0: function () {
        return eqName;
    }
};
var encodeJsonName = {
    encodeJson: (function () {
        var $52 = Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonJString);
        var $53 = Data_Show.show(showName);
        return function ($54) {
            return $52($53($54));
        };
    })()
};
var bodyChar = function (c) {
    return isLower(c) || c === "-";
};
var base = function (v) {
    return v.value0;
};
var anyDigit = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(offsetFrom("0")("9"))(offsetFrom("\u2080")("\u2089"));
var parseSubscript = Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(subscriptToInt)(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(anyDigit));
var parseName = {
    parse: Lambda_Language_Parser.token(Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(firstChar))(function (first) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(bodyChar)))(function (body) {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("?"))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))("")))(function (question) {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(parseSubscript))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value)))(function (subscript) {
                    var $$var = Data_String_CodeUnits.fromCharArray(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ first ])(body)) + question;
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(new Name($$var, subscript));
                });
            });
        });
    }))
};
var decodeJsonName = {
    decodeJson: Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Lambda_Language_Parser.liftJson(Lambda_Language_Parser.parse(parseName)))(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeJsonString))
};
module.exports = {
    from: from,
    withSubscript: withSubscript,
    base: base,
    next: next,
    eqName: eqName,
    ordName: ordName,
    showName: showName,
    hashableName: hashableName,
    decodeJsonName: decodeJsonName,
    encodeJsonName: encodeJsonName,
    prettyName: prettyName,
    parseName: parseName
};
