// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_State = require("../Control.Monad.State/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Lambda_Language_Definition = require("../Lambda.Language.Definition/index.js");
var Lambda_Language_Expression = require("../Lambda.Language.Expression/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_Snapshot_Error = require("../Lambda.Language.Snapshot.Error/index.js");
var Lambda_Language_Snapshot_RPN = require("../Lambda.Language.Snapshot.RPN/index.js");
var Lambda_Language_Snapshot_Signature = require("../Lambda.Language.Snapshot.Signature/index.js");
var Lambda_Prelude = require("../Lambda.Prelude/index.js");
var Snapshot = function (x) {
    return x;
};
var take = function (dictMonadThrow) {
    return function (dictMonadState) {
        return function (n) {
            var go = function ($copy_v) {
                return function ($copy_i) {
                    var $tco_var_v = $copy_v;
                    var $tco_done = false;
                    var $tco_result;
                    function $tco_loop(v, i) {
                        if (i <= 0) {
                            $tco_done = true;
                            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())({
                                acc: v.acc,
                                stack: v.stack
                            });
                        };
                        if (Data_Boolean.otherwise) {
                            if (v.stack instanceof Data_List_Types.Nil) {
                                $tco_done = true;
                                return Control_Monad_Error_Class.throwError(dictMonadThrow)(new Lambda_Language_Snapshot_Error.StackUnderflow({
                                    op: "take",
                                    wanted: n,
                                    saw: n - i | 0
                                }));
                            };
                            if (v.stack instanceof Data_List_Types.Cons) {
                                $tco_var_v = {
                                    acc: new Data_List_Types.Cons(v.stack.value0, v.acc),
                                    stack: v.stack.value1
                                };
                                $copy_i = i - 1 | 0;
                                return;
                            };
                            throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 202, column 19 - line 204, column 63): " + [ v.stack.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 200, column 3 - line 204, column 63): " + [ v.constructor.name, i.constructor.name ]);
                    };
                    while (!$tco_done) {
                        $tco_result = $tco_loop($tco_var_v, $copy_i);
                    };
                    return $tco_result;
                };
            };
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(go({
                    acc: Data_List_Types.Nil.value,
                    stack: v.stack
                })(n))(function (v1) {
                    return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (s) {
                        var $45 = {};
                        for (var $46 in s) {
                            if ({}.hasOwnProperty.call(s, $46)) {
                                $45[$46] = s[$46];
                            };
                        };
                        $45.stack = v1.stack;
                        return $45;
                    }))(function () {
                        return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(v1.acc);
                    });
                });
            });
        };
    };
};
var showSnapshot = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "names";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "sig";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "state";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showInt)))(Lambda_Language_Snapshot_Signature.showSignature))(Data_Show.showArray(Lambda_Language_Name.showName)));
var save = function (dictMonadState) {
    return function (name) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
            var v1 = Data_Map_Internal.lookup(Lambda_Language_Name.ordName)(name)(v.cache);
            if (v1 instanceof Data_Maybe.Just) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(v1.value0);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                var i = Data_Array.length(v.names);
                return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (s) {
                    var $54 = {};
                    for (var $55 in s) {
                        if ({}.hasOwnProperty.call(s, $55)) {
                            $54[$55] = s[$55];
                        };
                    };
                    $54.names = Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.names)([ name ]);
                    $54.cache = Data_Map_Internal.insert(Lambda_Language_Name.ordName)(name)(i)(v.cache);
                    return $54;
                }))(function () {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(i);
                });
            };
            throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 119, column 3 - line 127, column 13): " + [ v1.constructor.name ]);
        });
    };
};
var push = function (dictMonadState) {
    return function (e) {
        return Control_Monad_State_Class.modify_(dictMonadState)(function (s) {
            var $59 = {};
            for (var $60 in s) {
                if ({}.hasOwnProperty.call(s, $60)) {
                    $59[$60] = s[$60];
                };
            };
            $59.stack = new Data_List_Types.Cons(e, s.stack);
            return $59;
        });
    };
};
var pop = function (dictMonadThrow) {
    return function (dictMonadState) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
            if (v.stack instanceof Data_List_Types.Nil) {
                return Control_Monad_Error_Class.throwError(dictMonadThrow)(new Lambda_Language_Snapshot_Error.StackUnderflow({
                    op: "pop",
                    wanted: 1,
                    saw: 0
                }));
            };
            if (v.stack instanceof Data_List_Types.Cons) {
                return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (s) {
                    var $64 = {};
                    for (var $65 in s) {
                        if ({}.hasOwnProperty.call(s, $65)) {
                            $64[$65] = s[$65];
                        };
                    };
                    $64.stack = v.stack.value1;
                    return $64;
                }))(function () {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(v.stack.value0);
                });
            };
            throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 176, column 3 - line 180, column 13): " + [ v.stack.constructor.name ]);
        });
    };
};
var newtypeSnapshot = {
    Coercible0: function () {
        return undefined;
    }
};
var flattenExpr = function (dictMonadState) {
    return function (v) {
        if (v instanceof Lambda_Language_Expression.Var) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(save(dictMonadState)(v.value0))(function (i) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())([ new Lambda_Language_Snapshot_RPN.Var(i) ]);
            });
        };
        if (v instanceof Lambda_Language_Expression.Nat) {
            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())([ new Lambda_Language_Snapshot_RPN.Nat(v.value0) ]);
        };
        if (v instanceof Lambda_Language_Expression.List) {
            var arr = Data_Array.fromFoldable(Data_List_Types.foldableList)(v.value0);
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Data_Traversable.traverse(Data_Traversable.traversableArray)((dictMonadState.Monad0()).Applicative0())(flattenExpr(dictMonadState))(arr))(function (ys) {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Array.concat(ys))([ Lambda_Language_Snapshot_RPN.Take.create(Data_Array.length(arr)) ]));
            });
        };
        if (v instanceof Lambda_Language_Expression.Apply && v.value0 instanceof Lambda_Language_Expression.Var) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(save(dictMonadState)(v.value0.value0))(function (i) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(flattenExpr(dictMonadState)(v.value1))(function (flatArg) {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Semigroup.append(Data_Semigroup.semigroupArray)(flatArg)([ new Lambda_Language_Snapshot_RPN.AppVar(i) ]));
                });
            });
        };
        if (v instanceof Lambda_Language_Expression.Apply) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(flattenExpr(dictMonadState)(v.value1))(function (flatArg) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(flattenExpr(dictMonadState)(v.value0))(function (flatFun) {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Semigroup.append(Data_Semigroup.semigroupArray)(flatArg)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(flatFun)([ Lambda_Language_Snapshot_RPN.Apply.value ])));
                });
            });
        };
        if (v instanceof Lambda_Language_Expression.Lambda) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(save(dictMonadState)(v.value0))(function (i) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(flattenExpr(dictMonadState)(v.value1))(function (flatBody) {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Semigroup.append(Data_Semigroup.semigroupArray)(flatBody)([ new Lambda_Language_Snapshot_RPN.Lambda(i) ]));
                });
            });
        };
        if (v instanceof Lambda_Language_Expression.Highlight) {
            return flattenExpr(dictMonadState)(v.value0);
        };
        if (v instanceof Lambda_Language_Expression.Cycle) {
            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())([  ]);
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 77, column 15 - line 100, column 30): " + [ v.constructor.name ]);
    };
};
var flattenDef = function (dictMonadState) {
    return function (def) {
        var v = Lambda_Language_Definition.split(def);
        if (v.id !== 0) {
            return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())([  ]);
        };
        if (Data_Boolean.otherwise) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(save(dictMonadState)(v.name))(function (i) {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(flattenExpr(dictMonadState)(v.expr))(function (ops) {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ops)([ new Lambda_Language_Snapshot_RPN.Define(i) ]));
                });
            });
        };
        throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 67, column 18 - line 73, column 31): " + [ v.constructor.name ]);
    };
};
var fetch = function (dictMonadThrow) {
    return function (dictMonadState) {
        return function (i) {
            return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
                var v1 = Data_Array.index(v.names)(i);
                if (v1 instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(v1.value0);
                };
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(new Lambda_Language_Snapshot_Error.IndexOutOfRange(i, v.names));
                };
                throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 210, column 3 - line 212, column 52): " + [ v1.constructor.name ]);
            });
        };
    };
};
var eqSnapshot = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "state";
    }
})(Data_Eq.eqArray(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "sig";
    }
})(Lambda_Language_Snapshot_Signature.eqSignature))()({
    reflectSymbol: function () {
        return "names";
    }
})(Data_Eq.eqArray(Lambda_Language_Name.eqName)));
var encodeJsonSnapshot = Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Lambda_Language_Name.encodeJsonName))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Lambda_Language_Snapshot_Signature.encodeJsonSignature)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonInt))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "state";
    }
})())({
    reflectSymbol: function () {
        return "sig";
    }
})())({
    reflectSymbol: function () {
        return "names";
    }
})())();
var define = function (dictMonadState) {
    return function (def) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
            return Control_Monad_State_Class.modify_(dictMonadState)(function (s) {
                var $91 = {};
                for (var $92 in s) {
                    if ({}.hasOwnProperty.call(s, $92)) {
                        $91[$92] = s[$92];
                    };
                };
                $91.defs = Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.defs)([ def ]);
                return $91;
            });
        });
    };
};
var replay = function (dictMonadThrow) {
    return function (dictMonadState) {
        return function (ops) {
            var step = function (v) {
                if (v instanceof Lambda_Language_Snapshot_RPN.Var) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(fetch(dictMonadThrow)(dictMonadState)(v.value0))(function (name) {
                        return push(dictMonadState)(new Lambda_Language_Expression.Var(name));
                    });
                };
                if (v instanceof Lambda_Language_Snapshot_RPN.Nat) {
                    return push(dictMonadState)(new Lambda_Language_Expression.Nat(v.value0));
                };
                if (v instanceof Lambda_Language_Snapshot_RPN.Take) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(take(dictMonadThrow)(dictMonadState)(v.value0))(function (xs) {
                        return push(dictMonadState)(new Lambda_Language_Expression.List(xs));
                    });
                };
                if (v instanceof Lambda_Language_Snapshot_RPN.Lambda) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(pop(dictMonadThrow)(dictMonadState))(function (body) {
                        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(fetch(dictMonadThrow)(dictMonadState)(v.value0))(function (name) {
                            return push(dictMonadState)(new Lambda_Language_Expression.Lambda(name, body));
                        });
                    });
                };
                if (v instanceof Lambda_Language_Snapshot_RPN.Define) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(pop(dictMonadThrow)(dictMonadState))(function (body) {
                        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(fetch(dictMonadThrow)(dictMonadState)(v.value0))(function (name) {
                            return define(dictMonadState)(Lambda_Language_Definition.join(name)(body));
                        });
                    });
                };
                if (v instanceof Lambda_Language_Snapshot_RPN.AppVar) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(pop(dictMonadThrow)(dictMonadState))(function (arg) {
                        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(fetch(dictMonadThrow)(dictMonadState)(v.value0))(function (name) {
                            return push(dictMonadState)(new Lambda_Language_Expression.Apply(new Lambda_Language_Expression.Var(name), arg));
                        });
                    });
                };
                if (v instanceof Lambda_Language_Snapshot_RPN.Apply) {
                    return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(pop(dictMonadThrow)(dictMonadState))(function (f) {
                        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(pop(dictMonadThrow)(dictMonadState))(function (arg) {
                            return push(dictMonadState)(new Lambda_Language_Expression.Apply(f, arg));
                        });
                    });
                };
                throw new Error("Failed pattern match at Lambda.Language.Snapshot (line 139, column 10 - line 163, column 36): " + [ v.constructor.name ]);
            };
            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Data_Foldable.traverse_((dictMonadState.Monad0()).Applicative0())(Data_Foldable.foldableArray)(step)(ops))(function () {
                return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.get(dictMonadState))(function (v) {
                    if (v.stack instanceof Data_List_Types.Nil) {
                        return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())({
                            defs: v.defs,
                            expr: Data_Maybe.Nothing.value
                        });
                    };
                    if (v.stack instanceof Data_List_Types.Cons && v.stack.value1 instanceof Data_List_Types.Nil) {
                        return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())({
                            defs: v.defs,
                            expr: new Data_Maybe.Just(v.stack.value0)
                        });
                    };
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(Lambda_Language_Snapshot_Error.ExtraStackValues.create(Data_Array.length(Data_Array.fromFoldable(Data_List_Types.foldableList)(v.stack))));
                });
            });
        };
    };
};
var load = function (v) {
    var withPrelude = function (program) {
        return {
            defs: Data_Semigroup.append(Data_Semigroup.semigroupArray)(Lambda_Language_Snapshot_Signature.inflate(v.sig))(program.defs),
            expr: program.expr
        };
    };
    var start = {
        stack: Data_List_Types.Nil.value,
        defs: [  ],
        names: v.names
    };
    var act = Control_Bind.bindFlipped(Control_Monad_State_Trans.bindStateT(Control_Monad_Except_Trans.monadExceptT(Data_Identity.monadIdentity)))(replay(Control_Monad_State_Trans.monadThrowStateT(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity)))(Control_Monad_State_Trans.monadStateStateT(Control_Monad_Except_Trans.monadExceptT(Data_Identity.monadIdentity))))(Lambda_Language_Snapshot_RPN.decode(Control_Monad_State_Trans.monadThrowStateT(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity)))(v.state));
    return Data_Functor.map(Data_Either.functorEither)(withPrelude)(Lambda_Prelude.runIdentity(Control_Monad_Except_Trans.runExceptT(Control_Monad_State_Trans.evalStateT(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(act)(start))));
};
var decodeJsonSnapshot = Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Lambda_Language_Name.decodeJsonName)))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Lambda_Language_Snapshot_Signature.decodeJsonSignature))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeJsonInt)))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
    reflectSymbol: function () {
        return "state";
    }
})()())({
    reflectSymbol: function () {
        return "sig";
    }
})()())({
    reflectSymbol: function () {
        return "names";
    }
})()())();
var collect = function (act) {
    var dict = {
        names: [  ],
        cache: Data_Map_Internal.empty
    };
    var v = Control_Monad_State.runState(act)(dict);
    return new Data_Tuple.Tuple(v.value1.names, v.value0);
};
var flatten = function (defs) {
    return function (expr) {
        return collect(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(flattenDef(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(defs))(function (flatDefs) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(flattenExpr(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(expr))(function (flatExpr) {
                return Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Array.concat(flatDefs))(Data_Maybe.fromMaybe([  ])(flatExpr)));
            });
        }));
    };
};
var $$new = function (v) {
    var sig = Lambda_Language_Snapshot_Signature.deflate(v.defs);
    var v1 = flatten(v.defs)(v.expr);
    return Lambda_Prelude.runIdentity(Control_Monad_Except_Trans.runExceptT(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Lambda_Language_Snapshot_RPN.encode(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(v1.value1))(function (state) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
            sig: sig,
            names: v1.value0,
            state: state
        });
    })));
};
module.exports = {
    Snapshot: Snapshot,
    "new": $$new,
    load: load,
    newtypeSnapshot: newtypeSnapshot,
    eqSnapshot: eqSnapshot,
    showSnapshot: showSnapshot,
    decodeJsonSnapshot: decodeJsonSnapshot,
    encodeJsonSnapshot: encodeJsonSnapshot,
    ExtraStackValues: Lambda_Language_Snapshot_Error.ExtraStackValues,
    IndexOutOfRange: Lambda_Language_Snapshot_Error.IndexOutOfRange,
    PayloadOutOfRange: Lambda_Language_Snapshot_Error.PayloadOutOfRange,
    StackUnderflow: Lambda_Language_Snapshot_Error.StackUnderflow,
    UnrecognizedTag: Lambda_Language_Snapshot_Error.UnrecognizedTag
};
