// Generated by purs version 0.14.4
"use strict";
var ApiError = (function () {
    function ApiError(value0) {
        this.value0 = value0;
    };
    ApiError.create = function (value0) {
        return new ApiError(value0);
    };
    return ApiError;
})();
var SaveError = (function () {
    function SaveError(value0) {
        this.value0 = value0;
    };
    SaveError.create = function (value0) {
        return new SaveError(value0);
    };
    return SaveError;
})();
var ParseError = (function () {
    function ParseError(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ParseError.create = function (value0) {
        return function (value1) {
            return new ParseError(value0, value1);
        };
    };
    return ParseError;
})();
var Inconsistent = (function () {
    function Inconsistent(value0) {
        this.value0 = value0;
    };
    Inconsistent.create = function (value0) {
        return new Inconsistent(value0);
    };
    return Inconsistent;
})();
var Help = (function () {
    function Help() {

    };
    Help.value = new Help();
    return Help;
})();
var Link = (function () {
    function Link(value0) {
        this.value0 = value0;
    };
    Link.create = function (value0) {
        return new Link(value0);
    };
    return Link;
})();
var $$Error = (function () {
    function $$Error(value0) {
        this.value0 = value0;
    };
    $$Error.create = function (value0) {
        return new $$Error(value0);
    };
    return $$Error;
})();
module.exports = {
    Help: Help,
    Link: Link,
    "Error": $$Error,
    ApiError: ApiError,
    SaveError: SaveError,
    ParseError: ParseError,
    Inconsistent: Inconsistent
};
