// Generated by purs version 0.14.4
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_MediaType_Common = require("../Data.MediaType.Common/index.js");
var ArrayView = (function () {
    function ArrayView(value0) {
        this.value0 = value0;
    };
    ArrayView.create = function (value0) {
        return new ArrayView(value0);
    };
    return ArrayView;
})();
var Blob = (function () {
    function Blob(value0) {
        this.value0 = value0;
    };
    Blob.create = function (value0) {
        return new Blob(value0);
    };
    return Blob;
})();
var Document = (function () {
    function Document(value0) {
        this.value0 = value0;
    };
    Document.create = function (value0) {
        return new Document(value0);
    };
    return Document;
})();
var $$String = (function () {
    function $$String(value0) {
        this.value0 = value0;
    };
    $$String.create = function (value0) {
        return new $$String(value0);
    };
    return $$String;
})();
var FormData = (function () {
    function FormData(value0) {
        this.value0 = value0;
    };
    FormData.create = function (value0) {
        return new FormData(value0);
    };
    return FormData;
})();
var FormURLEncoded = (function () {
    function FormURLEncoded(value0) {
        this.value0 = value0;
    };
    FormURLEncoded.create = function (value0) {
        return new FormURLEncoded(value0);
    };
    return FormURLEncoded;
})();
var Json = (function () {
    function Json(value0) {
        this.value0 = value0;
    };
    Json.create = function (value0) {
        return new Json(value0);
    };
    return Json;
})();
var toMediaType = function (v) {
    if (v instanceof FormURLEncoded) {
        return new Data_Maybe.Just(Data_MediaType_Common.applicationFormURLEncoded);
    };
    if (v instanceof Json) {
        return new Data_Maybe.Just(Data_MediaType_Common.applicationJSON);
    };
    return Data_Maybe.Nothing.value;
};
var string = $$String.create;
var json = Json.create;
var formURLEncoded = FormURLEncoded.create;
var formData = FormData.create;
var document = Document.create;
var blob = Blob.create;
var arrayView = function (av) {
    return new ArrayView(function (f) {
        return f(av);
    });
};
module.exports = {
    ArrayView: ArrayView,
    Blob: Blob,
    Document: Document,
    "String": $$String,
    FormData: FormData,
    FormURLEncoded: FormURLEncoded,
    Json: Json,
    arrayView: arrayView,
    blob: blob,
    document: document,
    string: string,
    formData: formData,
    formURLEncoded: formURLEncoded,
    json: json,
    toMediaType: toMediaType
};
