// Generated by purs version 0.14.4
"use strict";
var Components_Markup = require("../Components.Markup/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Lambda_Api = require("../Lambda.Api/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var Lambda_Language_Snapshot_Code = require("../Lambda.Language.Snapshot.Code/index.js");
var Lambda_Language_Snapshot_Error = require("../Lambda.Language.Snapshot.Error/index.js");
var Definitely = (function () {
    function Definitely() {

    };
    Definitely.value = new Definitely();
    return Definitely;
})();
var Probably = (function () {
    function Probably() {

    };
    Probably.value = new Probably();
    return Probably;
})();
var Maybe = (function () {
    function Maybe() {

    };
    Maybe.value = new Maybe();
    return Maybe;
})();
var bug = (function () {
    var fileAnIssue = Components_Markup.link({
        "this": "file an issue",
        to: "github.com/cdparks/lambda-machine/issues"
    });
    return function (v) {
        if (v instanceof Definitely) {
            return [ Components_Markup.text("This is definitely a bug. If you have time to "), fileAnIssue, Components_Markup.text(", I'd really appreciate it!") ];
        };
        if (v instanceof Probably) {
            return [ Components_Markup.text("This is probably a bug. If you have time to "), fileAnIssue, Components_Markup.text(", I'd really appreciate it!") ];
        };
        if (v instanceof Maybe) {
            return [ Components_Markup.text("If you think this looks like a bug, feel free to "), fileAnIssue, Components_Markup.text(". Thanks!") ];
        };
        throw new Error("Failed pattern match at Components.ApiError (line 51, column 7 - line 66, column 6): " + [ v.constructor.name ]);
    };
})();
var explain = function (v) {
    if (v instanceof Lambda_Api.BadSnapshot) {
        return [ Components_Markup.para([ Components_Markup.text("Something went wrong while processing a snapshot:") ]), Components_Markup.code([ Lambda_Language_Pretty.toString(Lambda_Language_Pretty.pretty(Lambda_Language_Snapshot_Error.prettyError)(Lambda_Language_Pretty.Raw.value)(v.value0)) ]), Components_Markup.para(bug(Definitely.value)) ];
    };
    if (v instanceof Lambda_Api.ParseError) {
        return [ Components_Markup.para([ Components_Markup.text("A response from the API failed to parse:") ]), Components_Markup.code([ v.value0 ]), Components_Markup.para(bug(Probably.value)) ];
    };
    if (v instanceof Lambda_Api.Missing) {
        return [ Components_Markup.para(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Components_Markup.text("The snapshot identified by "), Components_Markup.text(Data_Show.show(Lambda_Language_Snapshot_Code.showCode)(v.value0)), Components_Markup.text(" doesn't appear to exist anymore. ") ])(bug(Maybe.value))) ];
    };
    if (v instanceof Lambda_Api.HttpError && v.value0 instanceof Data_Maybe.Just) {
        return [ Components_Markup.para([ Components_Markup.text("Something went wrong while communicating with the API:") ]), Components_Markup.code([ v.value0.value0 ]), Components_Markup.para(bug(Probably.value)) ];
    };
    if (v instanceof Lambda_Api.HttpError && v.value0 instanceof Data_Maybe.Nothing) {
        return [ Components_Markup.para(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Components_Markup.text("Something went wrong while communicating with the API. ") ])(bug(Probably.value))) ];
    };
    throw new Error("Failed pattern match at Components.ApiError (line 15, column 11 - line 48, column 6): " + [ v.constructor.name ]);
};
var component = function ($11) {
    return Components_Markup.markup(explain((function (v) {
        return v.error;
    })($11)));
};
module.exports = {
    component: component
};
