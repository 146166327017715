// Generated by purs version 0.14.4
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var component = function (v) {
    return React_Basic.fragment([ React_Basic_DOM_Generated.hr()({}), React_Basic_DOM_Generated.a()({
        className: "pull-right",
        href: "https://github.com/cdparks/lambda-machine",
        children: [ React_Basic_DOM.text("Source on GitHub") ]
    }) ]);
};
module.exports = {
    component: component
};
