// Generated by purs version 0.14.4
"use strict";
var Default = (function () {
    function Default() {

    };
    Default.value = new Default();
    return Default;
})();
var Primary = (function () {
    function Primary() {

    };
    Primary.value = new Primary();
    return Primary;
})();
var Success = (function () {
    function Success() {

    };
    Success.value = new Success();
    return Success;
})();
var Info = (function () {
    function Info() {

    };
    Info.value = new Info();
    return Info;
})();
var Warning = (function () {
    function Warning() {

    };
    Warning.value = new Warning();
    return Warning;
})();
var Danger = (function () {
    function Danger() {

    };
    Danger.value = new Danger();
    return Danger;
})();
var showLevel = {
    show: function (v) {
        if (v instanceof Default) {
            return "default";
        };
        if (v instanceof Primary) {
            return "primary";
        };
        if (v instanceof Success) {
            return "success";
        };
        if (v instanceof Info) {
            return "info";
        };
        if (v instanceof Warning) {
            return "warning";
        };
        if (v instanceof Danger) {
            return "danger";
        };
        throw new Error("Failed pattern match at Components.Level (line 16, column 1 - line 22, column 25): " + [ v.constructor.name ]);
    }
};
module.exports = {
    Default: Default,
    Primary: Primary,
    Success: Success,
    Info: Info,
    Warning: Warning,
    Danger: Danger,
    showLevel: showLevel
};
