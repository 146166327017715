// Generated by purs version 0.14.4
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var Raw = (function () {
    function Raw() {

    };
    Raw.value = new Raw();
    return Raw;
})();
var Sugar = (function () {
    function Sugar() {

    };
    Sugar.value = new Sugar();
    return Sugar;
})();
var Empty = (function () {
    function Empty() {

    };
    Empty.value = new Empty();
    return Empty;
})();
var Text = (function () {
    function Text(value0) {
        this.value0 = value0;
    };
    Text.create = function (value0) {
        return new Text(value0);
    };
    return Text;
})();
var Style = (function () {
    function Style(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Style.create = function (value0) {
        return function (value1) {
            return new Style(value0, value1);
        };
    };
    return Style;
})();
var Leaf = (function () {
    function Leaf(value0) {
        this.value0 = value0;
    };
    Leaf.create = function (value0) {
        return new Leaf(value0);
    };
    return Leaf;
})();
var Append = (function () {
    function Append(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Append.create = function (value0) {
        return function (value1) {
            return new Append(value0, value1);
        };
    };
    return Append;
})();
var Builder = function (x) {
    return x;
};
var toggle = function (v) {
    if (v instanceof Sugar) {
        return Raw.value;
    };
    if (v instanceof Raw) {
        return Sugar.value;
    };
    throw new Error("Failed pattern match at Lambda.Language.Pretty (line 43, column 10 - line 45, column 15): " + [ v.constructor.name ]);
};
var text = function (s) {
    return Builder(function (v) {
        if (v instanceof Leaf && v.value0 instanceof Empty) {
            return Leaf.create(new Text(s));
        };
        if (v instanceof Leaf && v.value0 instanceof Text) {
            return Leaf.create(Text.create(s + v.value0.value0));
        };
        if (v instanceof Append && v.value0 instanceof Text) {
            return new Append(Text.create(s + v.value0.value0), v.value1);
        };
        return new Append(new Text(s), v);
    });
};
var style = function (cls) {
    return function (v) {
        return Builder(Append.create(Style.create(cls)(v(new Leaf(Empty.value)))));
    };
};
var semigroupBuilder = {
    append: function (v) {
        return function (v1) {
            return Builder(function ($46) {
                return v(v1($46));
            });
        };
    }
};
var select = function (rep) {
    return function (v) {
        if (rep instanceof Sugar) {
            return v.sugar;
        };
        if (rep instanceof Raw) {
            return v.raw;
        };
        throw new Error("Failed pattern match at Lambda.Language.Pretty (line 37, column 27 - line 39, column 13): " + [ rep.constructor.name ]);
    };
};
var pretty = function (dict) {
    return dict.pretty;
};
var parensIf = function (cond) {
    return function (body) {
        if (cond) {
            return Data_Semigroup.append(semigroupBuilder)(text("("))(Data_Semigroup.append(semigroupBuilder)(body)(text(")")));
        };
        if (Data_Boolean.otherwise) {
            return body;
        };
        throw new Error("Failed pattern match at Lambda.Language.Pretty (line 30, column 1 - line 30, column 42): " + [ cond.constructor.name, body.constructor.name ]);
    };
};
var monoidBuilder = {
    mempty: Control_Category.identity(Control_Category.categoryFn),
    Semigroup0: function () {
        return semigroupBuilder;
    }
};
var eqRep = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Raw && y instanceof Raw) {
                return true;
            };
            if (x instanceof Sugar && y instanceof Sugar) {
                return true;
            };
            return false;
        };
    }
};
var buildWith = function (dictMonoid) {
    return function (onText) {
        return function (onStyle) {
            return function (v) {
                var onNode = function (v1) {
                    if (v1 instanceof Leaf) {
                        return onLeaf(v1.value0);
                    };
                    if (v1 instanceof Append) {
                        return Data_Semigroup.append(dictMonoid.Semigroup0())(onLeaf(v1.value0))(onNode(v1.value1));
                    };
                    throw new Error("Failed pattern match at Lambda.Language.Pretty (line 105, column 12 - line 107, column 51): " + [ v1.constructor.name ]);
                };
                var onLeaf = function (v1) {
                    if (v1 instanceof Empty) {
                        return Data_Monoid.mempty(dictMonoid);
                    };
                    if (v1 instanceof Text) {
                        return onText(v1.value0);
                    };
                    if (v1 instanceof Style) {
                        return onStyle(v1.value0)(onNode(v1.value1));
                    };
                    throw new Error("Failed pattern match at Lambda.Language.Pretty (line 109, column 12 - line 112, column 60): " + [ v1.constructor.name ]);
                };
                return onNode(v(new Leaf(Empty.value)));
            };
        };
    };
};
var toJSX = buildWith(React_Basic.monoidJSX)(React_Basic_DOM.text)(function (className) {
    return function (node) {
        return React_Basic_DOM_Generated.span()({
            className: className,
            children: [ node ]
        });
    };
});
var toString = buildWith(Data_Monoid.monoidString)(Control_Category.identity(Control_Category.categoryFn))(function (v) {
    return Control_Category.identity(Control_Category.categoryFn);
});
module.exports = {
    pretty: pretty,
    Raw: Raw,
    Sugar: Sugar,
    parensIf: parensIf,
    select: select,
    toggle: toggle,
    text: text,
    style: style,
    toJSX: toJSX,
    toString: toString,
    eqRep: eqRep,
    semigroupBuilder: semigroupBuilder,
    monoidBuilder: monoidBuilder
};
