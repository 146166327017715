// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Decode_Error = require("../Data.Argonaut.Decode.Error/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Lambda_Env = require("../Lambda.Env/index.js");
var Lambda_Language_Snapshot = require("../Lambda.Language.Snapshot/index.js");
var Lambda_Language_Snapshot_Code = require("../Lambda.Language.Snapshot.Code/index.js");
var Lambda_Language_Snapshot_Error = require("../Lambda.Language.Snapshot.Error/index.js");
var Simple_Ajax = require("../Simple.Ajax/index.js");
var BadSnapshot = (function () {
    function BadSnapshot(value0) {
        this.value0 = value0;
    };
    BadSnapshot.create = function (value0) {
        return new BadSnapshot(value0);
    };
    return BadSnapshot;
})();
var ParseError = (function () {
    function ParseError(value0) {
        this.value0 = value0;
    };
    ParseError.create = function (value0) {
        return new ParseError(value0);
    };
    return ParseError;
})();
var Missing = (function () {
    function Missing(value0) {
        this.value0 = value0;
    };
    Missing.create = function (value0) {
        return new Missing(value0);
    };
    return Missing;
})();
var HttpError = (function () {
    function HttpError(value0) {
        this.value0 = value0;
    };
    HttpError.create = function (value0) {
        return new HttpError(value0);
    };
    return HttpError;
})();
var genericError = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new BadSnapshot(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new ParseError(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Missing(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new HttpError(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Lambda.Api (line 44, column 1 - line 44, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof BadSnapshot) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof ParseError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Missing) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof HttpError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at Lambda.Api (line 44, column 1 - line 44, column 48): " + [ x.constructor.name ]);
    }
};
var showError = {
    show: function (x) {
        return Data_Show_Generic.genericShow(genericError)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Snapshot_Error.showError))({
            reflectSymbol: function () {
                return "BadSnapshot";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showString))({
            reflectSymbol: function () {
                return "ParseError";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Lambda_Language_Snapshot_Code.showCode))({
            reflectSymbol: function () {
                return "Missing";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Maybe.showMaybe(Data_Show.showString)))({
            reflectSymbol: function () {
                return "HttpError";
            }
        })))))(x);
    }
};
var fromAjaxError = function (code) {
    var ignore = new HttpError(Data_Maybe.Nothing.value);
    return Data_Variant.match()()()({
        parseError: function ($25) {
            return ParseError.create(Data_Argonaut_Decode_Error.printJsonDecodeError($25));
        },
        badRequest: function ($26) {
            return HttpError.create(Data_Maybe.Just.create($26));
        },
        unAuthorized: Data_Function["const"](ignore),
        forbidden: Data_Function["const"](ignore),
        notFound: Data_Function["const"](Data_Maybe.maybe(ignore)(Missing.create)(code)),
        methodNotAllowed: Data_Function["const"](ignore),
        serverError: Data_Function["const"](ignore),
        affjaxError: Data_Function["const"](ignore)
    });
};
var store = function (program) {
    var url = Lambda_Env.api + "/snapshots";
    var convert = Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither)(fromAjaxError(Data_Maybe.Nothing.value))(function (v) {
        return v.code;
    });
    var v = Lambda_Language_Snapshot["new"](program);
    if (v instanceof Data_Either.Left) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Either.Left.create(new BadSnapshot(v.value0)));
    };
    if (v instanceof Data_Either.Right) {
        return Data_Functor.map(Effect_Aff.functorAff)(convert)(Simple_Ajax.post(Lambda_Language_Snapshot.encodeJsonSnapshot)(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Lambda_Language_Snapshot_Code.decodeJsonCode))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
            reflectSymbol: function () {
                return "code";
            }
        })()())())(url)(new Data_Maybe.Just(v.value0)));
    };
    throw new Error("Failed pattern match at Lambda.Api (line 19, column 17 - line 21, column 62): " + [ v.constructor.name ]);
};
var fetch = function (code) {
    var url = Lambda_Env.api + ("/snapshots/" + Data_Newtype.unwrap()(code));
    var convert = Data_Either.either((function () {
        var $27 = fromAjaxError(new Data_Maybe.Just(code));
        return function ($28) {
            return Data_Either.Left.create($27($28));
        };
    })())((function () {
        var $29 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(BadSnapshot.create);
        return function ($30) {
            return $29(Lambda_Language_Snapshot.load($30));
        };
    })());
    return Data_Functor.map(Effect_Aff.functorAff)(convert)(Simple_Ajax.get(Lambda_Language_Snapshot.decodeJsonSnapshot)(url));
};
module.exports = {
    BadSnapshot: BadSnapshot,
    ParseError: ParseError,
    Missing: Missing,
    HttpError: HttpError,
    fetch: fetch,
    store: store,
    genericError: genericError,
    showError: showError
};
