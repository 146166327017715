// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var Note = function (x) {
    return x;
};
var Markup = function (x) {
    return x;
};
var Leaf = function (x) {
    return x;
};
var title = (function () {
    var $4 = Safe_Coerce.coerce();
    var $5 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    return function ($6) {
        return $4(React_Basic_DOM_Generated.h3_($5(React_Basic_DOM.text($6))));
    };
})();
var text = (function () {
    var $7 = Safe_Coerce.coerce();
    return function ($8) {
        return $7(React_Basic_DOM.text($8));
    };
})();
var para = (function () {
    var $9 = Safe_Coerce.coerce();
    var $10 = Safe_Coerce.coerce();
    return function ($11) {
        return $9(React_Basic_DOM_Generated.p_($10($11)));
    };
})();
var notes = function (xs) {
    return Safe_Coerce.coerce()(React_Basic_DOM_Generated.table_([ React_Basic_DOM_Generated.tbody_(Safe_Coerce.coerce()(xs)) ]));
};
var mono = function (x) {
    return Safe_Coerce.coerce()(React_Basic_DOM_Generated.span()({
        className: "monospace-font",
        children: [ React_Basic_DOM.text(x) ]
    }));
};
var markup = (function () {
    var $12 = Safe_Coerce.coerce();
    return function ($13) {
        return React_Basic.fragment($12($13));
    };
})();
var link = function (v) {
    return Safe_Coerce.coerce()(React_Basic_DOM_Generated.a()({
        href: "https://" + v.to,
        target: "_blank",
        rel: "noopener",
        children: [ React_Basic_DOM.text(v["this"]) ]
    }));
};
var formatPre = function (x) {
    return React_Basic_DOM.text("  " + (x + "\x0a"));
};
var note = function (x) {
    return function (xs) {
        return Safe_Coerce.coerce()(React_Basic_DOM_Generated.tr_([ React_Basic_DOM_Generated.td_([ React_Basic_DOM_Generated.span()({
            className: "preformatted",
            children: [ formatPre(x) ]
        }) ]), React_Basic_DOM_Generated.td()({
            className: "comment",
            children: Safe_Coerce.coerce()(xs)
        }) ]));
    };
};
var code = function (xs) {
    return Safe_Coerce.coerce()(React_Basic_DOM_Generated.p()({
        className: "preformatted",
        children: Data_Functor.map(Data_Functor.functorArray)(formatPre)(xs)
    }));
};
var bold = (function () {
    var $14 = Safe_Coerce.coerce();
    var $15 = Control_Applicative.pure(Control_Applicative.applicativeArray);
    return function ($16) {
        return $14(React_Basic_DOM_Generated.strong_($15(React_Basic_DOM.text($16))));
    };
})();
module.exports = {
    markup: markup,
    title: title,
    para: para,
    code: code,
    notes: notes,
    note: note,
    text: text,
    bold: bold,
    mono: mono,
    link: link
};
