// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");
var Data_Hashable = require("../Data.Hashable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var SemigroupHashMap = function (x) {
    return x;
};
var values = $foreign.toArrayBy(function (v) {
    return function (v1) {
        return v1;
    };
});
var unionWith = function (dictHashable) {
    return function (f) {
        return $foreign.unionWithPurs(Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable), f);
    };
};
var union = function (dictHashable) {
    return $foreign.unionWithPurs(Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable), Data_Function["const"]);
};
var singleton = function (dictHashable) {
    return function (k) {
        return $foreign.singletonPurs(k)(Data_Hashable.hash(dictHashable)(k));
    };
};
var showHashMap = function (dictShow) {
    return function (dictShow1) {
        return {
            show: function (m) {
                return "(fromArray " + (Data_Show.show(Data_Show.showArray(Data_Tuple.showTuple(dictShow)(dictShow1)))($foreign.toArrayBy(Data_Tuple.Tuple.create)(m)) + ")");
            }
        };
    };
};
var showSemigroupHashMap = function (dictShow) {
    return function (dictShow1) {
        return showHashMap(dictShow)(dictShow1);
    };
};
var semigroupSemigroupHashMap = function (dictHashable) {
    return function (dictSemigroup) {
        return {
            append: function (v) {
                return function (v1) {
                    return unionWith(dictHashable)(Data_Semigroup.append(dictSemigroup))(v)(v1);
                };
            }
        };
    };
};
var nubHash = function (dictHashable) {
    return $foreign.nubHashPurs(Data_Maybe.Nothing.value, Data_Maybe.Just.create, Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable));
};
var newtypeSemigroupHashMap = {
    Coercible0: function () {
        return undefined;
    }
};
var monoidSemigroupHashMap = function (dictHashable) {
    return function (dictSemigroup) {
        return {
            mempty: $foreign.empty,
            Semigroup0: function () {
                return semigroupSemigroupHashMap(dictHashable)(dictSemigroup);
            }
        };
    };
};
var lookup = function (dictHashable) {
    return function (k) {
        return $foreign.lookupPurs(Data_Maybe.Nothing.value, Data_Maybe.Just.create, Data_Eq.eq(dictHashable.Eq0()), k, Data_Hashable.hash(dictHashable)(k));
    };
};
var member = function (dictHashable) {
    return function (k) {
        var $64 = lookup(dictHashable)(k);
        return function ($65) {
            return Data_Maybe.isJust($64($65));
        };
    };
};
var keys = $foreign.toArrayBy(Data_Function["const"]);
var intersectionWith = function (dictHashable) {
    return function (f) {
        return $foreign.intersectionWithPurs(Data_Maybe.Nothing.value, Data_Maybe.Just.create, Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable), f);
    };
};
var intersection = function (dictHashable) {
    return intersectionWith(dictHashable)(function (v) {
        return function (x) {
            return x;
        };
    });
};
var insertWith = function (dictHashable) {
    return $foreign.insertWithPurs(Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable));
};
var upsert = function (dictHashable) {
    return function (f) {
        return insertWith(dictHashable)(function (v) {
            return function (v1) {
                return f(v);
            };
        });
    };
};
var insert = function (dictHashable) {
    return $foreign.insertPurs(Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable));
};
var functorWithIndexHashMap = {
    mapWithIndex: $foreign.mapWithIndexPurs,
    Functor0: function () {
        return functorHashMap;
    }
};
var functorHashMap = {
    map: function (f) {
        return Data_FunctorWithIndex.mapWithIndex(functorWithIndexHashMap)(Data_Function["const"](f));
    }
};
var functorSemigroupHashMap = functorHashMap;
var functorWithIndexSemigroupHashMap = functorWithIndexHashMap;
var fromFoldableWithIndex = function (dictFoldableWithIndex) {
    return function (dictHashable) {
        return Data_FoldableWithIndex.foldlWithIndex(dictFoldableWithIndex)(function (k) {
            return function (m) {
                return function (v) {
                    return insert(dictHashable)(k)(v)(m);
                };
            };
        })($foreign.empty);
    };
};
var fromFoldableBy = function (dictFoldable) {
    return function (dictHashable) {
        return function (kf) {
            return function (vf) {
                return Data_Foldable.foldl(dictFoldable)(function (m) {
                    return function (a) {
                        return insert(dictHashable)(kf(a))(vf(a))(m);
                    };
                })($foreign.empty);
            };
        };
    };
};
var fromFoldable = function (dictFoldable) {
    return function (dictHashable) {
        return Data_Foldable.foldl(dictFoldable)(function (m) {
            return function (v) {
                return insert(dictHashable)(v.value0)(v.value1)(m);
            };
        })($foreign.empty);
    };
};
var fromArrayBy = function (dictHashable) {
    return $foreign.fromArrayPurs(Data_Eq.eq(dictHashable.Eq0()), Data_Hashable.hash(dictHashable));
};
var fromArray = function (dictHashable) {
    return fromArrayBy(dictHashable)(Data_Tuple.fst)(Data_Tuple.snd);
};
var foldableWithIndexHashMap = {
    foldMapWithIndex: function (dictMonoid) {
        return $foreign.foldMapWithIndexPurs(Data_Monoid.mempty(dictMonoid))(Data_Semigroup.append(dictMonoid.Semigroup0()));
    },
    foldrWithIndex: function (f) {
        return Data_FoldableWithIndex.foldrWithIndexDefault(foldableWithIndexHashMap)(f);
    },
    foldlWithIndex: function (f) {
        return Data_FoldableWithIndex.foldlWithIndexDefault(foldableWithIndexHashMap)(f);
    },
    Foldable0: function () {
        return foldableHashMap;
    }
};
var foldableHashMap = {
    foldMap: function (dictMonoid) {
        return function (f) {
            return Data_FoldableWithIndex.foldMapWithIndex(foldableWithIndexHashMap)(dictMonoid)(Data_Function["const"](f));
        };
    },
    foldr: function (f) {
        return Data_Foldable.foldrDefault(foldableHashMap)(f);
    },
    foldl: function (f) {
        return Data_Foldable.foldlDefault(foldableHashMap)(f);
    }
};
var foldableSemigroupHashMap = foldableHashMap;
var foldableWithIndexSemigroupHashMap = foldableWithIndexHashMap;
var traversableWithIndexHashMap = {
    traverseWithIndex: function (dictApplicative) {
        return function (f) {
            return function (m) {
                return $foreign.traverseWithIndexPurs(Control_Applicative.pure(dictApplicative))(Control_Apply.apply(dictApplicative.Apply0()))(f)(m);
            };
        };
    },
    FunctorWithIndex0: function () {
        return functorWithIndexHashMap;
    },
    FoldableWithIndex1: function () {
        return foldableWithIndexHashMap;
    },
    Traversable2: function () {
        return traversableHashMap;
    }
};
var traversableHashMap = {
    traverse: function (dictApplicative) {
        return function (f) {
            return Data_TraversableWithIndex.traverseWithIndex(traversableWithIndexHashMap)(dictApplicative)(Data_Function["const"](f));
        };
    },
    sequence: function (dictApplicative) {
        return Data_Traversable.traverse(traversableHashMap)(dictApplicative)(function (x) {
            return x;
        });
    },
    Functor0: function () {
        return functorHashMap;
    },
    Foldable1: function () {
        return foldableHashMap;
    }
};
var traversableSemigroupHashMap = traversableHashMap;
var traversableWithIndexSemigroupHashMap = traversableWithIndexHashMap;
var filterKeys = function (f) {
    return $foreign.filterWithKey(function (k) {
        return function (v) {
            return f(k);
        };
    });
};
var filter = function (f) {
    return $foreign.filterWithKey(Data_Function["const"](f));
};
var mapMaybeWithKey = function (f) {
    var $66 = Data_Functor.map(functorHashMap)(Data_Maybe.fromJust());
    var $67 = filter(Data_Maybe.isJust);
    var $68 = Data_FunctorWithIndex.mapWithIndex(functorWithIndexHashMap)(f);
    return function ($69) {
        return $66($67($68($69)));
    };
};
var mapMaybe = function ($70) {
    return mapMaybeWithKey(Data_Function["const"]($70));
};
var eqHashMap = function (dictEq) {
    return function (dictEq1) {
        return {
            eq: $foreign.eqPurs(Data_Eq.eq(dictEq), Data_Eq.eq(dictEq1))
        };
    };
};
var eqSemigroupHashMap = function (dictEq) {
    return function (dictEq1) {
        return eqHashMap(dictEq)(dictEq1);
    };
};
var hashHashMap = function (dictHashable) {
    return function (dictHashable1) {
        return {
            hash: $foreign.hashPurs(Data_Hashable.hash(dictHashable1)),
            Eq0: function () {
                return eqHashMap(dictHashable.Eq0())(dictHashable1.Eq0());
            }
        };
    };
};
var hashSemigroupHashMap = function (dictHashable) {
    return function (dictHashable1) {
        return hashHashMap(dictHashable)(dictHashable1);
    };
};
var $$delete = function (dictHashable) {
    return function (k) {
        return $foreign.deletePurs(Data_Eq.eq(dictHashable.Eq0()), k, Data_Hashable.hash(dictHashable)(k));
    };
};
var difference = function (dictHashable) {
    return function (l) {
        return function (r) {
            return Data_Foldable.foldr(Data_Foldable.foldableArray)($$delete(dictHashable))(l)(keys(r));
        };
    };
};
var applyHashMap = function (dictHashable) {
    return {
        apply: intersectionWith(dictHashable)(Control_Category.identity(Control_Category.categoryFn)),
        Functor0: function () {
            return functorHashMap;
        }
    };
};
var applySemigroupHashMap = function (dictHashable) {
    return applyHashMap(dictHashable);
};
var bindHashMap = function (dictHashable) {
    return {
        bind: function (m) {
            return function (f) {
                return mapMaybeWithKey(function (k) {
                    var $71 = lookup(dictHashable)(k);
                    return function ($72) {
                        return $71(f($72));
                    };
                })(m);
            };
        },
        Apply0: function () {
            return applyHashMap(dictHashable);
        }
    };
};
var bindSemigroupHashMap = function (dictHashable) {
    return bindHashMap(dictHashable);
};
var alter = function (dictHashable) {
    return function (f) {
        return function (k) {
            return function (m) {
                var v = f(lookup(dictHashable)(k)(m));
                if (v instanceof Data_Maybe.Nothing) {
                    return $$delete(dictHashable)(k)(m);
                };
                if (v instanceof Data_Maybe.Just) {
                    return insert(dictHashable)(k)(v.value0)(m);
                };
                throw new Error("Failed pattern match at Data.HashMap (line 306, column 15 - line 308, column 25): " + [ v.constructor.name ]);
            };
        };
    };
};
var update = function (dictHashable) {
    return function (f) {
        return alter(dictHashable)(function (v) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(v)(f);
        });
    };
};
module.exports = {
    singleton: singleton,
    lookup: lookup,
    insert: insert,
    "delete": $$delete,
    member: member,
    upsert: upsert,
    insertWith: insertWith,
    update: update,
    alter: alter,
    filter: filter,
    filterKeys: filterKeys,
    mapMaybe: mapMaybe,
    mapMaybeWithKey: mapMaybeWithKey,
    fromArray: fromArray,
    fromFoldable: fromFoldable,
    fromArrayBy: fromArrayBy,
    fromFoldableBy: fromFoldableBy,
    fromFoldableWithIndex: fromFoldableWithIndex,
    keys: keys,
    values: values,
    union: union,
    unionWith: unionWith,
    intersection: intersection,
    intersectionWith: intersectionWith,
    difference: difference,
    SemigroupHashMap: SemigroupHashMap,
    nubHash: nubHash,
    eqHashMap: eqHashMap,
    hashHashMap: hashHashMap,
    functorHashMap: functorHashMap,
    functorWithIndexHashMap: functorWithIndexHashMap,
    applyHashMap: applyHashMap,
    bindHashMap: bindHashMap,
    foldableHashMap: foldableHashMap,
    foldableWithIndexHashMap: foldableWithIndexHashMap,
    traversableHashMap: traversableHashMap,
    traversableWithIndexHashMap: traversableWithIndexHashMap,
    newtypeSemigroupHashMap: newtypeSemigroupHashMap,
    eqSemigroupHashMap: eqSemigroupHashMap,
    hashSemigroupHashMap: hashSemigroupHashMap,
    functorSemigroupHashMap: functorSemigroupHashMap,
    functorWithIndexSemigroupHashMap: functorWithIndexSemigroupHashMap,
    applySemigroupHashMap: applySemigroupHashMap,
    bindSemigroupHashMap: bindSemigroupHashMap,
    foldableSemigroupHashMap: foldableSemigroupHashMap,
    foldableWithIndexSemigroupHashMap: foldableWithIndexSemigroupHashMap,
    traversableSemigroupHashMap: traversableSemigroupHashMap,
    traversableWithIndexSemigroupHashMap: traversableWithIndexSemigroupHashMap,
    showSemigroupHashMap: showSemigroupHashMap,
    semigroupSemigroupHashMap: semigroupSemigroupHashMap,
    monoidSemigroupHashMap: monoidSemigroupHashMap,
    showHashMap: showHashMap,
    empty: $foreign.empty,
    size: $foreign.size,
    isEmpty: $foreign.isEmpty,
    filterWithKey: $foreign.filterWithKey,
    toArrayBy: $foreign.toArrayBy,
    debugShow: $foreign.debugShow
};
