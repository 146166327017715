// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_HashMap = require("../Data.HashMap/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Queue = require("../Data.Queue/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Lambda_Machine_Address = require("../Lambda.Machine.Address/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var modifyHeap = function (dictMonadState) {
    return function (f) {
        return Control_Bind.bind((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.gets(dictMonadState)(function (v) {
            return v.heap;
        }))(function (heap0) {
            var v = f(heap0);
            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadState.Monad0()).Bind1())(Control_Monad_State_Class.modify_(dictMonadState)(function (v1) {
                var $23 = {};
                for (var $24 in v1) {
                    if ({}.hasOwnProperty.call(v1, $24)) {
                        $23[$24] = v1[$24];
                    };
                };
                $23.heap = v.value1;
                return $23;
            }))(function () {
                return Control_Applicative.pure((dictMonadState.Monad0()).Applicative0())(v.value0);
            });
        });
    };
};
var reserve = function (dictMonadState) {
    return modifyHeap(dictMonadState)(function (v) {
        return new Data_Tuple.Tuple(v.next, {
            memory: v.memory,
            next: Lambda_Machine_Address.offset(1)(v.next)
        });
    });
};
var update = function (dictMonadState) {
    return function (address) {
        return function (node) {
            return modifyHeap(dictMonadState)(function (heap) {
                return Data_Tuple.Tuple.create(Data_Unit.unit)({
                    memory: Data_HashMap.insert(Lambda_Machine_Address.hashableAddress)(address)(node)(heap.memory),
                    next: heap.next
                });
            });
        };
    };
};
var withHeap = function (dictMonadState) {
    return function (f) {
        return modifyHeap(dictMonadState)(function (heap) {
            return new Data_Tuple.Tuple(f(heap), heap);
        });
    };
};
var free = function (dictMonadState) {
    return function (address) {
        return modifyHeap(dictMonadState)(function (heap) {
            return Data_Tuple.Tuple.create(Data_Unit.unit)({
                memory: Data_HashMap["delete"](Lambda_Machine_Address.hashableAddress)(address)(heap.memory),
                next: heap.next
            });
        });
    };
};
var fetch = function (dictMonadState) {
    return function (address) {
        return withHeap(dictMonadState)(function (v) {
            var v1 = Data_HashMap.lookup(Lambda_Machine_Address.hashableAddress)(address)(v.memory);
            if (v1 instanceof Data_Maybe.Just) {
                return v1.value0;
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Partial_Unsafe.unsafeCrashWith("Invalid address " + Data_Show.show(Lambda_Machine_Address.showAddress)(address));
            };
            throw new Error("Failed pattern match at Lambda.Machine.Heap (line 47, column 3 - line 49, column 68): " + [ v1.constructor.name ]);
        });
    };
};
var gc = function (dictMonadState) {
    return function (dictMonadRec) {
        return function (dictFoldable) {
            return function (roots) {
                return function (children) {
                    var go = function (v) {
                        var v1 = Data_Queue.pop(v.queue);
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(v.toSpace));
                        };
                        if (v1 instanceof Data_Maybe.Just) {
                            if (Data_HashMap.member(Lambda_Machine_Address.hashableAddress)(v1.value0.value0)(v.toSpace)) {
                                return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop({
                                    queue: v1.value0.value1,
                                    toSpace: v.toSpace
                                }));
                            };
                            if (Data_Boolean.otherwise) {
                                return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(fetch(dictMonadState)(v1.value0.value0))(function (node) {
                                    return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop({
                                        queue: Data_Queue.extend(Data_Foldable.foldableArray)(v1.value0.value1)(children(node)),
                                        toSpace: Data_HashMap.insert(Lambda_Machine_Address.hashableAddress)(v1.value0.value0)(node)(v.toSpace)
                                    }));
                                });
                            };
                        };
                        throw new Error("Failed pattern match at Lambda.Machine.Heap (line 88, column 5 - line 101, column 16): " + [ v1.constructor.name ]);
                    };
                    return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go)({
                        queue: Data_Queue.fromFoldable(dictFoldable)(roots),
                        toSpace: Data_HashMap.empty
                    }))(function (toSpace) {
                        return Control_Monad_State_Class.modify_(dictMonadState)(function (v) {
                            var $42 = {};
                            for (var $43 in v) {
                                if ({}.hasOwnProperty.call(v, $43)) {
                                    $42[$43] = v[$43];
                                };
                            };
                            $42.heap = {
                                memory: toSpace,
                                next: Lambda_Machine_Address.offset(1)(Data_Maybe.fromMaybe(Lambda_Machine_Address.nullptr)(Data_Foldable.maximum(Lambda_Machine_Address.ordAddress)(Data_Foldable.foldableArray)(Data_HashMap.keys(toSpace))))
                            };
                            return $42;
                        });
                    });
                };
            };
        };
    };
};
var empty = {
    memory: Data_HashMap.empty,
    next: Lambda_Machine_Address.baseptr
};
var alloc = function (dictMonadState) {
    return function (node) {
        return modifyHeap(dictMonadState)(function (v) {
            return new Data_Tuple.Tuple(v.next, {
                memory: Data_HashMap.insert(Lambda_Machine_Address.hashableAddress)(v.next)(node)(v.memory),
                next: Lambda_Machine_Address.offset(1)(v.next)
            });
        });
    };
};
module.exports = {
    empty: empty,
    alloc: alloc,
    reserve: reserve,
    fetch: fetch,
    update: update,
    free: free,
    gc: gc
};
