// Generated by purs version 0.14.4
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Lambda_Language_Definition = require("../Lambda.Language.Definition/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var component = function (v) {
    var renderDef = function (v1) {
        return React_Basic_DOM_Generated.li()({
            className: "definition",
            children: [ React_Basic_DOM_Generated.span()({
                className: "cursor-pointer glyphicon glyphicon-remove",
                onClick: React_Basic_Events.handler_(v.onDelete(v1.name)),
                children: [  ]
            }), React_Basic_DOM.text(" "), Lambda_Language_Pretty.toJSX(Lambda_Language_Pretty.pretty(Lambda_Language_Definition.prettyDefinition)(v.rep)(v1)) ]
        });
    };
    return React_Basic_DOM_Generated.ul()({
        className: "unstyled",
        children: Data_Functor.map(Data_Functor.functorArray)(renderDef)(v.defs)
    });
};
module.exports = {
    component: component
};
