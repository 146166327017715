// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var unsafeCreateDOMComponent = $foreign.unsafeCreateDOMComponent_(React_Basic.element);
var semigroupCSS = {
    append: function (a) {
        return function (b) {
            return $foreign.mergeStyles([ b, a ]);
        };
    }
};
var css = Unsafe_Coerce.unsafeCoerce;
var monoidCSS = {
    mempty: css({}),
    Semigroup0: function () {
        return semigroupCSS;
    }
};
module.exports = {
    css: css,
    unsafeCreateDOMComponent: unsafeCreateDOMComponent,
    semigroupCSS: semigroupCSS,
    monoidCSS: monoidCSS,
    mergeStyles: $foreign.mergeStyles,
    unsafeCreateDOMComponent_: $foreign.unsafeCreateDOMComponent_
};
