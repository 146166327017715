// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Copy = require("../Effect.Copy/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Aff = require("../React.Basic.Hooks.Aff/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Ready = (function () {
    function Ready() {

    };
    Ready.value = new Ready();
    return Ready;
})();
var Copying = (function () {
    function Copying() {

    };
    Copying.value = new Copying();
    return Copying;
})();
var DidCopy = (function () {
    function DidCopy() {

    };
    DidCopy.value = new DidCopy();
    return DidCopy;
})();
var NoCopy = (function () {
    function NoCopy() {

    };
    NoCopy.value = new NoCopy();
    return NoCopy;
})();
var eqCopying = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Ready && y instanceof Ready) {
                return true;
            };
            if (x instanceof Copying && y instanceof Copying) {
                return true;
            };
            if (x instanceof DidCopy && y instanceof DidCopy) {
                return true;
            };
            if (x instanceof NoCopy && y instanceof NoCopy) {
                return true;
            };
            return false;
        };
    }
};
var $$new = (function () {
    var style = React_Basic_DOM_Internal.css({
        width: "160px"
    });
    var copyIcon = function (label) {
        return [ React_Basic_DOM_Generated.span()({
            className: "glyphicon glyphicon-copy",
            children: [  ]
        }), React_Basic_DOM.text(" " + label) ];
    };
    return React_Basic_Hooks.component("Copy")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Ready.value))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Aff.useAff(Data_Tuple.eqTuple(Data_Eq.eqString)(eqCopying))(new Data_Tuple.Tuple(v.text, v1.value0))((function () {
                if (v1.value0 instanceof Ready) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                };
                if (v1.value0 instanceof NoCopy) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                };
                if (v1.value0 instanceof Copying) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Copy.copy(v.text))(function (result) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(300.0))(function () {
                            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value1(Data_Function["const"]((function () {
                                if (result instanceof Data_Either.Left) {
                                    return NoCopy.value;
                                };
                                if (result instanceof Data_Either.Right) {
                                    return DidCopy.value;
                                };
                                throw new Error("Failed pattern match at Components.Copy (line 39, column 43 - line 41, column 29): " + [ result.constructor.name ]);
                            })())));
                        });
                    });
                };
                if (v1.value0 instanceof DidCopy) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(1000.0))(function () {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value1(Data_Function["const"](Ready.value)));
                    });
                };
                throw new Error("Failed pattern match at Components.Copy (line 32, column 5 - line 44, column 46): " + [ v1.value0.constructor.name ]);
            })()))(function () {
                var startCopying = v1.value1(Data_Function["const"](Copying.value));
                var copyButton = (function () {
                    if (v1.value0 instanceof Ready) {
                        return React_Basic_DOM_Generated.button()({
                            className: "btn btn-default",
                            style: style,
                            type: "submit",
                            onClick: React_Basic_Events.handler_(startCopying),
                            children: copyIcon("Copy to Clipboard")
                        });
                    };
                    if (v1.value0 instanceof Copying) {
                        return React_Basic_DOM_Generated.button()({
                            className: "btn btn-default",
                            style: style,
                            disabled: true,
                            type: "submit",
                            children: copyIcon("Copying\u2026")
                        });
                    };
                    if (v1.value0 instanceof DidCopy) {
                        return React_Basic_DOM_Generated.button()({
                            className: "btn btn-default",
                            style: style,
                            type: "submit",
                            onClick: React_Basic_Events.handler_(startCopying),
                            children: copyIcon("Copied!")
                        });
                    };
                    if (v1.value0 instanceof NoCopy) {
                        return React_Basic_DOM_Generated.button()({
                            className: "btn btn-default",
                            style: style,
                            disabled: true,
                            type: "submit",
                            children: copyIcon("Copy Manually")
                        });
                    };
                    throw new Error("Failed pattern match at Components.Copy (line 48, column 18 - line 76, column 10): " + [ v1.value0.constructor.name ]);
                })();
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.form()({
                    onSubmit: React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))),
                    children: [ React_Basic_DOM_Generated.div()({
                        className: "input-group",
                        children: [ React_Basic_DOM_Generated.input()({
                            className: "form-control",
                            readOnly: true,
                            autoComplete: "off",
                            autoCorrect: "off",
                            autoCapitalize: "off",
                            spellCheck: false,
                            value: v.text
                        }), React_Basic_DOM_Generated.div()({
                            className: "input-group-btn",
                            children: [ copyButton ]
                        }) ]
                    }) ]
                }));
            });
        });
    });
})();
module.exports = {
    "new": $$new
};
