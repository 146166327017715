// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Grammar = require("../Data.Grammar/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Lambda_Language_Name = require("../Lambda.Language.Name/index.js");
var Lambda_Language_World = require("../Lambda.Language.World/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var component = function (v) {
    var inject = (function () {
        var $11 = Control_Applicative.pure(Control_Applicative.applicativeArray);
        return function ($12) {
            return $11(React_Basic_DOM.text($12));
        };
    })();
    var code = (function () {
        var $13 = Control_Applicative.pure(Control_Applicative.applicativeArray);
        return function ($14) {
            return React_Basic_DOM_Generated.code_($13(React_Basic_DOM.text($14)));
        };
    })();
    var join = function (dictShow) {
        return function (dictFoldable) {
            var $15 = Data_Grammar.joinWith(Data_Monoid.monoidArray)(Data_Foldable.foldableArray)({
                inject: inject,
                conjunction: [ React_Basic_DOM.text("and") ]
            });
            var $16 = Data_Functor.map(Data_Functor.functorArray)((function () {
                var $19 = Control_Applicative.pure(Control_Applicative.applicativeArray);
                var $20 = Data_Show.show(dictShow);
                return function ($21) {
                    return $19(code($20($21)));
                };
            })());
            var $17 = Data_Array.fromFoldable(dictFoldable);
            return function ($18) {
                return $15($16($17($18)));
            };
        };
    };
    var children = (function () {
        if (v.error instanceof Lambda_Language_World.Undefined) {
            return [ React_Basic_DOM.text("No top-level "), React_Basic_DOM.text(Data_Grammar.pluralizeWith("s")(Data_Set.size(v.error.value0))("definition")), React_Basic_DOM.text(" for "), React_Basic_DOM_Generated.span_(join(Lambda_Language_Name.showName)(Data_Set.foldableSet)(v.error.value0)) ];
        };
        if (v.error instanceof Lambda_Language_World.CannotDelete) {
            return [ React_Basic_DOM.text("Cannot delete "), code(Data_Show.show(Lambda_Language_Name.showName)(v.error.value0)), React_Basic_DOM.text(" because it's still referenced by "), React_Basic_DOM_Generated.span_(join(Lambda_Language_World.showEntity)(Data_Set.foldableSet)(v.error.value1)) ];
        };
        if (v.error instanceof Lambda_Language_World.CannotRedefine) {
            return [ React_Basic_DOM.text("Cannot redefine "), code(Data_Show.show(Lambda_Language_Name.showName)(v.error.value0)), React_Basic_DOM.text(" because it's still referenced by "), React_Basic_DOM_Generated.span_(join(Lambda_Language_World.showEntity)(Data_Set.foldableSet)(v.error.value1)) ];
        };
        throw new Error("Failed pattern match at Components.ConsistencyError (line 21, column 14 - line 39, column 8): " + [ v.error.constructor.name ]);
    })();
    return React_Basic_DOM_Generated.p()({
        children: children
    });
};
module.exports = {
    component: component
};
