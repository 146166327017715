// Generated by purs version 0.14.4
"use strict";
var Affjax = require("../Affjax/index.js");
var Affjax_RequestBody = require("../Affjax.RequestBody/index.js");
var Affjax_RequestHeader = require("../Affjax.RequestHeader/index.js");
var Affjax_ResponseFormat = require("../Affjax.ResponseFormat/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Argonaut_Decode_Class = require("../Data.Argonaut.Decode.Class/index.js");
var Data_Argonaut_Encode_Class = require("../Data.Argonaut.Encode.Class/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HTTP_Method = require("../Data.HTTP.Method/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Record = require("../Record/index.js");
var Simple_Ajax_Errors = require("../Simple.Ajax.Errors/index.js");
var writeContent = function (dictEncodeJson) {
    return function (a) {
        return Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $117 = Data_Argonaut_Encode_Class.encodeJson(dictEncodeJson);
            return function ($118) {
                return Affjax_RequestBody.json($117($118));
            };
        })())(a);
    };
};
var handleResponseH_ = function (res) {
    if (res instanceof Data_Either.Left) {
        return Data_Either.Left.create(Data_Variant.inj()({
            reflectSymbol: function () {
                return "affjaxError";
            }
        })(Simple_Ajax_Errors["_affjaxError"])(res.value0));
    };
    if (res instanceof Data_Either.Right) {
        if (Simple_Ajax_Errors.statusOk(res.value0.status)) {
            return new Data_Either.Right(new Data_Tuple.Tuple(res.value0.headers, Data_Unit.unit));
        };
        if (Data_Boolean.otherwise) {
            return Data_Either.Left.create(Data_Variant.expand()(Simple_Ajax_Errors.mapBasicError(res.value0.status)(Data_Argonaut_Core.toString(res.value0.body))));
        };
    };
    throw new Error("Failed pattern match at Simple.Ajax (line 59, column 24 - line 63, column 94): " + [ res.constructor.name ]);
};
var handleResponse_ = function (res) {
    return Data_Functor.map(Data_Either.functorEither)(Data_Tuple.snd)(handleResponseH_(res));
};
var handleResponseH = function (dictDecodeJson) {
    return function (res) {
        if (res instanceof Data_Either.Left) {
            return Data_Either.Left.create(Data_Variant.inj()({
                reflectSymbol: function () {
                    return "affjaxError";
                }
            })(Simple_Ajax_Errors["_affjaxError"])(res.value0));
        };
        if (res instanceof Data_Either.Right) {
            if (Simple_Ajax_Errors.statusOk(res.value0.status)) {
                return Data_Functor.map(Data_Either.functorEither)(Data_Tuple.Tuple.create(res.value0.headers))(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)((function () {
                    var $119 = Data_Variant.expand();
                    return function ($120) {
                        return $119(Simple_Ajax_Errors.parseError($120));
                    };
                })())(Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson)(res.value0.body)));
            };
            if (Data_Boolean.otherwise) {
                return Data_Either.Left.create(Data_Variant.expand()(Simple_Ajax_Errors.mapBasicError(res.value0.status)(Data_Argonaut_Core.toString(res.value0.body))));
            };
        };
        throw new Error("Failed pattern match at Simple.Ajax (line 43, column 23 - line 47, column 94): " + [ res.constructor.name ]);
    };
};
var handleResponse = function (dictDecodeJson) {
    return function (res) {
        return Data_Functor.map(Data_Either.functorEither)(Data_Tuple.snd)(handleResponseH(dictDecodeJson)(res));
    };
};
var defaults = function (dictUnion) {
    return function (dictNub) {
        return Data_Function.flip(Record.merge()());
    };
};
var defaultSimpleRequest = Record.merge()()({
    responseFormat: Affjax_ResponseFormat.json,
    headers: [ new Affjax_RequestHeader.Accept("application/json") ]
})(Affjax.defaultRequest);
var buildRequest = function (dictUnion) {
    return function (dictUnion1) {
        return function (dictNub) {
            return function (dictNub1) {
                return defaults()()(defaultSimpleRequest);
            };
        };
    };
};
var getR = function (dictDecodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return function (r) {
                        return function (url) {
                            var req = (function () {
                                var v = buildRequest()()()()(r);
                                return {
                                    method: new Data_Either.Left(Data_HTTP_Method.GET.value),
                                    url: url,
                                    responseFormat: Affjax_ResponseFormat.json,
                                    content: v.content,
                                    headers: v.headers,
                                    password: v.password,
                                    timeout: v.timeout,
                                    username: v.username,
                                    withCredentials: v.withCredentials
                                };
                            })();
                            return Control_Bind.bind(Effect_Aff.bindAff)(Affjax.request(req))(function (res) {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)(handleResponse(dictDecodeJson)(res));
                            });
                        };
                    };
                };
            };
        };
    };
};
var get = function (dictDecodeJson) {
    return getR(dictDecodeJson)()()()()({});
};
var simpleRequest = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictNub) {
                    return function (dictNub1) {
                        return function (method) {
                            return function (r) {
                                return function (url) {
                                    return function (content) {
                                        var req = (function () {
                                            var v = buildRequest()()()()(r);
                                            return {
                                                method: method,
                                                url: url,
                                                content: writeContent(dictEncodeJson)(content),
                                                headers: v.headers,
                                                password: v.password,
                                                responseFormat: v.responseFormat,
                                                timeout: v.timeout,
                                                username: v.username,
                                                withCredentials: v.withCredentials
                                            };
                                        })();
                                        return Control_Bind.bind(Effect_Aff.bindAff)(Affjax.request(req))(function (res) {
                                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(handleResponse(dictDecodeJson)(res));
                                        });
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var deleteR = function (dictDecodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return function (req) {
                        return function (url) {
                            return simpleRequest(Data_Argonaut_Encode_Class.encodeJsonJson)(dictDecodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.DELETE.value))(req)(url)(Data_Maybe.Nothing.value);
                        };
                    };
                };
            };
        };
    };
};
var $$delete = function (dictDecodeJson) {
    return deleteR(dictDecodeJson)()()()()({});
};
var patchR = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictNub) {
                    return function (dictNub1) {
                        return simpleRequest(dictEncodeJson)(dictDecodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.PATCH.value));
                    };
                };
            };
        };
    };
};
var patch = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return patchR(dictEncodeJson)(dictDecodeJson)()()()()({});
    };
};
var postR = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictNub) {
                    return function (dictNub1) {
                        return simpleRequest(dictEncodeJson)(dictDecodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.POST.value));
                    };
                };
            };
        };
    };
};
var post = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return postR(dictEncodeJson)(dictDecodeJson)()()()()({});
    };
};
var putR = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictNub) {
                    return function (dictNub1) {
                        return simpleRequest(dictEncodeJson)(dictDecodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.PUT.value));
                    };
                };
            };
        };
    };
};
var put = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return putR(dictEncodeJson)(dictDecodeJson)()()()()({});
    };
};
var simpleRequest$prime = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return function (method) {
                        return function (r) {
                            return function (url) {
                                return function (content) {
                                    var req = (function () {
                                        var v = buildRequest()()()()(r);
                                        return {
                                            method: method,
                                            url: url,
                                            content: writeContent(dictEncodeJson)(content),
                                            headers: v.headers,
                                            password: v.password,
                                            responseFormat: v.responseFormat,
                                            timeout: v.timeout,
                                            username: v.username,
                                            withCredentials: v.withCredentials
                                        };
                                    })();
                                    return Affjax.request(req);
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var simpleRequestH = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictNub) {
                    return function (dictNub1) {
                        return function (m) {
                            return function (r) {
                                return function (u) {
                                    return function (a) {
                                        return Data_Functor.map(Effect_Aff.functorAff)(handleResponseH(dictDecodeJson))(simpleRequest$prime(dictEncodeJson)()()()()(m)(r)(u)(a));
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var postRH = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictNub) {
                    return function (dictNub1) {
                        return simpleRequestH(dictEncodeJson)(dictDecodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.POST.value));
                    };
                };
            };
        };
    };
};
var postH = function (dictEncodeJson) {
    return function (dictDecodeJson) {
        return postRH(dictEncodeJson)(dictDecodeJson)()()()()({});
    };
};
var simpleRequestH_ = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return function (m) {
                        return function (r) {
                            return function (u) {
                                return function (a) {
                                    return Data_Functor.map(Effect_Aff.functorAff)(handleResponseH_)(simpleRequest$prime(dictEncodeJson)()()()()(m)(r)(u)(a));
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var postRH_ = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return simpleRequestH_(dictEncodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.POST.value));
                };
            };
        };
    };
};
var postH_ = function (dictEncodeJson) {
    return postRH_(dictEncodeJson)()()()()({});
};
var simpleRequest_ = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return function (m) {
                        return function (r) {
                            return function (u) {
                                return function (a) {
                                    return Data_Functor.map(Effect_Aff.functorAff)(handleResponse_)(simpleRequest$prime(dictEncodeJson)()()()()(m)(r)(u)(a));
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var deleteR_ = function (dictUnion) {
    return function (dictUnion1) {
        return function (dictNub) {
            return function (dictNub1) {
                return function (req) {
                    return function (url) {
                        return simpleRequest_(Data_Argonaut_Encode_Class.encodeJsonJson)()()()()(new Data_Either.Left(Data_HTTP_Method.DELETE.value))(req)(url)(Data_Maybe.Nothing.value);
                    };
                };
            };
        };
    };
};
var delete_ = deleteR_()()()()({});
var patchR_ = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return simpleRequest_(dictEncodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.PATCH.value));
                };
            };
        };
    };
};
var patch_ = function (dictEncodeJson) {
    return patchR_(dictEncodeJson)()()()()({});
};
var postR_ = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return simpleRequest_(dictEncodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.POST.value));
                };
            };
        };
    };
};
var post_ = function (dictEncodeJson) {
    return postR_(dictEncodeJson)()()()()({});
};
var putR_ = function (dictEncodeJson) {
    return function (dictUnion) {
        return function (dictUnion1) {
            return function (dictNub) {
                return function (dictNub1) {
                    return simpleRequest_(dictEncodeJson)()()()()(new Data_Either.Left(Data_HTTP_Method.PUT.value));
                };
            };
        };
    };
};
var put_ = function (dictEncodeJson) {
    return putR_(dictEncodeJson)()()()()({});
};
module.exports = {
    simpleRequest: simpleRequest,
    simpleRequest_: simpleRequest_,
    postR: postR,
    post: post,
    postR_: postR_,
    post_: post_,
    postH: postH,
    postH_: postH_,
    postRH: postRH,
    postRH_: postRH_,
    putR: putR,
    put: put,
    putR_: putR_,
    put_: put_,
    deleteR: deleteR,
    "delete": $$delete,
    deleteR_: deleteR_,
    delete_: delete_,
    patchR: patchR,
    patch: patch,
    patchR_: patchR_,
    patch_: patch_,
    getR: getR,
    get: get,
    handleResponse: handleResponse,
    handleResponse_: handleResponse_,
    "_affjaxError": Simple_Ajax_Errors["_affjaxError"],
    "_badRequest": Simple_Ajax_Errors["_badRequest"],
    "_forbidden": Simple_Ajax_Errors["_forbidden"],
    "_methodNotAllowed": Simple_Ajax_Errors["_methodNotAllowed"],
    "_notFound": Simple_Ajax_Errors["_notFound"],
    "_parseError": Simple_Ajax_Errors["_parseError"],
    "_serverError": Simple_Ajax_Errors["_serverError"],
    "_unAuthorized": Simple_Ajax_Errors["_unAuthorized"],
    mapBasicError: Simple_Ajax_Errors.mapBasicError,
    parseError: Simple_Ajax_Errors.parseError,
    statusOk: Simple_Ajax_Errors.statusOk
};
