// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var Filename = function (x) {
    return x;
};
var showFilename = Data_Show.showString;
var save = function (v) {
    return $foreign.saveImpl(Data_Either.Left.create)(Data_Either.Right.create)(v.text)(v.to);
};
var newtypeFilename = {
    Coercible0: function () {
        return undefined;
    }
};
var eqFilename = Data_Eq.eqString;
module.exports = {
    save: save,
    Filename: Filename,
    newtypeFilename: newtypeFilename,
    eqFilename: eqFilename,
    showFilename: showFilename
};
