// Generated by purs version 0.14.4
"use strict";
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var booleanAlgebraUnit = {
    HeytingAlgebra0: function () {
        return Data_HeytingAlgebra.heytingAlgebraUnit;
    }
};
var booleanAlgebraRecordNil = {
    HeytingAlgebraRecord0: function () {
        return Data_HeytingAlgebra.heytingAlgebraRecordNil;
    }
};
var booleanAlgebraRecordCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictBooleanAlgebraRecord) {
            return function (dictBooleanAlgebra) {
                return {
                    HeytingAlgebraRecord0: function () {
                        return Data_HeytingAlgebra.heytingAlgebraRecordCons(dictIsSymbol)()(dictBooleanAlgebraRecord.HeytingAlgebraRecord0())(dictBooleanAlgebra.HeytingAlgebra0());
                    }
                };
            };
        };
    };
};
var booleanAlgebraRecord = function (dictRowToList) {
    return function (dictBooleanAlgebraRecord) {
        return {
            HeytingAlgebra0: function () {
                return Data_HeytingAlgebra.heytingAlgebraRecord()(dictBooleanAlgebraRecord.HeytingAlgebraRecord0());
            }
        };
    };
};
var booleanAlgebraProxy3 = {
    HeytingAlgebra0: function () {
        return Data_HeytingAlgebra.heytingAlgebraProxy3;
    }
};
var booleanAlgebraProxy2 = {
    HeytingAlgebra0: function () {
        return Data_HeytingAlgebra.heytingAlgebraProxy2;
    }
};
var booleanAlgebraProxy = {
    HeytingAlgebra0: function () {
        return Data_HeytingAlgebra.heytingAlgebraProxy;
    }
};
var booleanAlgebraFn = function (dictBooleanAlgebra) {
    return {
        HeytingAlgebra0: function () {
            return Data_HeytingAlgebra.heytingAlgebraFunction(dictBooleanAlgebra.HeytingAlgebra0());
        }
    };
};
var booleanAlgebraBoolean = {
    HeytingAlgebra0: function () {
        return Data_HeytingAlgebra.heytingAlgebraBoolean;
    }
};
module.exports = {
    booleanAlgebraBoolean: booleanAlgebraBoolean,
    booleanAlgebraUnit: booleanAlgebraUnit,
    booleanAlgebraFn: booleanAlgebraFn,
    booleanAlgebraRecord: booleanAlgebraRecord,
    booleanAlgebraProxy: booleanAlgebraProxy,
    booleanAlgebraProxy2: booleanAlgebraProxy2,
    booleanAlgebraProxy3: booleanAlgebraProxy3,
    booleanAlgebraRecordNil: booleanAlgebraRecordNil,
    booleanAlgebraRecordCons: booleanAlgebraRecordCons,
    conj: Data_HeytingAlgebra.conj,
    disj: Data_HeytingAlgebra.disj,
    ff: Data_HeytingAlgebra.ff,
    implies: Data_HeytingAlgebra.implies,
    not: Data_HeytingAlgebra.not,
    tt: Data_HeytingAlgebra.tt
};
