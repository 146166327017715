// Generated by purs version 0.14.4
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Last = function (x) {
    return x;
};
var showLast = function (dictShow) {
    return {
        show: function (v) {
            return "(Last " + (Data_Show.show(dictShow)(v) + ")");
        }
    };
};
var semigroupLast = {
    append: function (v) {
        return function (x) {
            return x;
        };
    }
};
var ordLast = function (dictOrd) {
    return dictOrd;
};
var functorLast = {
    map: function (f) {
        return function (m) {
            return f(m);
        };
    }
};
var eqLast = function (dictEq) {
    return dictEq;
};
var eq1Last = {
    eq1: function (dictEq) {
        return Data_Eq.eq(eqLast(dictEq));
    }
};
var ord1Last = {
    compare1: function (dictOrd) {
        return Data_Ord.compare(ordLast(dictOrd));
    },
    Eq10: function () {
        return eq1Last;
    }
};
var boundedLast = function (dictBounded) {
    return dictBounded;
};
var applyLast = {
    apply: function (v) {
        return function (v1) {
            return v(v1);
        };
    },
    Functor0: function () {
        return functorLast;
    }
};
var bindLast = {
    bind: function (v) {
        return function (f) {
            return f(v);
        };
    },
    Apply0: function () {
        return applyLast;
    }
};
var applicativeLast = {
    pure: Last,
    Apply0: function () {
        return applyLast;
    }
};
var monadLast = {
    Applicative0: function () {
        return applicativeLast;
    },
    Bind1: function () {
        return bindLast;
    }
};
module.exports = {
    Last: Last,
    eqLast: eqLast,
    eq1Last: eq1Last,
    ordLast: ordLast,
    ord1Last: ord1Last,
    boundedLast: boundedLast,
    showLast: showLast,
    functorLast: functorLast,
    applyLast: applyLast,
    applicativeLast: applicativeLast,
    bindLast: bindLast,
    monadLast: monadLast,
    semigroupLast: semigroupLast
};
