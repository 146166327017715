// Generated by purs version 0.14.4
"use strict";
var Components_Level = require("../Components.Level/index.js");
var Components_Overlay = require("../Components.Overlay/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var $$new = function __do() {
    var overlay = Components_Overlay["new"]();
    return React_Basic_Hooks.component("Modal")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(overlay({
            dismiss: new Data_Maybe.Just(v.dismiss),
            children: [ React_Basic_DOM_Generated.div()({
                className: Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString)([ "panel panel-", Data_Show.show(Components_Level.showLevel)(v.level), " overlay-modal" ]),
                onClick: React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))),
                children: [ React_Basic_DOM_Generated.div()({
                    className: "panel-heading",
                    children: [ React_Basic_DOM_Generated.h3()({
                        className: "panel-title",
                        children: [ React_Basic_DOM_Generated.span()({
                            className: "cursor-pointer glyphicon glyphicon-remove pull-right",
                            onClick: React_Basic_Events.handler_(v.dismiss)
                        }), React_Basic_DOM.text(v.title) ]
                    }) ]
                }), React_Basic_DOM_Generated.div()({
                    className: "panel-body",
                    children: v.children
                }) ]
            }) ]
        }));
    })();
};
module.exports = {
    "new": $$new
};
