// Generated by purs version 0.14.4
"use strict";
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var statusOk = function (v) {
    return v >= 200 && v < 300;
};
var _unAuthorized = Type_Proxy["Proxy"].value;
var _serverError = Type_Proxy["Proxy"].value;
var _parseError = Type_Proxy["Proxy"].value;
var parseError = Data_Variant.inj()({
    reflectSymbol: function () {
        return "parseError";
    }
})(_parseError);
var _notFound = Type_Proxy["Proxy"].value;
var _methodNotAllowed = Type_Proxy["Proxy"].value;
var _forbidden = Type_Proxy["Proxy"].value;
var _badRequest = Type_Proxy["Proxy"].value;
var mapBasicError = function (v) {
    return function (m) {
        if (v === 400) {
            return Data_Variant.inj()({
                reflectSymbol: function () {
                    return "badRequest";
                }
            })(_badRequest)(Data_Maybe.fromMaybe("")(m));
        };
        if (v === 401) {
            return Data_Variant.inj()({
                reflectSymbol: function () {
                    return "unAuthorized";
                }
            })(_unAuthorized)(Data_Unit.unit);
        };
        if (v === 403) {
            return Data_Variant.inj()({
                reflectSymbol: function () {
                    return "forbidden";
                }
            })(_forbidden)(Data_Unit.unit);
        };
        if (v === 404) {
            return Data_Variant.inj()({
                reflectSymbol: function () {
                    return "notFound";
                }
            })(_notFound)(Data_Unit.unit);
        };
        if (v === 405) {
            return Data_Variant.inj()({
                reflectSymbol: function () {
                    return "methodNotAllowed";
                }
            })(_methodNotAllowed)(Data_Unit.unit);
        };
        if (Data_Boolean.otherwise) {
            return Data_Variant.inj()({
                reflectSymbol: function () {
                    return "serverError";
                }
            })(_serverError)(Data_Maybe.fromMaybe("")(m));
        };
        throw new Error("Failed pattern match at Simple.Ajax.Errors (line 61, column 1 - line 61, column 61): " + [ v.constructor.name, m.constructor.name ]);
    };
};
var _affjaxError = Type_Proxy["Proxy"].value;
module.exports = {
    "_parseError": _parseError,
    "_badRequest": _badRequest,
    "_unAuthorized": _unAuthorized,
    "_forbidden": _forbidden,
    "_notFound": _notFound,
    "_methodNotAllowed": _methodNotAllowed,
    "_serverError": _serverError,
    "_affjaxError": _affjaxError,
    mapBasicError: mapBasicError,
    parseError: parseError,
    statusOk: statusOk
};
