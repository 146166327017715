// Generated by purs version 0.14.4
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var pluralizeWith = function (suffix) {
    return function (n) {
        return function (text) {
            if (n === 1) {
                return text;
            };
            if (Data_Boolean.otherwise) {
                return text + suffix;
            };
            throw new Error("Failed pattern match at Data.Grammar (line 13, column 1 - line 13, column 51): " + [ suffix.constructor.name, n.constructor.name, text.constructor.name ]);
        };
    };
};
var joinInternal = function (dictMonoid) {
    return function (v) {
        return function (xs) {
            var space = v.inject(" ");
            var prepend = function (x) {
                return Data_Semigroup.append(dictMonoid.Semigroup0())(v.conjunction)(Data_Semigroup.append(dictMonoid.Semigroup0())(space)(x));
            };
            var conjOnLast = function (i) {
                return Data_Maybe.fromJust()(Data_Array.modifyAt(i)(prepend)(xs));
            };
            var comma = v.inject(", ");
            var v1 = Data_Array.length(xs);
            if (v1 === 0) {
                return Data_Monoid.mempty(dictMonoid);
            };
            if (v1 === 1) {
                return Data_Array.intercalate(dictMonoid)(Data_Monoid.mempty(dictMonoid))(xs);
            };
            if (v1 === 2) {
                return Data_Array.intercalate(dictMonoid)(space)(conjOnLast(1));
            };
            return Data_Array.intercalate(dictMonoid)(comma)(conjOnLast(v1 - 1 | 0));
        };
    };
};
var joinWith = function (dictMonoid) {
    return function (dictFoldable) {
        return function (options) {
            var $14 = joinInternal(dictMonoid)(options);
            var $15 = Data_Array.fromFoldable(dictFoldable);
            return function ($16) {
                return $14($15($16));
            };
        };
    };
};
module.exports = {
    pluralizeWith: pluralizeWith,
    joinWith: joinWith
};
