// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Void = require("../Data.Void/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var semigroupVoid = {
    append: function (v) {
        return Data_Void.absurd;
    }
};
var semigroupUnit = {
    append: function (v) {
        return function (v1) {
            return Data_Unit.unit;
        };
    }
};
var semigroupString = {
    append: $foreign.concatString
};
var semigroupRecordNil = {
    appendRecord: function (v) {
        return function (v1) {
            return function (v2) {
                return {};
            };
        };
    }
};
var semigroupProxy3 = {
    append: function (v) {
        return function (v1) {
            return Type_Proxy.Proxy3.value;
        };
    }
};
var semigroupProxy2 = {
    append: function (v) {
        return function (v1) {
            return Type_Proxy.Proxy2.value;
        };
    }
};
var semigroupProxy = {
    append: function (v) {
        return function (v1) {
            return Type_Proxy["Proxy"].value;
        };
    }
};
var semigroupArray = {
    append: $foreign.concatArray
};
var appendRecord = function (dict) {
    return dict.appendRecord;
};
var semigroupRecord = function (dictRowToList) {
    return function (dictSemigroupRecord) {
        return {
            append: appendRecord(dictSemigroupRecord)(Type_Proxy["Proxy"].value)
        };
    };
};
var append = function (dict) {
    return dict.append;
};
var semigroupFn = function (dictSemigroup) {
    return {
        append: function (f) {
            return function (g) {
                return function (x) {
                    return append(dictSemigroup)(f(x))(g(x));
                };
            };
        }
    };
};
var semigroupRecordCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictSemigroupRecord) {
            return function (dictSemigroup) {
                return {
                    appendRecord: function (v) {
                        return function (ra) {
                            return function (rb) {
                                var tail = appendRecord(dictSemigroupRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                                var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var insert = Record_Unsafe.unsafeSet(key);
                                var get = Record_Unsafe.unsafeGet(key);
                                return insert(append(dictSemigroup)(get(ra))(get(rb)))(tail);
                            };
                        };
                    }
                };
            };
        };
    };
};
module.exports = {
    append: append,
    appendRecord: appendRecord,
    semigroupString: semigroupString,
    semigroupUnit: semigroupUnit,
    semigroupVoid: semigroupVoid,
    semigroupFn: semigroupFn,
    semigroupArray: semigroupArray,
    semigroupProxy: semigroupProxy,
    semigroupProxy2: semigroupProxy2,
    semigroupProxy3: semigroupProxy3,
    semigroupRecord: semigroupRecord,
    semigroupRecordNil: semigroupRecordNil,
    semigroupRecordCons: semigroupRecordCons
};
