// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Gen_Class = require("../Control.Monad.Gen.Class/index.js");
var Control_Monad_Gen_Common = require("../Control.Monad.Gen.Common/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_ST = require("../Data.Array.ST/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Lazy = require("../Data.Lazy/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_NonEmpty_CodeUnits = require("../Data.String.NonEmpty.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Record = require("../Record/index.js");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var coarbitraryNoArguments = {
    coarbitrary: function (v) {
        return Control_Category.identity(Control_Category.categoryFn);
    }
};
var coarbitrary = function (dict) {
    return dict.coarbitrary;
};
var coarbitraryArgument = function (dictCoarbitrary) {
    return {
        coarbitrary: function (v) {
            return coarbitrary(dictCoarbitrary)(v);
        }
    };
};
var coarbitraryConstructor = function (dictCoarbitrary) {
    return {
        coarbitrary: function (v) {
            return coarbitrary(dictCoarbitrary)(v);
        }
    };
};
var coarbitraryProduct = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return {
            coarbitrary: function (v) {
                var $104 = coarbitrary(dictCoarbitrary1)(v.value1);
                var $105 = coarbitrary(dictCoarbitrary)(v.value0);
                return function ($106) {
                    return $104($105($106));
                };
            }
        };
    };
};
var coarbitrarySum = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return {
            coarbitrary: function (v) {
                if (v instanceof Data_Generic_Rep.Inl) {
                    return coarbitrary(dictCoarbitrary)(v.value0);
                };
                if (v instanceof Data_Generic_Rep.Inr) {
                    return coarbitrary(dictCoarbitrary1)(v.value0);
                };
                throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 217, column 1 - line 219, column 38): " + [ v.constructor.name ]);
            }
        };
    };
};
var genericCoarbitrary = function (dictGeneric) {
    return function (dictCoarbitrary) {
        return function (x) {
            return coarbitrary(dictCoarbitrary)(Data_Generic_Rep.from(dictGeneric)(x));
        };
    };
};
var coarbUnit = {
    coarbitrary: function (v) {
        return Test_QuickCheck_Gen.perturbGen(1.0);
    }
};
var coarbTuple = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return {
            coarbitrary: function (v) {
                var $107 = coarbitrary(dictCoarbitrary1)(v.value1);
                var $108 = coarbitrary(dictCoarbitrary)(v.value0);
                return function ($109) {
                    return $107($108($109));
                };
            }
        };
    };
};
var coarbOrdering = {
    coarbitrary: function (v) {
        if (v instanceof Data_Ordering.LT) {
            return Test_QuickCheck_Gen.perturbGen(1.0);
        };
        if (v instanceof Data_Ordering.EQ) {
            return Test_QuickCheck_Gen.perturbGen(2.0);
        };
        if (v instanceof Data_Ordering.GT) {
            return Test_QuickCheck_Gen.perturbGen(3.0);
        };
        throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 115, column 1 - line 118, column 34): " + [ v.constructor.name ]);
    }
};
var coarbNumber = {
    coarbitrary: Test_QuickCheck_Gen.perturbGen
};
var coarbNonEmpty = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return {
            coarbitrary: function (v) {
                var $110 = coarbitrary(dictCoarbitrary)(v.value1);
                var $111 = coarbitrary(dictCoarbitrary1)(v.value0);
                return function ($112) {
                    return $110($111($112));
                };
            }
        };
    };
};
var coarbMaybe = function (dictCoarbitrary) {
    return {
        coarbitrary: function (v) {
            if (v instanceof Data_Maybe.Nothing) {
                return Test_QuickCheck_Gen.perturbGen(1.0);
            };
            if (v instanceof Data_Maybe.Just) {
                return coarbitrary(dictCoarbitrary)(v.value0);
            };
            throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 155, column 1 - line 157, column 39): " + [ v.constructor.name ]);
        }
    };
};
var coarbList = function (dictCoarbitrary) {
    return {
        coarbitrary: Data_Foldable.foldl(Data_List_Types.foldableList)(function (f) {
            return function (x) {
                var $113 = coarbitrary(dictCoarbitrary)(x);
                return function ($114) {
                    return f($113($114));
                };
            };
        })(Control_Category.identity(Control_Category.categoryFn))
    };
};
var coarbNonEmptyList = function (dictCoarbitrary) {
    return {
        coarbitrary: function (v) {
            return coarbitrary(coarbNonEmpty(coarbList(dictCoarbitrary))(dictCoarbitrary))(v);
        }
    };
};
var coarbLazy = function (dictCoarbitrary) {
    return {
        coarbitrary: function (a) {
            return coarbitrary(dictCoarbitrary)(Data_Lazy.force(a));
        }
    };
};
var coarbInt = {
    coarbitrary: function ($115) {
        return Test_QuickCheck_Gen.perturbGen(Data_Int.toNumber($115));
    }
};
var coarbIdentity = function (dictCoarbitrary) {
    return {
        coarbitrary: function (v) {
            return coarbitrary(dictCoarbitrary)(v);
        }
    };
};
var coarbEither = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return {
            coarbitrary: function (v) {
                if (v instanceof Data_Either.Left) {
                    return coarbitrary(dictCoarbitrary)(v.value0);
                };
                if (v instanceof Data_Either.Right) {
                    return coarbitrary(dictCoarbitrary1)(v.value0);
                };
                throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 162, column 1 - line 164, column 40): " + [ v.constructor.name ]);
            }
        };
    };
};
var coarbChar = {
    coarbitrary: function (c) {
        return coarbitrary(coarbInt)(Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(c));
    }
};
var coarbBoolean = {
    coarbitrary: function (v) {
        if (v) {
            return Test_QuickCheck_Gen.perturbGen(1.0);
        };
        if (!v) {
            return Test_QuickCheck_Gen.perturbGen(2.0);
        };
        throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 72, column 1 - line 74, column 37): " + [ v.constructor.name ]);
    }
};
var coarbArray = function (dictCoarbitrary) {
    return {
        coarbitrary: Data_Foldable.foldl(Data_Foldable.foldableArray)(function (f) {
            return function (x) {
                var $116 = coarbitrary(dictCoarbitrary)(x);
                return function ($117) {
                    return f($116($117));
                };
            };
        })(Control_Category.identity(Control_Category.categoryFn))
    };
};
var coarbNonEmptyArray = function (dictCoarbitrary) {
    return {
        coarbitrary: (function () {
            var $118 = coarbitrary(coarbArray(dictCoarbitrary));
            return function ($119) {
                return $118(Data_Array_NonEmpty.toArray($119));
            };
        })()
    };
};
var coarbString = {
    coarbitrary: function (s) {
        return coarbitrary(coarbArray(coarbMaybe(coarbChar)))(Data_Functor.map(Data_Functor.functorArray)(Data_String_CodeUnits.charAt(0))(Data_String_Common.split(Data_Newtype.wrap()(""))(s)));
    }
};
var coarbNonEmptyString = {
    coarbitrary: (function () {
        var $120 = coarbitrary(coarbString);
        return function ($121) {
            return $120(Data_String_NonEmpty_Internal.toString($121));
        };
    })()
};
var arbitraryRowListNil = {
    arbitraryRecord: function (v) {
        return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)({});
    }
};
var arbitraryRecord = function (dict) {
    return dict.arbitraryRecord;
};
var arbitraryRecordInstance = function (dictRowToList) {
    return function (dictArbitraryRowList) {
        return {
            arbitrary: arbitraryRecord(dictArbitraryRowList)(Type_Proxy["Proxy"].value)
        };
    };
};
var arbitraryNoArguments = {
    arbitrary: Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Data_Generic_Rep.NoArguments.value)
};
var arbitraryGenericSum = function (dict) {
    return dict.arbitraryGenericSum;
};
var arbitrary = function (dict) {
    return dict.arbitrary;
};
var arbitraryArgument = function (dictArbitrary) {
    return {
        arbitrary: Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Argument)(arbitrary(dictArbitrary))
    };
};
var arbitraryConstructor = function (dictArbitrary) {
    return {
        arbitrary: Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Constructor)(arbitrary(dictArbitrary))
    };
};
var arbitraryIdentity = function (dictArbitrary) {
    return {
        arbitrary: Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Identity.Identity)(arbitrary(dictArbitrary))
    };
};
var arbitraryLazy = function (dictArbitrary) {
    return {
        arbitrary: Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(dictArbitrary))((function () {
            var $122 = Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen);
            return function ($123) {
                return $122(Data_Lazy.defer(Data_Function["const"]($123)));
            };
        })())
    };
};
var arbitraryList = function (dictArbitrary) {
    return {
        arbitrary: Test_QuickCheck_Gen.sized(function (n) {
            return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(Test_QuickCheck_Gen.chooseInt(0)(n))(Data_Function.flip(Test_QuickCheck_Gen.listOf)(arbitrary(dictArbitrary)));
        })
    };
};
var arbitraryProduct = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return {
            arbitrary: Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Product.create)(arbitrary(dictArbitrary)))(arbitrary(dictArbitrary1))
        };
    };
};
var arbitraryRowListCons = function (dictArbitrary) {
    return function (dictArbitraryRowList) {
        return function (dictLacks) {
            return function (dictCons) {
                return function (dictRowToList) {
                    return function (dictIsSymbol) {
                        return {
                            arbitraryRecord: function (v) {
                                return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(dictArbitrary))(function (value) {
                                    return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitraryRecord(dictArbitraryRowList)(Type_Proxy["Proxy"].value))(function (previous) {
                                        return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Record.insert(dictIsSymbol)()()(Type_Proxy["Proxy"].value)(value)(previous));
                                    });
                                });
                            }
                        };
                    };
                };
            };
        };
    };
};
var arbitrarySum = function (dictArbitrary) {
    return function (dictArbitraryGenericSum) {
        return {
            arbitrary: Test_QuickCheck_Gen.oneOf(Data_Maybe.fromJust()(Data_Array_NonEmpty.fromArray(Data_Array.cons(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inl.create)(arbitrary(dictArbitrary)))(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inr.create))(arbitraryGenericSum(dictArbitraryGenericSum))))))
        };
    };
};
var genericArbitrary = function (dictGeneric) {
    return function (dictArbitrary) {
        return Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.to(dictGeneric))(arbitrary(dictArbitrary));
    };
};
var arbUnit = {
    arbitrary: Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Data_Unit.unit)
};
var arbTuple = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return {
            arbitrary: Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Tuple.Tuple.create)(arbitrary(dictArbitrary)))(arbitrary(dictArbitrary1))
        };
    };
};
var arbOrdering = {
    arbitrary: Test_QuickCheck_Gen.elements(Data_Maybe.fromJust()(Data_Array_NonEmpty.fromArray([ Data_Ordering.LT.value, Data_Ordering.EQ.value, Data_Ordering.GT.value ])))
};
var arbNumber = {
    arbitrary: Test_QuickCheck_Gen.uniform
};
var arbNonEmpty = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return {
            arbitrary: Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_NonEmpty.NonEmpty.create)(arbitrary(dictArbitrary1)))(arbitrary(dictArbitrary))
        };
    };
};
var arbNonEmptyList = function (dictArbitrary) {
    return {
        arbitrary: Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_List_Types.NonEmptyList)(arbitrary(arbNonEmpty(arbitraryList(dictArbitrary))(dictArbitrary)))
    };
};
var arbMaybe = function (dictArbitrary) {
    return {
        arbitrary: Control_Monad_Gen_Common.genMaybe(Test_QuickCheck_Gen.monadGenGen)(arbitrary(dictArbitrary))
    };
};
var arbInt = {
    arbitrary: Test_QuickCheck_Gen.chooseInt(-1000000 | 0)(1000000)
};
var arbGenSumSum = function (dictArbitrary) {
    return function (dictArbitraryGenericSum) {
        return {
            arbitraryGenericSum: Data_Array.cons(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inl.create)(arbitrary(dictArbitrary)))(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inr.create))(arbitraryGenericSum(dictArbitraryGenericSum)))
        };
    };
};
var arbGenSumConstructor = function (dictArbitrary) {
    return {
        arbitraryGenericSum: [ arbitrary(arbitraryConstructor(dictArbitrary)) ]
    };
};
var arbFunction = function (dictCoarbitrary) {
    return function (dictArbitrary) {
        return {
            arbitrary: Test_QuickCheck_Gen.repeatable(function (a) {
                return coarbitrary(dictCoarbitrary)(a)(arbitrary(dictArbitrary));
            })
        };
    };
};
var arbEither = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return {
            arbitrary: Control_Monad_Gen_Common.genEither(Test_QuickCheck_Gen.monadGenGen)(arbitrary(dictArbitrary))(arbitrary(dictArbitrary1))
        };
    };
};
var arbChar = {
    arbitrary: Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar)))(Test_QuickCheck_Gen.chooseInt(0)(65536))
};
var arbBoolean = {
    arbitrary: Control_Monad_Gen_Class.chooseBool(Test_QuickCheck_Gen.monadGenGen)
};
var arbArray = function (dictArbitrary) {
    return {
        arbitrary: Test_QuickCheck_Gen.arrayOf(arbitrary(dictArbitrary))
    };
};
var arbNonEmptyArray = function (dictArbitrary) {
    return {
        arbitrary: Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(dictArbitrary))(function (x) {
            return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(arbArray(dictArbitrary)))(function (xs) {
                return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Data_Maybe.fromJust()(Data_Array_NonEmpty.fromArray((function __do() {
                    var mxs = Data_Array_ST.unsafeThaw(xs)();
                    Data_Array_ST.push(x)(mxs)();
                    return Data_Array_ST.unsafeFreeze(mxs)();
                })())));
            });
        })
    };
};
var arbString = {
    arbitrary: Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_String_CodeUnits.fromCharArray)(arbitrary(arbArray(arbChar)))
};
var arbNonEmptyString = {
    arbitrary: Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_String_NonEmpty_CodeUnits.cons)(arbitrary(arbChar)))(arbitrary(arbString))
};
var coarbFunction = function (dictArbitrary) {
    return function (dictCoarbitrary) {
        return {
            coarbitrary: function (f) {
                return function (gen) {
                    return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(arbArray(dictArbitrary)))(function (xs) {
                        return coarbitrary(coarbArray(dictCoarbitrary))(Data_Functor.map(Data_Functor.functorArray)(f)(xs))(gen);
                    });
                };
            }
        };
    };
};
module.exports = {
    arbitrary: arbitrary,
    coarbitrary: coarbitrary,
    genericArbitrary: genericArbitrary,
    genericCoarbitrary: genericCoarbitrary,
    arbitraryGenericSum: arbitraryGenericSum,
    arbitraryRecord: arbitraryRecord,
    arbBoolean: arbBoolean,
    coarbBoolean: coarbBoolean,
    arbNumber: arbNumber,
    coarbNumber: coarbNumber,
    arbInt: arbInt,
    coarbInt: coarbInt,
    arbString: arbString,
    coarbString: coarbString,
    arbNonEmptyString: arbNonEmptyString,
    coarbNonEmptyString: coarbNonEmptyString,
    arbChar: arbChar,
    coarbChar: coarbChar,
    arbUnit: arbUnit,
    coarbUnit: coarbUnit,
    arbOrdering: arbOrdering,
    coarbOrdering: coarbOrdering,
    arbArray: arbArray,
    coarbArray: coarbArray,
    arbNonEmptyArray: arbNonEmptyArray,
    coarbNonEmptyArray: coarbNonEmptyArray,
    arbFunction: arbFunction,
    coarbFunction: coarbFunction,
    arbTuple: arbTuple,
    coarbTuple: coarbTuple,
    arbMaybe: arbMaybe,
    coarbMaybe: coarbMaybe,
    arbEither: arbEither,
    coarbEither: coarbEither,
    arbitraryList: arbitraryList,
    coarbList: coarbList,
    arbitraryIdentity: arbitraryIdentity,
    coarbIdentity: coarbIdentity,
    arbitraryLazy: arbitraryLazy,
    coarbLazy: coarbLazy,
    arbNonEmpty: arbNonEmpty,
    coarbNonEmpty: coarbNonEmpty,
    arbNonEmptyList: arbNonEmptyList,
    coarbNonEmptyList: coarbNonEmptyList,
    arbitraryNoArguments: arbitraryNoArguments,
    coarbitraryNoArguments: coarbitraryNoArguments,
    arbGenSumSum: arbGenSumSum,
    arbGenSumConstructor: arbGenSumConstructor,
    arbitrarySum: arbitrarySum,
    coarbitrarySum: coarbitrarySum,
    arbitraryProduct: arbitraryProduct,
    coarbitraryProduct: coarbitraryProduct,
    arbitraryConstructor: arbitraryConstructor,
    coarbitraryConstructor: coarbitraryConstructor,
    arbitraryArgument: arbitraryArgument,
    coarbitraryArgument: coarbitraryArgument,
    arbitraryRowListNil: arbitraryRowListNil,
    arbitraryRowListCons: arbitraryRowListCons,
    arbitraryRecordInstance: arbitraryRecordInstance
};
