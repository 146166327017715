// Generated by purs version 0.14.4
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Cont_Class = require("../Control.Monad.Cont.Class/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Control_Monad_Writer_Class = require("../Control.Monad.Writer.Class/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Distributive = require("../Data.Distributive/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var ReaderT = function (x) {
    return x;
};
var withReaderT = function (f) {
    return function (v) {
        return function ($63) {
            return v(f($63));
        };
    };
};
var runReaderT = function (v) {
    return v;
};
var newtypeReaderT = {
    Coercible0: function () {
        return undefined;
    }
};
var monadTransReaderT = {
    lift: function (dictMonad) {
        return function ($64) {
            return ReaderT(Data_Function["const"]($64));
        };
    }
};
var mapReaderT = function (f) {
    return function (v) {
        return function ($65) {
            return f(v($65));
        };
    };
};
var functorReaderT = function (dictFunctor) {
    return {
        map: (function () {
            var $66 = Data_Functor.map(dictFunctor);
            return function ($67) {
                return mapReaderT($66($67));
            };
        })()
    };
};
var distributiveReaderT = function (dictDistributive) {
    return {
        distribute: function (dictFunctor) {
            return function (a) {
                return function (e) {
                    return Data_Distributive.collect(dictDistributive)(dictFunctor)(function (r) {
                        return r(e);
                    })(a);
                };
            };
        },
        collect: function (dictFunctor) {
            return function (f) {
                var $68 = Data_Distributive.distribute(distributiveReaderT(dictDistributive))(dictFunctor);
                var $69 = Data_Functor.map(dictFunctor)(f);
                return function ($70) {
                    return $68($69($70));
                };
            };
        },
        Functor0: function () {
            return functorReaderT(dictDistributive.Functor0());
        }
    };
};
var applyReaderT = function (dictApply) {
    return {
        apply: function (v) {
            return function (v1) {
                return function (r) {
                    return Control_Apply.apply(dictApply)(v(r))(v1(r));
                };
            };
        },
        Functor0: function () {
            return functorReaderT(dictApply.Functor0());
        }
    };
};
var bindReaderT = function (dictBind) {
    return {
        bind: function (v) {
            return function (k) {
                return function (r) {
                    return Control_Bind.bind(dictBind)(v(r))(function (a) {
                        var v1 = k(a);
                        return v1(r);
                    });
                };
            };
        },
        Apply0: function () {
            return applyReaderT(dictBind.Apply0());
        }
    };
};
var semigroupReaderT = function (dictApply) {
    return function (dictSemigroup) {
        return {
            append: Control_Apply.lift2(applyReaderT(dictApply))(Data_Semigroup.append(dictSemigroup))
        };
    };
};
var applicativeReaderT = function (dictApplicative) {
    return {
        pure: (function () {
            var $71 = Control_Applicative.pure(dictApplicative);
            return function ($72) {
                return ReaderT(Data_Function["const"]($71($72)));
            };
        })(),
        Apply0: function () {
            return applyReaderT(dictApplicative.Apply0());
        }
    };
};
var monadReaderT = function (dictMonad) {
    return {
        Applicative0: function () {
            return applicativeReaderT(dictMonad.Applicative0());
        },
        Bind1: function () {
            return bindReaderT(dictMonad.Bind1());
        }
    };
};
var monadAskReaderT = function (dictMonad) {
    return {
        ask: Control_Applicative.pure(dictMonad.Applicative0()),
        Monad0: function () {
            return monadReaderT(dictMonad);
        }
    };
};
var monadReaderReaderT = function (dictMonad) {
    return {
        local: withReaderT,
        MonadAsk0: function () {
            return monadAskReaderT(dictMonad);
        }
    };
};
var monadContReaderT = function (dictMonadCont) {
    return {
        callCC: function (f) {
            return function (r) {
                return Control_Monad_Cont_Class.callCC(dictMonadCont)(function (c) {
                    var v = f(function ($73) {
                        return ReaderT(Data_Function["const"](c($73)));
                    });
                    return v(r);
                });
            };
        },
        Monad0: function () {
            return monadReaderT(dictMonadCont.Monad0());
        }
    };
};
var monadEffectReader = function (dictMonadEffect) {
    return {
        liftEffect: (function () {
            var $74 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadEffect.Monad0());
            var $75 = Effect_Class.liftEffect(dictMonadEffect);
            return function ($76) {
                return $74($75($76));
            };
        })(),
        Monad0: function () {
            return monadReaderT(dictMonadEffect.Monad0());
        }
    };
};
var monadRecReaderT = function (dictMonadRec) {
    return {
        tailRecM: function (k) {
            return function (a) {
                var k$prime = function (r) {
                    return function (a$prime) {
                        var v = k(a$prime);
                        return Control_Bind.bindFlipped((dictMonadRec.Monad0()).Bind1())(Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0()))(v(r));
                    };
                };
                return function (r) {
                    return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(k$prime(r))(a);
                };
            };
        },
        Monad0: function () {
            return monadReaderT(dictMonadRec.Monad0());
        }
    };
};
var monadStateReaderT = function (dictMonadState) {
    return {
        state: (function () {
            var $77 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadState.Monad0());
            var $78 = Control_Monad_State_Class.state(dictMonadState);
            return function ($79) {
                return $77($78($79));
            };
        })(),
        Monad0: function () {
            return monadReaderT(dictMonadState.Monad0());
        }
    };
};
var monadTellReaderT = function (dictMonadTell) {
    return {
        tell: (function () {
            var $80 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadTell.Monad1());
            var $81 = Control_Monad_Writer_Class.tell(dictMonadTell);
            return function ($82) {
                return $80($81($82));
            };
        })(),
        Semigroup0: dictMonadTell.Semigroup0,
        Monad1: function () {
            return monadReaderT(dictMonadTell.Monad1());
        }
    };
};
var monadWriterReaderT = function (dictMonadWriter) {
    return {
        listen: mapReaderT(Control_Monad_Writer_Class.listen(dictMonadWriter)),
        pass: mapReaderT(Control_Monad_Writer_Class.pass(dictMonadWriter)),
        Monoid0: dictMonadWriter.Monoid0,
        MonadTell1: function () {
            return monadTellReaderT(dictMonadWriter.MonadTell1());
        }
    };
};
var monadThrowReaderT = function (dictMonadThrow) {
    return {
        throwError: (function () {
            var $83 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadThrow.Monad0());
            var $84 = Control_Monad_Error_Class.throwError(dictMonadThrow);
            return function ($85) {
                return $83($84($85));
            };
        })(),
        Monad0: function () {
            return monadReaderT(dictMonadThrow.Monad0());
        }
    };
};
var monadErrorReaderT = function (dictMonadError) {
    return {
        catchError: function (v) {
            return function (h) {
                return function (r) {
                    return Control_Monad_Error_Class.catchError(dictMonadError)(v(r))(function (e) {
                        var v1 = h(e);
                        return v1(r);
                    });
                };
            };
        },
        MonadThrow0: function () {
            return monadThrowReaderT(dictMonadError.MonadThrow0());
        }
    };
};
var monoidReaderT = function (dictApplicative) {
    return function (dictMonoid) {
        return {
            mempty: Control_Applicative.pure(applicativeReaderT(dictApplicative))(Data_Monoid.mempty(dictMonoid)),
            Semigroup0: function () {
                return semigroupReaderT(dictApplicative.Apply0())(dictMonoid.Semigroup0());
            }
        };
    };
};
var altReaderT = function (dictAlt) {
    return {
        alt: function (v) {
            return function (v1) {
                return function (r) {
                    return Control_Alt.alt(dictAlt)(v(r))(v1(r));
                };
            };
        },
        Functor0: function () {
            return functorReaderT(dictAlt.Functor0());
        }
    };
};
var plusReaderT = function (dictPlus) {
    return {
        empty: Data_Function["const"](Control_Plus.empty(dictPlus)),
        Alt0: function () {
            return altReaderT(dictPlus.Alt0());
        }
    };
};
var alternativeReaderT = function (dictAlternative) {
    return {
        Applicative0: function () {
            return applicativeReaderT(dictAlternative.Applicative0());
        },
        Plus1: function () {
            return plusReaderT(dictAlternative.Plus1());
        }
    };
};
var monadPlusReaderT = function (dictMonadPlus) {
    return {
        Monad0: function () {
            return monadReaderT(dictMonadPlus.Monad0());
        },
        Alternative1: function () {
            return alternativeReaderT(dictMonadPlus.Alternative1());
        }
    };
};
var monadZeroReaderT = function (dictMonadZero) {
    return {
        Monad0: function () {
            return monadReaderT(dictMonadZero.Monad0());
        },
        Alternative1: function () {
            return alternativeReaderT(dictMonadZero.Alternative1());
        },
        MonadZeroIsDeprecated2: function () {
            return undefined;
        }
    };
};
module.exports = {
    ReaderT: ReaderT,
    runReaderT: runReaderT,
    withReaderT: withReaderT,
    mapReaderT: mapReaderT,
    newtypeReaderT: newtypeReaderT,
    functorReaderT: functorReaderT,
    applyReaderT: applyReaderT,
    applicativeReaderT: applicativeReaderT,
    altReaderT: altReaderT,
    plusReaderT: plusReaderT,
    alternativeReaderT: alternativeReaderT,
    bindReaderT: bindReaderT,
    monadReaderT: monadReaderT,
    monadZeroReaderT: monadZeroReaderT,
    semigroupReaderT: semigroupReaderT,
    monoidReaderT: monoidReaderT,
    monadPlusReaderT: monadPlusReaderT,
    monadTransReaderT: monadTransReaderT,
    monadEffectReader: monadEffectReader,
    monadContReaderT: monadContReaderT,
    monadThrowReaderT: monadThrowReaderT,
    monadErrorReaderT: monadErrorReaderT,
    monadAskReaderT: monadAskReaderT,
    monadReaderReaderT: monadReaderReaderT,
    monadStateReaderT: monadStateReaderT,
    monadTellReaderT: monadTellReaderT,
    monadWriterReaderT: monadWriterReaderT,
    distributiveReaderT: distributiveReaderT,
    monadRecReaderT: monadRecReaderT,
    ask: Control_Monad_Reader_Class.ask,
    asks: Control_Monad_Reader_Class.asks,
    local: Control_Monad_Reader_Class.local,
    lift: Control_Monad_Trans_Class.lift
};
