// Generated by purs version 0.14.4
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Hashable = require("../Data.Hashable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Safe_Coerce = require("../Safe.Coerce/index.js");
var Address = function (x) {
    return x;
};
var showAddress = {
    show: function (v) {
        return "#" + Data_Show.show(Data_Show.showInt)(v);
    }
};
var ordAddress = Data_Ord.ordInt;
var offset = function (i) {
    return function (address) {
        return Address(Safe_Coerce.coerce()(address) + i | 0);
    };
};
var nullptr = Safe_Coerce.coerce()(0);
var hashableAddress = Data_Hashable.hashableInt;
var eqAddress = Data_Eq.eqInt;
var baseptr = Safe_Coerce.coerce()(1);
module.exports = {
    offset: offset,
    baseptr: baseptr,
    nullptr: nullptr,
    eqAddress: eqAddress,
    hashableAddress: hashableAddress,
    ordAddress: ordAddress,
    showAddress: showAddress
};
