// Generated by purs version 0.14.4
"use strict";
var $foreign = require("./foreign.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var subRecord = function (dict) {
    return dict.subRecord;
};
var sub = function (dict) {
    return dict.sub;
};
var ringUnit = {
    sub: function (v) {
        return function (v1) {
            return Data_Unit.unit;
        };
    },
    Semiring0: function () {
        return Data_Semiring.semiringUnit;
    }
};
var ringRecordNil = {
    subRecord: function (v) {
        return function (v1) {
            return function (v2) {
                return {};
            };
        };
    },
    SemiringRecord0: function () {
        return Data_Semiring.semiringRecordNil;
    }
};
var ringRecordCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictRingRecord) {
            return function (dictRing) {
                return {
                    subRecord: function (v) {
                        return function (ra) {
                            return function (rb) {
                                var tail = subRecord(dictRingRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                                var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var insert = Record_Unsafe.unsafeSet(key);
                                var get = Record_Unsafe.unsafeGet(key);
                                return insert(sub(dictRing)(get(ra))(get(rb)))(tail);
                            };
                        };
                    },
                    SemiringRecord0: function () {
                        return Data_Semiring.semiringRecordCons(dictIsSymbol)()(dictRingRecord.SemiringRecord0())(dictRing.Semiring0());
                    }
                };
            };
        };
    };
};
var ringRecord = function (dictRowToList) {
    return function (dictRingRecord) {
        return {
            sub: subRecord(dictRingRecord)(Type_Proxy["Proxy"].value),
            Semiring0: function () {
                return Data_Semiring.semiringRecord()(dictRingRecord.SemiringRecord0());
            }
        };
    };
};
var ringProxy3 = {
    sub: function (v) {
        return function (v1) {
            return Type_Proxy.Proxy3.value;
        };
    },
    Semiring0: function () {
        return Data_Semiring.semiringProxy3;
    }
};
var ringProxy2 = {
    sub: function (v) {
        return function (v1) {
            return Type_Proxy.Proxy2.value;
        };
    },
    Semiring0: function () {
        return Data_Semiring.semiringProxy2;
    }
};
var ringProxy = {
    sub: function (v) {
        return function (v1) {
            return Type_Proxy["Proxy"].value;
        };
    },
    Semiring0: function () {
        return Data_Semiring.semiringProxy;
    }
};
var ringNumber = {
    sub: $foreign.numSub,
    Semiring0: function () {
        return Data_Semiring.semiringNumber;
    }
};
var ringInt = {
    sub: $foreign.intSub,
    Semiring0: function () {
        return Data_Semiring.semiringInt;
    }
};
var ringFn = function (dictRing) {
    return {
        sub: function (f) {
            return function (g) {
                return function (x) {
                    return sub(dictRing)(f(x))(g(x));
                };
            };
        },
        Semiring0: function () {
            return Data_Semiring.semiringFn(dictRing.Semiring0());
        }
    };
};
var negate = function (dictRing) {
    return function (a) {
        return sub(dictRing)(Data_Semiring.zero(dictRing.Semiring0()))(a);
    };
};
module.exports = {
    sub: sub,
    negate: negate,
    subRecord: subRecord,
    ringInt: ringInt,
    ringNumber: ringNumber,
    ringUnit: ringUnit,
    ringFn: ringFn,
    ringProxy: ringProxy,
    ringProxy2: ringProxy2,
    ringProxy3: ringProxy3,
    ringRecord: ringRecord,
    ringRecordNil: ringRecordNil,
    ringRecordCons: ringRecordCons,
    add: Data_Semiring.add,
    mul: Data_Semiring.mul,
    one: Data_Semiring.one,
    zero: Data_Semiring.zero
};
