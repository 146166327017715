// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Lambda_Language_Pretty = require("../Lambda.Language.Pretty/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var button = function (v) {
    return React_Basic_DOM_Generated.button()({
        className: v.className,
        onClick: React_Basic_Events.handler_(Data_Maybe.fromMaybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(v.onClick)),
        children: [ React_Basic_DOM.text(v.label) ],
        disabled: Data_Maybe.isNothing(v.onClick)
    });
};
var component = function (v) {
    return React_Basic_DOM_Generated.div()({
        className: "add-margin-medium btn-group pull-right",
        children: [ button({
            label: "Step",
            className: "btn btn-default",
            onClick: v.onStep
        }), button({
            label: "Clear",
            className: "btn btn-default",
            onClick: v.onClear
        }), (function () {
            if (v.flags.sharing) {
                return button({
                    label: "Share",
                    className: "btn btn-default",
                    onClick: v.onShare
                });
            };
            return React_Basic.empty;
        })(), button({
            label: "Save",
            className: "btn btn-default",
            onClick: v.onSave
        }), button({
            label: Lambda_Language_Pretty.select(v.rep)({
                sugar: "Raw",
                raw: "Sugar"
            }),
            className: Lambda_Language_Pretty.select(v.rep)({
                sugar: "btn btn-danger",
                raw: "btn btn-success"
            }),
            onClick: v.onSugar
        }) ]
    });
};
module.exports = {
    component: component
};
