// Generated by purs version 0.14.4
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Cont_Trans = require("../Control.Monad.Cont.Trans/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_Writer_Trans = require("../Control.Monad.Writer.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Compose = require("../Data.Functor.Compose/index.js");
var Data_Functor_Costar = require("../Data.Functor.Costar/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Star = require("../Data.Profunctor.Star/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var ParCont = function (x) {
    return x;
};
var sequential = function (dict) {
    return dict.sequential;
};
var parallel = function (dict) {
    return dict.parallel;
};
var newtypeParCont = {
    Coercible0: function () {
        return undefined;
    }
};
var monadParWriterT = function (dictMonoid) {
    return function (dictParallel) {
        return {
            parallel: Control_Monad_Writer_Trans.mapWriterT(parallel(dictParallel)),
            sequential: Control_Monad_Writer_Trans.mapWriterT(sequential(dictParallel)),
            Monad0: function () {
                return Control_Monad_Writer_Trans.monadWriterT(dictMonoid)(dictParallel.Monad0());
            },
            Applicative1: function () {
                return Control_Monad_Writer_Trans.applicativeWriterT(dictMonoid)(dictParallel.Applicative1());
            }
        };
    };
};
var monadParStar = function (dictParallel) {
    return {
        parallel: function (v) {
            return Data_Profunctor_Star.Star((function () {
                var $49 = parallel(dictParallel);
                return function ($50) {
                    return $49(v($50));
                };
            })());
        },
        sequential: function (v) {
            return Data_Profunctor_Star.Star((function () {
                var $51 = sequential(dictParallel);
                return function ($52) {
                    return $51(v($52));
                };
            })());
        },
        Monad0: function () {
            return Data_Profunctor_Star.monadStar(dictParallel.Monad0());
        },
        Applicative1: function () {
            return Data_Profunctor_Star.applicativeStar(dictParallel.Applicative1());
        }
    };
};
var monadParReaderT = function (dictParallel) {
    return {
        parallel: Control_Monad_Reader_Trans.mapReaderT(parallel(dictParallel)),
        sequential: Control_Monad_Reader_Trans.mapReaderT(sequential(dictParallel)),
        Monad0: function () {
            return Control_Monad_Reader_Trans.monadReaderT(dictParallel.Monad0());
        },
        Applicative1: function () {
            return Control_Monad_Reader_Trans.applicativeReaderT(dictParallel.Applicative1());
        }
    };
};
var monadParMaybeT = function (dictParallel) {
    return {
        parallel: function (v) {
            return parallel(dictParallel)(v);
        },
        sequential: function (v) {
            return sequential(dictParallel)(v);
        },
        Monad0: function () {
            return Control_Monad_Maybe_Trans.monadMaybeT(dictParallel.Monad0());
        },
        Applicative1: function () {
            return Data_Functor_Compose.applicativeCompose(dictParallel.Applicative1())(Data_Maybe.applicativeMaybe);
        }
    };
};
var monadParExceptT = function (dictParallel) {
    return {
        parallel: function (v) {
            return parallel(dictParallel)(v);
        },
        sequential: function (v) {
            return sequential(dictParallel)(v);
        },
        Monad0: function () {
            return Control_Monad_Except_Trans.monadExceptT(dictParallel.Monad0());
        },
        Applicative1: function () {
            return Data_Functor_Compose.applicativeCompose(dictParallel.Applicative1())(Data_Either.applicativeEither);
        }
    };
};
var monadParCostar = function (dictParallel) {
    return {
        parallel: function (v) {
            return Data_Functor_Costar.Costar((function () {
                var $53 = sequential(dictParallel);
                return function ($54) {
                    return v($53($54));
                };
            })());
        },
        sequential: function (v) {
            return Data_Functor_Costar.Costar((function () {
                var $55 = parallel(dictParallel);
                return function ($56) {
                    return v($55($56));
                };
            })());
        },
        Monad0: function () {
            return Data_Functor_Costar.monadCostar;
        },
        Applicative1: function () {
            return Data_Functor_Costar.applicativeCostar;
        }
    };
};
var monadParParCont = function (dictMonadEffect) {
    return {
        parallel: ParCont,
        sequential: function (v) {
            return v;
        },
        Monad0: function () {
            return Control_Monad_Cont_Trans.monadContT(dictMonadEffect.Monad0());
        },
        Applicative1: function () {
            return applicativeParCont(dictMonadEffect);
        }
    };
};
var functorParCont = function (dictMonadEffect) {
    return {
        map: function (f) {
            var $57 = parallel(monadParParCont(dictMonadEffect));
            var $58 = Data_Functor.map(Control_Monad_Cont_Trans.functorContT((((dictMonadEffect.Monad0()).Bind1()).Apply0()).Functor0()))(f);
            var $59 = sequential(monadParParCont(dictMonadEffect));
            return function ($60) {
                return $57($58($59($60)));
            };
        }
    };
};
var applyParCont = function (dictMonadEffect) {
    return {
        apply: function (v) {
            return function (v1) {
                return ParCont(function (k) {
                    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref["new"](Data_Maybe.Nothing.value)))(function (ra) {
                        return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref["new"](Data_Maybe.Nothing.value)))(function (rb) {
                            return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadEffect.Monad0()).Bind1())(Control_Monad_Cont_Trans.runContT(v)(function (a) {
                                return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.read(rb)))(function (mb) {
                                    if (mb instanceof Data_Maybe.Nothing) {
                                        return Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.write(new Data_Maybe.Just(a))(ra));
                                    };
                                    if (mb instanceof Data_Maybe.Just) {
                                        return k(a(mb.value0));
                                    };
                                    throw new Error("Failed pattern match at Control.Parallel.Class (line 82, column 7 - line 84, column 26): " + [ mb.constructor.name ]);
                                });
                            }))(function () {
                                return Control_Monad_Cont_Trans.runContT(v1)(function (b) {
                                    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.read(ra)))(function (ma) {
                                        if (ma instanceof Data_Maybe.Nothing) {
                                            return Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.write(new Data_Maybe.Just(b))(rb));
                                        };
                                        if (ma instanceof Data_Maybe.Just) {
                                            return k(ma.value0(b));
                                        };
                                        throw new Error("Failed pattern match at Control.Parallel.Class (line 88, column 7 - line 90, column 26): " + [ ma.constructor.name ]);
                                    });
                                });
                            });
                        });
                    });
                });
            };
        },
        Functor0: function () {
            return functorParCont(dictMonadEffect);
        }
    };
};
var applicativeParCont = function (dictMonadEffect) {
    return {
        pure: (function () {
            var $61 = parallel(monadParParCont(dictMonadEffect));
            var $62 = Control_Applicative.pure(Control_Monad_Cont_Trans.applicativeContT((dictMonadEffect.Monad0()).Applicative0()));
            return function ($63) {
                return $61($62($63));
            };
        })(),
        Apply0: function () {
            return applyParCont(dictMonadEffect);
        }
    };
};
var altParCont = function (dictMonadEffect) {
    return {
        alt: function (v) {
            return function (v1) {
                return ParCont(function (k) {
                    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref["new"](false)))(function (done) {
                        return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadEffect.Monad0()).Bind1())(Control_Monad_Cont_Trans.runContT(v)(function (a) {
                            return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.read(done)))(function (b) {
                                if (b) {
                                    return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(Data_Unit.unit);
                                };
                                return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.write(true)(done)))(function () {
                                    return k(a);
                                });
                            });
                        }))(function () {
                            return Control_Monad_Cont_Trans.runContT(v1)(function (a) {
                                return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.read(done)))(function (b) {
                                    if (b) {
                                        return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(Data_Unit.unit);
                                    };
                                    return Control_Bind.discard(Control_Bind.discardUnit)((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(Effect_Ref.write(true)(done)))(function () {
                                        return k(a);
                                    });
                                });
                            });
                        });
                    });
                });
            };
        },
        Functor0: function () {
            return functorParCont(dictMonadEffect);
        }
    };
};
var plusParCont = function (dictMonadEffect) {
    return {
        empty: ParCont(function (v) {
            return Control_Applicative.pure((dictMonadEffect.Monad0()).Applicative0())(Data_Unit.unit);
        }),
        Alt0: function () {
            return altParCont(dictMonadEffect);
        }
    };
};
var alternativeParCont = function (dictMonadEffect) {
    return {
        Applicative0: function () {
            return applicativeParCont(dictMonadEffect);
        },
        Plus1: function () {
            return plusParCont(dictMonadEffect);
        }
    };
};
module.exports = {
    parallel: parallel,
    sequential: sequential,
    ParCont: ParCont,
    monadParExceptT: monadParExceptT,
    monadParReaderT: monadParReaderT,
    monadParWriterT: monadParWriterT,
    monadParMaybeT: monadParMaybeT,
    monadParStar: monadParStar,
    monadParCostar: monadParCostar,
    newtypeParCont: newtypeParCont,
    functorParCont: functorParCont,
    applyParCont: applyParCont,
    applicativeParCont: applicativeParCont,
    altParCont: altParCont,
    plusParCont: plusParCont,
    alternativeParCont: alternativeParCont,
    monadParParCont: monadParParCont
};
