// Generated by purs version 0.14.4
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var type_ = React_Basic_Events.unsafeEventFn(function (e) {
    return e.type;
});
var timeStamp = React_Basic_Events.unsafeEventFn(function (e) {
    return e.timeStamp;
});
var targetValue = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.value);
});
var targetFiles = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.files);
});
var targetChecked = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.checked);
});
var target = React_Basic_Events.unsafeEventFn(function (e) {
    return e.target;
});
var stopPropagation = React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(function __do() {
        e.stopPropagation();
        return e;
    });
});
var shiftKey = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.shiftKey);
});
var screenY = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.screenY);
});
var screenX = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.screenX);
});
var repeat = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.repeat);
});
var relatedTarget = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.relatedTarget);
});
var preventDefault = React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(function __do() {
        e.preventDefault();
        return e;
    });
});
var nativeEvent = React_Basic_Events.unsafeEventFn(function (e) {
    return e.nativeEvent;
});
var metaKey = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.metaKey);
});
var location = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.location);
});
var locale = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.locale);
});
var key = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.key);
});
var isTrusted = React_Basic_Events.unsafeEventFn(function (e) {
    return e.isTrusted;
});
var isPropagationStopped = React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(e.isPropagationStopped);
});
var isDefaultPrevented = React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(e.isDefaultPrevented);
});
var eventPhaseNone = 0;
var eventPhaseCapturing = 1;
var eventPhaseBubbling = 3;
var eventPhaseAtTarget = 2;
var eventPhase = React_Basic_Events.unsafeEventFn(function (e) {
    return e.eventPhase;
});
var detail = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.detail);
});
var dataTransfer = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.dataTransfer);
});
var currentTarget = React_Basic_Events.unsafeEventFn(function (e) {
    return e.currentTarget;
});
var ctrlKey = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.ctrlKey);
});
var compositionData = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.data);
});
var code = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.code);
});
var clipboardData = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.clipboardData);
});
var clientY = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.clientY);
});
var clientX = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.clientX);
});
var $$char = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e["char"]);
});
var capture = function (eventFn) {
    return React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(preventDefault)(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(stopPropagation)(eventFn)));
};
var capture_ = function (cb) {
    return capture(Control_Category.identity(React_Basic_Events.categoryBuilder))(function (v) {
        return cb;
    });
};
var cancelable = React_Basic_Events.unsafeEventFn(function (e) {
    return e.cancelable;
});
var buttons = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.buttons);
});
var button = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.button);
});
var bubbles = React_Basic_Events.unsafeEventFn(function (e) {
    return e.bubbles;
});
var altKey = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.altKey);
});
module.exports = {
    capture: capture,
    capture_: capture_,
    bubbles: bubbles,
    cancelable: cancelable,
    compositionData: compositionData,
    eventPhase: eventPhase,
    eventPhaseNone: eventPhaseNone,
    eventPhaseCapturing: eventPhaseCapturing,
    eventPhaseAtTarget: eventPhaseAtTarget,
    eventPhaseBubbling: eventPhaseBubbling,
    isTrusted: isTrusted,
    nativeEvent: nativeEvent,
    preventDefault: preventDefault,
    isDefaultPrevented: isDefaultPrevented,
    stopPropagation: stopPropagation,
    isPropagationStopped: isPropagationStopped,
    target: target,
    currentTarget: currentTarget,
    relatedTarget: relatedTarget,
    targetChecked: targetChecked,
    targetValue: targetValue,
    targetFiles: targetFiles,
    timeStamp: timeStamp,
    type_: type_,
    key: key,
    code: code,
    "char": $$char,
    location: location,
    repeat: repeat,
    locale: locale,
    ctrlKey: ctrlKey,
    shiftKey: shiftKey,
    altKey: altKey,
    metaKey: metaKey,
    detail: detail,
    screenX: screenX,
    screenY: screenY,
    clientX: clientX,
    clientY: clientY,
    button: button,
    buttons: buttons,
    dataTransfer: dataTransfer,
    clipboardData: clipboardData
};
